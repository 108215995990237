import { FunctionComponent, useState, ChangeEvent } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import { Button, FileUploader } from 'shared/components';
import { TicketAttachmentUpload } from 'models/shared';
import { TicketPostComment } from 'models/state/tickets';
import { ApiError } from 'models/api';

interface TicketDetailedNewCommentProps {
  saving: boolean;
  error?: ApiError;
  onSubmitComment: (ticket: TicketPostComment) => void;
}

const TicketDetailedNewComment: FunctionComponent<
  TicketDetailedNewCommentProps
> = (props: TicketDetailedNewCommentProps) => {
  const initialUploads: TicketAttachmentUpload[] = [];
  const [uploadedFiles, setUploadedFiles] = useState(initialUploads);

  const [body, setBody] = useState('');
  const [bodyValid, setBodyValid] = useState(false);
  const [bodyDirty, setBodyDirty] = useState(false);

  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);

  const onBodyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    setBody(newValue);
    setBodyDirty(true);
    setBodyValid(newValue.length > 0);
  };

  const onSubmitForm = () => {
    setHasSubmittedForm(true);

    if (!bodyValid) return;

    const uploads: string[] = uploadedFiles.map((a) => a.token);
    const comment: TicketPostComment = {
      body,
      uploads,
    };
    props.onSubmitComment(comment);
  };

  const handleChangeStatus = (file: IFileWithMeta, status: StatusValue) => {
    if (file.xhr && (status === 'removed' || status === 'done')) {
      if (status === 'removed') {
        setUploadedFiles(
          uploadedFiles.filter((item) => item.id !== file.meta.id)
        );
      } else if (status === 'done') {
        const { token } = JSON.parse(file.xhr.response);

        const attachmentFile: TicketAttachmentUpload = {
          token,
          name: file.file.name,
          id: file.meta.id,
        };

        setUploadedFiles([...uploadedFiles, attachmentFile]);
      }
    }
  };

  const { saving, error } = props;
  return (
    <>
      <Row>
        <Col md={12}>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows="3"
              onChange={onBodyChange}
              placeholder="Create a new comment"
              className={`${
                (hasSubmittedForm && !bodyValid) || (bodyDirty && !bodyValid)
                  ? 'is-invalid'
                  : ''
              }`}
            />
          </Form.Group>
        </Col>
      </Row>
      {!saving && error && (
        <div className="alert alert-danger">{error.message}</div>
      )}
      <Row>
        <Col className="text-right">
          <span style={{ marginRight: 120 }}>
            <Button
              loading={saving}
              loadingText="Saving"
              onClick={() => onSubmitForm()}
              color="green"
              smallBtn
            >
              <span>Send</span>
            </Button>
          </span>
          <div style={{ marginTop: -32 }}>
            <FileUploader
              handleChangeStatus={(file, status) => {
                handleChangeStatus(file, status);
              }}
              showAsButton
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TicketDetailedNewComment;
