import { PureComponent } from 'react';
import ComboBox from '@crayoncupl/styleguide-react/lib/ComboBox/ComboBox';
import {
  TicketStatus,
  MyTicketsState,
  TicketsFilter,
} from 'models/state/tickets';
import { Translations } from 'models/state/translations';
import { throttle } from 'throttle-debounce';
import { ComboBoxObject } from '@crayoncupl/styleguide-react/lib/ComboBox/models/ComboBoxModels';
import TicketsTable from '../TicketsTable/TicketsTable';

interface MyTicketsProps {
  translations: Translations;
  myTicketList: MyTicketsState;
  onSearch: (ticketFilter: TicketsFilter) => void;
}

interface MyTicketsComponentState {
  searchText: string;
  status?: TicketStatus;
  page: number;
  pageSize: number;
}

let getMessagesTimeout: NodeJS.Timeout | null = null;

export default class MyTickets extends PureComponent<
  MyTicketsProps,
  MyTicketsComponentState
> {
  autocompleteSearchThrottled = throttle(500, () => {
    this.triggerSearch();
  });

  constructor(props: {
    translations: Translations;
    myTicketList: MyTicketsState;
    onSearch: (filter: TicketsFilter) => void;
  }) {
    super(props);
    this.state = { searchText: '', page: 1, pageSize: 20 };
  }

  onChangePage = (page: number) => {
    this.setState({ page }, () => this.triggerSearch());
  };

  changeQuery = (event: { target: { value: string } }) => {
    this.setState({ searchText: event.target.value, page: 1 }, () => {
      if (getMessagesTimeout && getMessagesTimeout != null) {
        clearTimeout(getMessagesTimeout);
        getMessagesTimeout = null;
      }

      getMessagesTimeout = setTimeout(() => {
        this.autocompleteSearchThrottled();
      }, 1000);
    });
  };

  triggerSearch = () => {
    const status =
      this.state.status || this.state.status === 0
        ? [this.state.status]
        : undefined;

    this.props.onSearch({
      searchText: this.state.searchText,
      status,
      page: this.state.page,
      pageSize: this.state.pageSize,
    });
  };

  changeStatus = (values: ComboBoxObject[]) => {
    const status = values && values.length ? values[0].id : undefined;

    this.setState(
      {
        page: 1,
        status: status ? parseInt(status, 10) : undefined,
      },
      () => {
        this.triggerSearch();
      }
    );
  };

  public render() {
    const {
      myTicketList: { loading, error, data },
      translations,
    } = this.props;
    const totalPages =
      data !== undefined ? Math.ceil(data.totalHits / this.state.pageSize) : 0;

    const statusOptions = [
      { id: String(TicketStatus.New), name: 'New' },
      { id: String(TicketStatus.Open), name: 'Open' },
      { id: String(TicketStatus.Solved), name: 'Solved' },
      { id: String(TicketStatus.Closed), name: 'Closed' },
      { id: String(TicketStatus.Pending), name: 'Pending' },
      { id: String(TicketStatus.Hold), name: 'On Hold' },
      { id: String(TicketStatus.Deleted), name: 'Deleted' },
    ];

    return (
      <>
        <form className="form">
          <div className="row">
            <div className="col-sm-8">
              <div className="form-group">
                <label htmlFor="requestSearch">
                  {translations.CommonSearch}
                </label>
                <input
                  id="requestSearch"
                  type="text"
                  className={`form-control ${
                    this.state !== null
                      ? this.state.searchText && 'hasValue'
                      : ''
                  }`}
                  placeholder="Search for requests here"
                  value={this.state !== null ? this.state.searchText || '' : ''}
                  onChange={this.changeQuery}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label htmlFor="requestStatus">
                  {translations.CommonStatus}
                </label>
                <ComboBox
                  options={statusOptions}
                  placeholder={translations.CommonAny}
                  onChange={this.changeStatus}
                />
              </div>
            </div>
          </div>
        </form>

        <TicketsTable
          loading={loading}
          error={error}
          data={data}
          paginated
          currentPage={this.state.page}
          totalPages={totalPages}
          onChangePage={this.onChangePage}
        />
      </>
    );
  }
}
