import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Service, ServiceId } from 'models/state/services';
import { LoadingSpinner } from 'shared/components';
import { getLayouts } from 'modules/home/utils/services';
import { Link } from 'react-router-dom';
import { ROUTES } from 'shared/utils/routes';
import styles from './MyServices.module.scss';
import 'react-grid-layout/css/styles.css';
import '../../styles/services.scss';
import MyService from './MyService';

const ResponsiveGridLayout = WidthProvider(Responsive);

const MyServices: FunctionComponent = () => {
  const { loading, error, items } = useSelector(
    (state: ApplicationState) => state.services
  );
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );

  const renderServices = (services: Service[]) => {
    const servicesToRender = services.filter(
      (service) => service.owned === true
    );
    const breakpoints = { lg: 992, md: 576, sm: 320, xs: 0, xxs: 0 };
    const layouts = getLayouts(servicesToRender);
    const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

    if (!servicesToRender.length) {
      return null;
    }

    return (
      <div className="section-margin">
        <h2>{translations!.MyServicesHeading}</h2>
        {/* https://github.com/STRML/react-grid-layout#grid-layout-props */}
        {servicesToRender.length <= 4 && (
          <ResponsiveGridLayout
            className="section-title-spacing"
            layouts={layouts}
            breakpoints={breakpoints}
            cols={cols}
            rowHeight={33}
            margin={[24, 24]}
            containerPadding={[0, 0]}
          >
            {servicesToRender.map((service: Service, index: number) =>
              service.id === ServiceId.SOFTWARE_DO ? (
                <Link
                  to={ROUTES.softwareDownloads}
                  key={service.id}
                  className={`tile-brick ${styles.gridTileBrick}`}
                >
                  <MyService
                    service={service}
                    layouts={layouts}
                    index={index}
                  />
                </Link>
              ) : (
                <a
                  key={service.id}
                  href={service.linkUrl}
                  className={`tile-brick ${styles.gridTileBrick}`}
                >
                  <MyService
                    service={service}
                    layouts={layouts}
                    index={index}
                  />
                </a>
              )
            )}
          </ResponsiveGridLayout>
        )}
        {servicesToRender.length > 4 && (
          <div className="o-grid o-grid--cols-sm-2 o-grid--cols-lg-3 o-grid--cols-xl-4 section-title-spacing">
            {servicesToRender.map((service: Service, index: number) => (
              <div className="o-grid-col u-mb-3 u-mb-sm-4" key={service.id}>
                {service.id === ServiceId.SOFTWARE_DO && (
                  <Link
                    to={ROUTES.softwareDownloads}
                    key={service.id}
                    className="o-card u-h-100 o-card--hover-3d"
                  >
                    <MyService
                      service={service}
                      layouts={layouts}
                      index={index}
                      classNameLogo="o-card__top o-card__splash"
                    />
                  </Link>
                )}

                {service.id !== ServiceId.SOFTWARE_DO && (
                  <a
                    key={service.id}
                    href={service.linkUrl}
                    className="o-card u-h-100 o-card--hover-3d"
                  >
                    <MyService
                      service={service}
                      layouts={layouts}
                      index={index}
                      classNameLogo="o-card__top o-card__splash"
                    />
                  </a>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {loading && <LoadingSpinner className="section-margin" />}
      {!loading && !error && !!items.length && renderServices(items)}
    </>
  );
};

export default MyServices;
