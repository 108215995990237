import { useState, HTMLProps, forwardRef, ReactNode, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { User } from 'oidc-client';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import styles from './Header.module.scss';

interface ToggleProps extends HTMLProps<HTMLButtonElement> {
  children?: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}
const CustomToggle = forwardRef<HTMLButtonElement, ToggleProps>(
  ({ children, onClick }, ref) => (
    <button
      type="button"
      className={styles.toggleButton}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </button>
  )
);

interface ProfileProps {
  logout: (event: MouseEvent<HTMLElement>) => void;
  user: User;
}

const Profile = ({ user, logout }: ProfileProps) => {
  const [menuShouldShow, toggleMenu] = useState(false);
  const i18n = useSelector((state: ApplicationState) => state.i18n);
  const { loading, error, translations } = i18n;
  const isLoading = loading || translations === undefined;
  const hasError = !loading && error !== undefined;

  return (
    <>
      {!isLoading && !hasError && (
        <div className={styles.profileSection}>
          <div>
            <div className={styles.userName}>
              {`${user.profile.given_name} ${user.profile.family_name}`}
            </div>
            <div className={styles.userNameSubtitle}>{user.profile.name}</div>
          </div>
          <div className={styles.profilePicture} />
          <div className={styles.dropDownContainer}>
            <Dropdown alignRight onToggle={() => toggleMenu(!menuShouldShow)}>
              <Dropdown.Toggle
                id="dropdown-custom-components"
                as={CustomToggle}
              >
                <FontAwesomeIcon
                  icon={menuShouldShow ? faChevronUp : faChevronDown}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className={styles.dropDownOverrides}>
                <Dropdown.Item
                  className={styles.dropDownItemOverrides}
                  as={Button}
                  onClick={logout}
                >
                  {translations!.CommonSignOut}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
