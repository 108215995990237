import { FunctionComponent } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import styles from './LoadingSpinner.module.scss';

interface LoadingSpinnerProps {
  loadingText?: string;
  className?: string;
}

const LoadingSpinner: FunctionComponent<LoadingSpinnerProps> = ({
  loadingText,
  className,
}: LoadingSpinnerProps) => (
  <div className={className}>
    <Spinner
      className={styles.spinnerOverrides}
      animation="border"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </Spinner>
    {loadingText && <div>{loadingText}</div>}
  </div>
);

export default LoadingSpinner;
