import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { LoadingSpinner } from 'shared/components';
import { ApplicationState } from 'store';

import styles from './CrayonContacts.module.scss';

const CrayonContacts: FunctionComponent = () => {
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );
  const {
    loading,
    error,
    items: contacts,
  } = useSelector((state: ApplicationState) => state.contacts);

  return (
    <Col md={6}>
      <h3>{translations!.AdminCrayonContactsHeading}</h3>
      {loading && <LoadingSpinner className="section-title-spacing" />}
      {!loading && error && (
        <div className="section-title-spacing">
          <p>Error fetching contacts</p>
        </div>
      )}
      {!loading && !error && contacts !== undefined && (
        <>
          {contacts.length === 0 ? (
            <div className="section-title-spacing">
              <p>No contacts found.</p>
            </div>
          ) : (
            <ul className={styles.contactsList}>
              {contacts.map((contact) => (
                <li
                  key={`${contact.fullName}_${contact.phone}_${contact.email}_${contact.role}`}
                  className={styles.contactsListItem}
                >
                  <div className={styles.profileCard}>
                    <div className={styles.profileImage}>
                      <FontAwesomeIcon icon={faUser} fixedWidth />
                    </div>
                    <div>
                      <div className={styles.contactName}>
                        {contact.fullName}
                      </div>
                      <div className={styles.contactPosition}>
                        {contact.role}
                      </div>
                    </div>
                  </div>
                  <div className={styles.contactInfo}>
                    <div>{contact.phone}</div>
                    <div>{contact.email}</div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </Col>
  );
};

export default CrayonContacts;
