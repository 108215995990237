// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { SupportFormsState, SupportForm } from 'models/state/tickets';
import { ApiError } from 'models/api';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const SUPPORT_FORMS_FETCH_REQUEST = 'SUPPORT_FORMS_FETCH_REQUEST';
const SUPPORT_FORMS_FETCH_SUCCESS = 'SUPPORT_FORMS_FETCH_SUCCESS';
const SUPPORT_FORMS_FETCH_FAILURE = 'SUPPORT_FORMS_FETCH_FAILURE';

interface SupportFormsFetchRequestAction extends Action<string> {
  type: typeof SUPPORT_FORMS_FETCH_REQUEST;
}

interface SupportFormsFetchSuccessAction extends Action<string> {
  type: typeof SUPPORT_FORMS_FETCH_SUCCESS;
  payload: SupportForm[];
}

interface SupportFormsFetchFailureAction extends Action<string> {
  type: typeof SUPPORT_FORMS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type SupportFormsActions =
  | SupportFormsFetchRequestAction
  | SupportFormsFetchSuccessAction
  | SupportFormsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: () =>
    ({
      type: SUPPORT_FORMS_FETCH_REQUEST,
    } as SupportFormsFetchRequestAction),
  success: (data: SupportForm[]) =>
    ({
      type: SUPPORT_FORMS_FETCH_SUCCESS,
      payload: data,
    } as SupportFormsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: SUPPORT_FORMS_FETCH_FAILURE,
      payload: error,
    } as SupportFormsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestSupportForms:
    (): AppThunkAction<SupportFormsActions> => async (dispatch) => {
      dispatch(actionCreators.request());
      api.get<SupportForm[]>('api/v1/tickets/support-forms/').then(
        (data) => {
          dispatch(actionCreators.success(data));
        },
        (error: ApiError) => {
          dispatch(actionCreators.failure(error));
        }
      );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: SupportFormsState = {
  forms: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<SupportFormsState> = (
  state: SupportFormsState = defaultState,
  incomingAction: Action | undefined = undefined
): SupportFormsState => {
  const action = incomingAction as SupportFormsActions;

  switch (action.type) {
    case SUPPORT_FORMS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SUPPORT_FORMS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        forms: action.payload,
        error: undefined,
      };

    case SUPPORT_FORMS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        forms: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
