import { PureComponent } from 'react';
import { TicketForm } from 'modules/tickets/components';
import {
  TicketCreateState,
  TicketCategory,
  TicketPost,
} from 'models/state/tickets';
import { ApplicationState } from 'store';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as ticketCreateActions } from 'store/tickets/tickets-create';
import { thunks as ticketFormThunks } from 'store/tickets/ticket-form';
import { LoadingSpinner } from 'shared/components';

interface TicketNewStepTwoOwnProps {
  ticketCreateState: TicketCreateState;
  category: TicketCategory;
  onSubmitTicket: (ticket: TicketPost) => void;
  saving: boolean;
  goToStepOne: () => void;
}
interface TicketStepTwoState {}

const mapStateToProps = (state: ApplicationState) => ({
  ticketFormState: state.tickets.ticketForm,
});

const mapDispatchToProps = (
  dispatch: Dispatch // used to be generic <TicketActions & TicketCommentsActions & TicketCommentActions & TicketHistoryActions>
) =>
  bindActionCreators(
    {
      createTicketReset: ticketCreateActions.reset,
      fetchTicketForm: ticketFormThunks.requestTicketForm,
    },
    dispatch
  );

type TicketNewStepTwoProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TicketNewStepTwoOwnProps;
class TicketNewStepTwo extends PureComponent<
  TicketNewStepTwoProps,
  TicketStepTwoState
> {
  componentDidMount() {
    const { fetchTicketForm, createTicketReset, category } = this.props;
    createTicketReset();
    fetchTicketForm(category.ticketFormId);
  }

  render() {
    const {
      onSubmitTicket,
      saving,
      ticketCreateState,
      category,
      goToStepOne,
      ticketFormState,
    } = this.props;
    const { loading, error, ticketForm } = ticketFormState;
    if (loading)
      return (
        <LoadingSpinner className="section-margin blockquote text-center" />
      );
    if (error)
      return (
        <div className="section-title-spacing">
          <p>Error fetching ticket form</p>
        </div>
      );
    if (ticketForm)
      return (
        <TicketForm
          onSubmitTicket={onSubmitTicket}
          saving={saving}
          ticketCreateState={ticketCreateState}
          category={category}
          goToStepOne={goToStepOne}
          ticketFields={ticketForm.ticketFields}
        />
      );

    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketNewStepTwo);
