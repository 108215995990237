"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useOutsideClick = function (refs, callback) {
    react_1.useLayoutEffect(function () {
        var handleClick = function (e) {
            var clickedOutSide = true;
            for (var i = 0; i < refs.length; ++i) {
                var variable = refs[i].current;
                var parentNode = e.target.parentNode;
                var parentParentNode = parentNode === null || parentNode === void 0 ? void 0 : parentNode.parentNode;
                if ((variable && variable.contains(e.target)) ||
                    (variable && variable.contains(parentParentNode)) ||
                    (variable && variable.contains(parentNode))) {
                    clickedOutSide = false;
                }
            }
            if (clickedOutSide) {
                callback();
            }
        };
        document.addEventListener('click', handleClick);
        return function () {
            document.removeEventListener('click', handleClick);
        };
    }, [callback, refs]);
};
exports.default = useOutsideClick;
