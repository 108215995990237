import { FunctionComponent, useState, useEffect } from 'react';
import { ComboBox } from '@crayoncupl/styleguide-react/lib/ComboBox/ComboBox';
import { thunks as softwareDownloadsThunks } from 'store/software-downloads/software-downloads';
import {
  OperatingSystem,
  Product,
  SoftwareFilter,
  Type,
} from 'models/state/software-downloads';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ComboBoxObject } from '@crayoncupl/styleguide-react/lib/ComboBox/models/ComboBoxModels';
import { useDebounceState } from 'shared/utils/useDebounce';
import { Translations } from 'models/state/translations';

type FilterProps = {
  defaultFilter: SoftwareFilter;
  onChange: () => void;
  translations: Translations;
};

const SoftwareDownloadsFilter: FunctionComponent<FilterProps> = ({
  defaultFilter,
  onChange,
  translations,
}) => {
  const [filterOpen, setFilterOpen] = useState(true);
  const [filter, setFilter] = useState<SoftwareFilter>(defaultFilter);
  const [search, setSearch, debounceSearch] = useDebounceState('');

  useEffect(() => {
    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, debounceSearch]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      softwareDownloadsThunks.requestSoftwareDownloads({
        ...filter,
        page: defaultFilter.page,
        searchText: debounceSearch as unknown as string,
      })
    );
  }, [dispatch, filter, defaultFilter.page, debounceSearch]);

  const changeType = (_values: ComboBoxObject[], type: Type | null) => {
    setFilter({
      ...filter,
      type: type ?? undefined,
    });
  };

  const changeProduct = (
    _values: ComboBoxObject[],
    product: Product | null
  ) => {
    setFilter({
      ...filter,
      product: product ?? undefined,
    });
  };

  const changeOperationSystem = (
    _values: ComboBoxObject[],
    operationSystem: OperatingSystem | null
  ) => {
    setFilter({
      ...filter,
      operatingSystem: operationSystem ?? undefined,
    });
  };

  return (
    <>
      <div className="o-grid">
        <div className="o-grid__col-sm-5 o-grid__col-md-5 o-grid__col-lg-7">
          <button
            type="button"
            className="c-btn c-btn--midnight-green c-btn--outlined-w c-btn--border c-btn--rounded c-btn--prefix u-mb-3"
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
          >
            <div className="c-btn__prefix">
              <FontAwesomeIcon icon={faFilter} />
            </div>
            Filter
          </button>

          {filterOpen && (
            <button
              type="button"
              className="c-btn c-btn--white c-btn--border c-btn--rounded u-ml-2"
              onClick={() => {
                setFilterOpen(false);
              }}
            >
              <span className="c-btn__times" />
            </button>
          )}
        </div>
        <div className="o-grid__col-sm-7 o-grid__col-md-7 o-grid__col-lg-5">
          <div className="c-form-group">
            <span className="c-form-control c-form-control--pill">
              <input
                type="text"
                placeholder={translations.SoftwareDownloadsSearchPlaceHolder}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <button
                type="button"
                className="u-btn-reset c-btn c-btn--midnight-green c-form-control__btn"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className={`${filterOpen ? '' : ' u-d-none'}`}>
        <div className="o-card u-mb-4">
          <div className="o-card__body">
            <div className="o-grid o-grid--cols-1 o-grid--cols-md-3">
              <div className="o-grid__col">
                <div className="c-form-group">
                  <label htmlFor="FilterProduct">Software</label>
                  <ComboBox
                    id="FilterProduct"
                    placeholder="Software"
                    comboBoxType="single"
                    options={[
                      Product.Amos,
                      Product.DataCollectionDataEntry,
                      Product.Modeler,
                      Product.ODBCDrivers,
                      Product.SAS,
                      Product.SPSS,
                      Product.SamplePower,
                      Product.SentinelLicenseManager,
                      Product.TIBCOStatistics,
                      Product.TextAnalyticsForSurveys,
                    ]}
                    onChange={changeProduct}
                    value={filter?.product}
                    renderOptionContent={(x) => `${Product[x]}`}
                    getOptionValue={(x) => x.toString()}
                    renderInputContent={(x) => `${Product[x]}`}
                  />
                </div>
              </div>
              <div className="o-grid__col">
                <div className="c-form-group">
                  <label htmlFor="FilterSoftware">Type</label>
                  <ComboBox
                    id="FilterType"
                    placeholder="Type"
                    comboBoxType="single"
                    options={[Type.Documentation, Type.Patch, Type.Software]}
                    onChange={changeType}
                    value={filter?.type}
                    renderOptionContent={(x) => `${Type[x]}`}
                    getOptionValue={(x) => x.toString()}
                    renderInputContent={(x) => `${Type[x]}`}
                  />
                </div>
              </div>
              <div className="o-grid__col">
                <div className="c-form-group">
                  <label htmlFor="FilterOperatingSystem">
                    Operating system
                  </label>
                  <ComboBox
                    id="FilterOperatingSystem"
                    placeholder="Operating system"
                    comboBoxType="single"
                    options={[
                      OperatingSystem.Linux,
                      OperatingSystem.Mac,
                      OperatingSystem.Windows32,
                      OperatingSystem.Windows64,
                    ]}
                    onChange={changeOperationSystem}
                    value={filter?.operatingSystem}
                    renderOptionContent={(x) => `${OperatingSystem[x]}`}
                    getOptionValue={(x) => x.toString()}
                    renderInputContent={(x) => `${OperatingSystem[x]}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftwareDownloadsFilter;
