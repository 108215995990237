import { MouseEvent } from 'react';
import { User, UserManager } from 'oidc-client';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import { Link } from 'react-router-dom';
import crayonLogo from 'shared/images/Crayon_iQ_logos_positive_Crayon_iQ_logo.svg';
import { api } from 'shared/utils/api';
import styles from './Header.module.scss';
import Profile from './Profile';

interface HeaderProps {
  user: User;
  path: string;
  userManager: UserManager;
  dispatchIsLoggingOut: Function;
}

const requestRevokeAuthentication = async () => {
  const url = '/api/v1/accounts/revoke';
  try {
    await api.post(url, {});
  } catch {
    // Do nothing, auth will be revoked after redirect
  }
};

const Header = (props: HeaderProps) => {
  const logout = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    props.dispatchIsLoggingOut();
    await requestRevokeAuthentication();
    const removeUserPromies = props.userManager.removeUser();
    const redirect = props.userManager.signoutRedirect();
    await Promise.all([removeUserPromies, redirect]);
  };

  const userIsSignedIn = !!props.user && !!props.user.profile;

  return (
    <header className={`${styles.header} border-bottom`}>
      <Navbar expand={false} className={styles.navBar}>
        <Container>
          <Navbar.Brand className={styles.navbarBrand}>
            <Link to="/">
              <img className={styles.logo} src={crayonLogo} alt="Logo" />
              <div />
            </Link>
          </Navbar.Brand>
          <ul className="navbar-nav flex-grow">
            <NavItem>
              {userIsSignedIn && <Profile logout={logout} user={props.user} />}
            </NavItem>
          </ul>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
