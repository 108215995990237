import { FunctionComponent, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { UserManager } from 'oidc-client';
import { useSelector } from 'react-redux';

import { ApplicationState } from 'store';
import { isControlPanelUser, isSupportUser, isUnauthorizedUser } from 'modules/auth/utils';
import SigningIn from 'modules/auth/components/SigningIn/SigningIn';
import SigningOut from 'modules/auth/components/SigningOut/SigningOut';
import { NotAuthorized, NotAuthorizedControlPanel } from 'modules/auth/components/NotAuthorized';
import SoftwareDownloads from 'modules/software-downloads/pages/SoftwareDownloads';

interface RouteAuthenticatedProps extends RouteProps {
  userManager: UserManager;
  isSigningOut: boolean;
}

// NOTE: This doesn't work with passing extra props to the component.
// Keep getting the following error when trying to type a private route with support
// JSX element type 'Component' does not have any construct or call signatures.ts(2604)
// If we can get it working (and we need it) we should use something like this (but with useSelector)
// https://stackoverflow.com/a/61199174
const RouteAuthenticated: FunctionComponent<RouteAuthenticatedProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useSelector((state: ApplicationState) => state.oidc.user); // NOTE: Could perhaps just be passed down as props
  const { userManager: um, path: propsPath, location, isSigningOut } = rest;

  useEffect(() => {
    const signinRedirect = async () => {
      // NOTE: We should test and see whether location exists so we can use pathname and search rather than just path, if they even differ
      // pass the current path to redirect to the correct page after successful login
      const path =
        location == null ? propsPath : `${location.pathname}${location.search}`;
      await um.signinRedirect({
        data: { path },
      });
    };
    if (!isSigningOut && (user == null || user.expired)) {
      signinRedirect();
    }
  });

  if (user && isSupportUser(user) && Component === SoftwareDownloads)
    return <NotAuthorized />

  if (user && isUnauthorizedUser(user))
    return isControlPanelUser(user) 
      ? <NotAuthorizedControlPanel /> 
      : <NotAuthorized />;

  if (user && !user.expired)
    return <Route component={Component} path={rest.path} />;

  return isSigningOut ? <SigningOut /> : <SigningIn />;
};

export default RouteAuthenticated;
