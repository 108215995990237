import { FunctionComponent } from 'react';
import ComboBox from '@crayoncupl/styleguide-react/lib/ComboBox/ComboBox';
import {
  ComboBoxType,
  ComboBoxObject,
  SetOptionsDelegate,
} from '@crayoncupl/styleguide-react/lib/ComboBox/models/ComboBoxModels';
import {
  FieldError,
  MultipleFieldErrors,
} from 'react-hook-form/dist/index.ie11';

interface FormGroupSelectProps {
  id: string;
  name: string;
  type: ComboBoxType;
  required: boolean;
  clearable: boolean;
  hasValue: boolean;
  labelText: string;
  placeholder: string;
  error?: FieldError | MultipleFieldErrors;
  defaultValue?: string | string[];
  options: { id: string; name: string }[] | SetOptionsDelegate<object>;
  ajaxClientSearch?: boolean;
  onChange: (values: ComboBoxObject[]) => void;
}

const FormGroupSelect: FunctionComponent<FormGroupSelectProps> = ({
  id,
  name,
  type,
  required,
  clearable,
  hasValue,
  labelText,
  placeholder,
  error,
  defaultValue,
  options,
  ajaxClientSearch,
  onChange,
}) => (
  <div
    className={`c-form-group${error ? ' is-invalid' : ' is-valid'}${
      hasValue ? ' hasValue' : ''
    }`}
  >
    <label htmlFor={id} className="c-form-group__label">
      {labelText} {required && <span className="is-required">*</span>}
    </label>

    <span className="c-form-group__invalid u-ml-1">{error?.message}</span>

    <ComboBox
      id={id}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      comboBoxType={type}
      canBeCleared={clearable}
      options={options}
      ajaxClientSearch={ajaxClientSearch}
      placeholder={placeholder}
    />
  </div>
);

export default FormGroupSelect;
