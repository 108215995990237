import { Action, Reducer } from 'redux';
import { AuthState } from 'models/state/auth';
// eslint-disable-next-line import/no-cycle
import {
  AuthActions,
  AUTH_FETCH_REQUEST,
  AUTH_FETCH_SUCCESS,
  AUTH_FETCH_FAILURE,
  AUTH_IS_SIGNING_OUT,
} from './actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultSettingsState: AuthState = {
  config: undefined,
  loading: false,
  error: undefined,
  isSigningOut: false,
};

export const reducer: Reducer<AuthState> = (
  state: AuthState = defaultSettingsState,
  incomingAction: Action | undefined = undefined
): AuthState => {
  const action = incomingAction as AuthActions;

  switch (action.type) {
    case AUTH_FETCH_REQUEST:
      return { ...state, loading: true };

    case AUTH_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        config: action.payload,
        error: undefined,
      };

    case AUTH_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        config: undefined,
        error: action.payload,
      };

    case AUTH_IS_SIGNING_OUT:
      return {
        ...state,
        isSigningOut: action.payload,
      };

    default:
      return state;
  }
};
