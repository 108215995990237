import { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  withRouter,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';
import { History } from 'history';
import {
  ApplicationInsights,
  ITelemetryPlugin,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { Dispatch, bindActionCreators } from 'redux';
import Container from 'react-bootstrap/Container';
import { OidcProvider, loadUser, createUserManager } from 'redux-oidc';
import { UserManagerSettings, UserManager } from 'oidc-client';

// Routes
import { ROUTES } from 'shared/utils/routes';

// Components & Pages
import { RouteAuthenticated } from 'modules/auth/components';
import { Callback, CallbackError } from 'modules/auth/pages';
import { Home } from 'modules/home/pages';
import SoftwareDownloads from 'modules/software-downloads/pages/SoftwareDownloads';
import { PageNotFound } from 'shared/pages';
import { Footer, Header, LoadingSpinner } from 'shared/components';

// Store
import { ApplicationState } from 'store';
import { settingThunks } from 'store/settings/actions';
import { thunks as translationThunks } from 'store/translations/actions';
import { actionCreators as authActions, authThunks } from 'store/auth/actions';

// Styles
import 'shared/styles/bootstrap-overrides.scss';
import 'shared/styles/global.scss';
import '@crayoncupl/styleguide/styles/index.scss';
import { TicketList, TicketNew, TicketDetails } from 'modules/tickets/pages';
import { isUnauthorizedUser } from 'modules/auth/utils';

// NOTE: How do we type the store?! :'(
interface AppOwnProps extends RouteComponentProps<{}> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: any;
  history: History;
}

interface AppOwnState {
  userManager: UserManager | null;
  aiInitialized: boolean;
}

const mapStateToProps = (state: ApplicationState) => ({
  auth: state.auth,
  settings: state.settings,
  i18n: state.i18n,
  user: state.oidc.user,
  isLoadingUser: state.oidc.isLoadingUser,
  location: state.router.location,
});

const mapDispatchToProps = (
  dispatch: Dispatch // used to be generic <AuthActions & SettingsActions & TranslationsActions>
) =>
  bindActionCreators(
    {
      fetchAuthConfig: authThunks.requestAuthConfigThunk,
      fetchSettings: settingThunks.requestSettingsThunk,
      fetchTranslations: translationThunks.requestTranslationsThunk,
      setIsSigningOut: authActions.setIsSigningOut,
    },
    dispatch
  );

type AppProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  AppOwnProps;

class App extends PureComponent<AppProps, AppOwnState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      userManager: null,
      aiInitialized: false,
    };
    this.dispatchIsLoggingOut = this.dispatchIsLoggingOut.bind(this);
  }

  componentDidMount() {
    this.props.fetchAuthConfig();
    this.getTranslations();
    this.getSettings();
    this.setupApplicationInsight();
  }

  async componentDidUpdate() {
    this.getTranslations();
    this.getSettings();
    this.setupApplicationInsight();
    await this.setupUserManager();
  }

  private getTranslations(): void {
    if (this.hasInvalidUser()) return;

    const { i18n } = this.props;

    const shouldFetchTranslations =
      !i18n.loading && i18n.error == null && i18n.translations == null;
    if (shouldFetchTranslations) this.props.fetchTranslations();
  }

  private getSettings(): void {
    if (this.hasInvalidUser()) return;

    const { settings } = this.props;

    const shouldFetchSettings =
      !settings.loading && settings.error == null && settings.settings == null;
    if (shouldFetchSettings) this.props.fetchSettings();
  }

  private async setupUserManager() {
    const { auth } = this.props;
    const shouldSetupUserManager =
      !auth.loading &&
      auth.config !== undefined &&
      this.state.userManager == null;

    if (!shouldSetupUserManager) return;

    const { config } = auth;
    const siteUrl =
      window.location.origin ||
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`;
    const umConfig: UserManagerSettings = {
      authority: config!.authority,
      automaticSilentRenew: config!.automaticSilentRenew,
      client_id: config!.clientId,
      redirect_uri: `${siteUrl}${ROUTES.callback}`,
      response_type: config!.responseType,
      scope: config!.scope,
      post_logout_redirect_uri: `${siteUrl}/index.html`,
      silent_redirect_uri: `${siteUrl}/silent.html`, // needs to be built by webpack. might require eject :'(
      loadUserInfo: config!.loadUserInfo,
    };

    const userManager = createUserManager(umConfig);

    this.setState({ userManager });

    // https://github.com/maxmantz/redux-oidc/blob/master/docs/API.md#loadUser
    await loadUser(this.props.store, userManager);
  }

  private setupApplicationInsight() {
    if (this.state.aiInitialized) return;

    const { settings } = this.props;
    const shouldSetupAi = !settings.loading && settings.settings !== undefined;

    if (!shouldSetupAi) return;

    this.setState({ aiInitialized: true }, () => {
      const { instrumentationKey } = settings.settings!.applicationInsights;
      if (instrumentationKey === '') return;

      const { history } = this.props;
      const reactPlugin = new ReactPlugin();
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          extensions: [reactPlugin as unknown as ITelemetryPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history },
          },
        },
      });
      appInsights.loadAppInsights();
    });
  }

  private hasInvalidUser(): boolean {
    const { user } = this.props;

    return user === null || user.expired || isUnauthorizedUser(user);
  }

  dispatchIsLoggingOut() {
    this.props.setIsSigningOut(true);
  }

  render() {
    const { auth, store, user, location, isLoadingUser } = this.props;

    const shouldRenderApp =
      !isLoadingUser &&
      !auth.loading &&
      auth.config !== undefined &&
      this.state.userManager != null;

    const hasUser = user != null && !user.expired;

    return shouldRenderApp ? (
      <OidcProvider store={store} userManager={this.state.userManager!}>
        <div className="main-wrapper">
          <div>
            <Header
              user={user}
              path={location.pathname}
              dispatchIsLoggingOut={this.dispatchIsLoggingOut}
              userManager={this.state.userManager!}
            />
            <Container id="page-container">
              <Switch>
                <RouteAuthenticated
                  exact
                  path={ROUTES.root}
                  component={Home}
                  isSigningOut={auth.isSigningOut}
                  userManager={this.state.userManager!}
                />
                <RouteAuthenticated
                  exact
                  path={ROUTES.ticketList}
                  component={TicketList}
                  isSigningOut={auth.isSigningOut}
                  userManager={this.state.userManager!}
                />
                <RouteAuthenticated
                  exact
                  path={ROUTES.ticketNew}
                  component={TicketNew}
                  isSigningOut={auth.isSigningOut}
                  userManager={this.state.userManager!}
                />

                <RouteAuthenticated
                  exact
                  path={ROUTES.ticketThankYou}
                  component={TicketNew}
                  isSigningOut={auth.isSigningOut}
                  userManager={this.state.userManager!}
                />

                <RouteAuthenticated
                  path={ROUTES.ticketDetails}
                  component={TicketDetails}
                  isSigningOut={auth.isSigningOut}
                  userManager={this.state.userManager!}
                />

                <RouteAuthenticated
                  path={ROUTES.softwareDownloads}
                  component={SoftwareDownloads}
                  isSigningOut={auth.isSigningOut}
                  userManager={this.state.userManager!}
                />

                <Route
                  path={ROUTES.callback}
                  render={(props) => (
                    <Callback
                      {...props}
                      userManager={this.state.userManager!}
                    />
                  )}
                />
                <Route path={ROUTES.callbackError} component={CallbackError} />
                <RouteAuthenticated
                  component={PageNotFound}
                  isSigningOut={auth.isSigningOut}
                  userManager={this.state.userManager!}
                />
              </Switch>
            </Container>
          </div>
          {hasUser && <Footer />}
        </div>
      </OidcProvider>
    ) : (
      <Container>
        <LoadingSpinner className="section-margin blockquote text-center" />
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
