// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { ApiCollection, ApiError } from 'models/api';
import { Ticket, MyTicketsState, TicketsFilter } from 'models/state/tickets';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const MY_TICKETS_FETCH_REQUEST = 'MY_TICKETS_FETCH_REQUEST';
const MY_TICKETS_FETCH_SUCCESS = 'MY_TICKETS_FETCH_SUCCESS';
const MY_TICKETS_FETCH_FAILURE = 'MY_TICKETS_FETCH_FAILURE';

interface MyTicketsFetchRequestAction extends Action<string> {
  type: typeof MY_TICKETS_FETCH_REQUEST;
}

interface MyTicketsFetchSuccessAction extends Action<string> {
  type: typeof MY_TICKETS_FETCH_SUCCESS;
  payload: ApiCollection<Ticket>;
}

interface MyTicketsFetchFailureAction extends Action<string> {
  type: typeof MY_TICKETS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type MyTicketsActions =
  | MyTicketsFetchRequestAction
  | MyTicketsFetchSuccessAction
  | MyTicketsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: () =>
    ({
      type: MY_TICKETS_FETCH_REQUEST,
    } as MyTicketsFetchRequestAction),
  success: (data: ApiCollection<Ticket>) =>
    ({
      type: MY_TICKETS_FETCH_SUCCESS,
      payload: data,
    } as MyTicketsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: MY_TICKETS_FETCH_FAILURE,
      payload: error,
    } as MyTicketsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestTickets:
    (ticketsFilter?: TicketsFilter): AppThunkAction<MyTicketsActions> =>
    async (dispatch) => {
      dispatch(actionCreators.request());

      const ticketFilter = {
        page: 1,
        pageSize: 20,
        searchText: '',
        status: null,
        ...ticketsFilter,
      };

      const filter = `?page=${ticketFilter.page}&pageSize=${
        ticketFilter.pageSize
      }&searchText=${encodeURIComponent(ticketFilter.searchText)}${
        ticketFilter.status && ticketFilter.status.length
          ? `&status=${ticketFilter.status.join('&status=')}`
          : ''
      }`;

      api
        .get<ApiCollection<Ticket>>(`api/v1/tickets/my-tickets/${filter}`)
        .then(
          (data) => {
            dispatch(actionCreators.success(data));
          },
          (error: ApiError) => {
            dispatch(actionCreators.failure(error));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: MyTicketsState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<MyTicketsState> = (
  state: MyTicketsState = defaultState,
  incomingAction: Action | undefined = undefined
): MyTicketsState => {
  const action = incomingAction as MyTicketsActions;

  switch (action.type) {
    case MY_TICKETS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MY_TICKETS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: undefined,
      };

    case MY_TICKETS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
