import { FunctionComponent } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, {
  IFileWithMeta,
  StatusValue,
  ILayoutProps,
  IInputProps,
  IPreviewProps,
  formatDuration,
} from 'react-dropzone-uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import axios from 'axios';
import styles from './FileUploader.module.scss';
import { Plus } from '..';

const maxSize = 1048576;

const Input: FunctionComponent<IInputProps> = (props: IInputProps) => {
  const {
    className,
    labelClassName,
    labelWithFilesClassName,
    style,
    labelStyle,
    labelWithFilesStyle,
    getFilesFromEvent,
    accept,
    multiple,
    disabled,
    content,
    withFilesContent,
    onFiles,
    files,
  } = props;

  return (
    <div>
      <label
        className={files.length > 0 ? labelWithFilesClassName : labelClassName}
        style={files.length > 0 ? labelWithFilesStyle : labelStyle}
      >
        <input
          className={className}
          style={style}
          type="file"
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          onChange={async (e) => {
            const { target } = e;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            // @ts-ignore
            target.value = null;
          }}
        />

        <div>
          <h4>{files.length > 0 ? withFilesContent : content}</h4>

          <p>or drag and drop here</p>

          <span>Maximum file size is {maxSize / 1024 / 1024}MB </span>
        </div>
      </label>
    </div>
  );
};

const InputButton: FunctionComponent<IInputProps> = (props: IInputProps) => {
  const {
    className,
    style,
    multiple,
    disabled,
    accept,
    onFiles,
    getFilesFromEvent,
  } = props;

  return (
    <div>
      <label className={styles['file-button']}>
        <span>
          <Plus /> Add files
        </span>
        <input
          className={className}
          style={style}
          type="file"
          accept={accept}
          multiple={multiple}
          disabled={disabled}
          onChange={async (e) => {
            const { target } = e;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            // @ts-ignore
            target.value = null;
          }}
        />
      </label>
    </div>
  );
};

const Layout = ({
  input,
  previews,
  dropzoneProps,
  files,
  extra: { maxFiles },
}: ILayoutProps) => (
  <div className={styles.cFileUploader}>
    <div {...dropzoneProps}>{files.length < maxFiles && input}</div>

    {previews}
  </div>
);

const Preview = (props: IPreviewProps) => {
  const {
    className,
    imageClassName,
    style,
    imageStyle,
    fileWithMeta: { remove },
    meta: {
      name = '',
      percent = 0,
      size = 0,
      previewUrl,
      status,
      duration,
      validationError,
    },
    isUpload,
    canRemove,
    extra: { minSizeBytes },
  } = props;

  let title = `${name || '?'}`;
  if (duration) title = `${title}, ${formatDuration(duration)}`;

  if (status === 'error_file_size' || status === 'error_validation') {
    return (
      <div className={className} style={style}>
        <span className="dzu-previewFileNameError">{title}</span>
        {status === 'error_file_size' && (
          <span>{size < minSizeBytes ? 'File too small' : 'File too big'}</span>
        )}
        {status === 'error_validation' && (
          <span>{String(validationError)}</span>
        )}
        {canRemove && (
          <FontAwesomeIcon
            className={styles.remove}
            icon={faTrashAlt}
            onClick={remove}
          />
        )}
      </div>
    );
  }

  let errorMessage: string = '';

  if (
    status === 'error_upload_params' ||
    status === 'exception_upload' ||
    status === 'error_upload'
  ) {
    errorMessage = 'upload failed';
  }
  if (status === 'aborted') {
    errorMessage = 'cancelled';
  }

  const isWorking =
    status !== 'preparing' &&
    status !== 'getting_upload_params' &&
    status !== 'uploading';

  return (
    <div className={className} style={style}>
      {previewUrl && (
        <img
          className={imageClassName}
          style={imageStyle}
          src={previewUrl}
          alt={title}
          title={title}
        />
      )}
      {!previewUrl && (
        <span
          className={`dzu-previewFileName ${styles['custom-preview-file-name']}`}
        >
          {title}
          {errorMessage && (
            <span className={styles.errorText}>{` (${errorMessage})`}</span>
          )}
        </span>
      )}

      <div className="dzu-previewStatusContainer">
        {isUpload && !isWorking && <progress max={100} value={percent} />}

        {isWorking && canRemove && (
          <FontAwesomeIcon
            className={styles.remove}
            icon={faTrashAlt}
            onClick={remove}
          />
        )}
      </div>
    </div>
  );
};

interface FileUploaderProps {
  handleChangeStatus?: (
    file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]
  ) => void;
  showAsButton?: boolean;
}

const FileUploader = (props: FileUploaderProps) => {
  const { handleChangeStatus, showAsButton } = props;

  // specify upload params and url for your files
  const getUploadParams = () => ({
    url: '/api/v1/tickets/upload-attachments/',
    headers: {
      authorization: axios.defaults.headers.common.Authorization,
    },
  });

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      minSizeBytes={1}
      maxSizeBytes={maxSize}
      maxFiles={10}
      multiple={true}
      autoUpload={true}
      canCancel={false}
      canRestart={false}
      inputContent="Upload files"
      inputWithFilesContent="Upload files"
      InputComponent={showAsButton ? InputButton : Input}
      LayoutComponent={Layout}
      PreviewComponent={Preview}
      classNames={{
        dropzone: showAsButton ? undefined : styles.dropArea,
        inputLabel: styles.fileUploaderInputLabel,
        inputLabelWithFiles: styles.fileUploaderInputLabel,
        preview: styles.filePreviewContainer,
      }}
      //   accept="image/*,audio/*,video/*"
    />
  );
};

export default FileUploader;
