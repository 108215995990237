import { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { faFileArchive } from '@fortawesome/free-regular-svg-icons';
import { SoftwareDownload } from 'models/state/software-downloads';
import { downloadFile } from 'shared/utils/downloadFile';

interface FileProps {
  software: SoftwareDownload;
}

const SoftwareDownloadFile: FunctionComponent<FileProps> = ({ software }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const getFile = async () => {
    setIsDownloading(true);
    await downloadFile(
      `/api/v1/softwareDownloads/${software?.id}/download-file`,
      `${software.displayName}`,
      undefined,
      undefined,
      () => {
        setIsDownloading(false);
      }
    );
  };
  return (
    <tr>
      <td className="u-td-icon u-d-none u-d-md-table-cell">
        <FontAwesomeIcon
          icon={faFileArchive}
          style={{
            color: '#666',
            fontSize: '23px',
          }}
        />
      </td>
      <td>
        <button type="button" className="o-link-with-icon" onClick={getFile}>
          {software.displayName}
          <span className="u-d-inline u-d-md-none">, {software.version}</span>
        </button>
      </td>
      <td className="u-d-none u-d-md-table-cell">{software.version}</td>
      <td className="u-text-right u-text-nowrap u-d-none u-d-md-table-cell">
        {!isDownloading && (
          <button
            type="button"
            className="o-link-with-icon"
            area-aria-label={`Download file ${software.displayName}`}
            onClick={getFile}
          >
            <FontAwesomeIcon icon={faDownload} className="u-mr-1" />
            <span className="o-link-with-icon__label">Download</span>
          </button>
        )}
        {isDownloading && (
          <span>
            <FontAwesomeIcon icon={faSpinner} className="u-mr-1 fa-spin" />
            <span className="o-link-with-icon__label">Downloading...</span>
          </span>
        )}
      </td>
    </tr>
  );
};

export default SoftwareDownloadFile;
