import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Service, ServiceId } from 'models/state/services';
import { LoadingSpinner } from 'shared/components';

import '../../styles/services.scss';
import { ROUTES } from 'shared/utils/routes';
import { Link } from 'react-router-dom';
import styles from './OtherServices.module.scss';
import OtherService from './OtherService';

const OtherServicesComponent: FunctionComponent = () => {
  const { loading, error, items } = useSelector(
    (state: ApplicationState) => state.services
  );
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );

  const renderServices = (services: Service[]) => {
    const servicesToRender = services.filter(
      (service) => service.owned === false
    );

    if (!servicesToRender.length) {
      return null;
    }

    return (
      <div className="section-margin">
        <h2>{translations!.OtherServicesHeading}</h2>
        <Row className="section-title-spacing">
          {servicesToRender.map((service: Service, index) =>
            service.id === ServiceId.SOFTWARE_DO ? (
              <Col xl={true} lg={4} md={6} sm={12} key={service.id}>
                <Link
                  to={ROUTES.softwareDownloads}
                  key={service.id}
                  className={`tile-brick ${styles.gridTileBrick}`}
                >
                  <OtherService service={service} />
                </Link>
              </Col>
            ) : (
              <Col xl={true} lg={4} md={6} sm={12} key={service.id}>
                <a
                  href={service.marketingLink || service.linkUrl}
                  className={`tile-brick ${styles['other-services-tile-brick']}`}
                >
                  <OtherService service={service} />
                </a>
              </Col>
            )
          )}
        </Row>
      </div>
    );
  };

  return (
    <>
      {loading && <LoadingSpinner className="section-margin" />}
      {!loading && !error && !!items.length && renderServices(items)}
    </>
  );
};

export default OtherServicesComponent;
