/* eslint-disable import/no-cycle */
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  Dispatch,
} from 'redux';
import thunk from 'redux-thunk';
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from 'connected-react-router';
import { History } from 'history';
import { User } from 'oidc-client';
import {
  reducer as oidcReducer,
  USER_FOUND,
  Action as OidcAction,
} from 'redux-oidc';

import { reducer as settingsReducer } from 'store/settings/reducers';
import { SettingsState } from 'models/state/settings';
import { reducer as translationsReducer } from 'store/translations/reducers';
import { TranslationsState } from 'models/state/translations';
import { newsReducer } from 'store/news/index';
import { NewsState } from 'models/state/news';
import { reducer as serviceReducer } from 'store/services/reducers';
import { ServicesState } from 'models/state/services';
import { reducer as contactsReducer } from 'store/contacts/reducers';
import { ContactsState } from 'models/state/contacts';
import { ticketsReducer } from 'store/tickets';
import { TicketsState } from 'models/state/tickets';
import { reducer as authReducer } from 'store/auth/reducers';
import { AuthState } from 'models/state/auth';
import axios from 'axios';
import { SoftwareDownloadsState } from 'models/state/software-downloads';
import { softwareDownloadsReducer } from './software-downloads';

interface OidcState {
  isLoadingUser: boolean;
  isSigningOut: boolean;
  user: User;
}

// The top-level state object
export interface ApplicationState {
  settings: SettingsState;
  i18n: TranslationsState;
  news: NewsState;
  services: ServicesState;
  contacts: ContactsState;
  tickets: TicketsState;
  router: RouterState;
  oidc: OidcState;
  auth: AuthState;
  softwareDownloads: SoftwareDownloadsState
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  settings: settingsReducer,
  i18n: translationsReducer,
  news: newsReducer,
  services: serviceReducer,
  contacts: contactsReducer,
  tickets: ticketsReducer,
  oidc: oidcReducer,
  auth: authReducer,
  softwareDownloads: softwareDownloadsReducer,
};

const saveAuthTokenMiddleware: Middleware<{}, ApplicationState> = (api) => (
  next: Dispatch
) => (action) => {
  if (action.type === USER_FOUND) {
    const user = (action as OidcAction<User>).payload;
    if (user && !user.expired) {
      axios.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;
    }
  }

  return next(action);
};

export default function configureStore(
  history: History,
  initialState?: ApplicationState
) {
  const middleware = [
    thunk,
    saveAuthTokenMiddleware,
    routerMiddleware(history),
  ];

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  const enhancers = [];
  const windowIfDefined =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof window === 'undefined' ? null : (window as any);
  // eslint-disable-next-line no-underscore-dangle
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    // eslint-disable-next-line no-underscore-dangle
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
