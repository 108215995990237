// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { ApiCollection, ApiError } from 'models/api';
import { BlogItem, SkillDevelopmentNewsState } from 'models/state/news';
import { Action, Reducer } from 'redux';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export const SKILL_DEVELOPMENT_NEWS_FETCH_REQUEST =
  'SKILL_DEVELOPMENT_NEWS_FETCH_REQUEST';
export const SKILL_DEVELOPMENT_NEWS_FETCH_SUCCESS =
  'SKILL_DEVELOPMENT_NEWS_FETCH_SUCCESS';
export const SKILL_DEVELOPMENT_NEWS_FETCH_FAILURE =
  'SKILL_DEVELOPMENT_NEWS_FETCH_FAILURE';

interface SdNewsFetchRequestAction {
  type: typeof SKILL_DEVELOPMENT_NEWS_FETCH_REQUEST;
}

interface SdNewsFetchSuccessAction {
  type: typeof SKILL_DEVELOPMENT_NEWS_FETCH_SUCCESS;
  payload: BlogItem[];
}

interface SdNewsFetchFailureAction {
  type: typeof SKILL_DEVELOPMENT_NEWS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type SdNewsActions =
  | SdNewsFetchRequestAction
  | SdNewsFetchSuccessAction
  | SdNewsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  request: () =>
    // eslint-disable-next-line max-len
    ({
      type: SKILL_DEVELOPMENT_NEWS_FETCH_REQUEST,
    } as SdNewsFetchRequestAction),
  success: (data: BlogItem[]) =>
    ({
      type: SKILL_DEVELOPMENT_NEWS_FETCH_SUCCESS,
      payload: data,
    } as SdNewsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: SKILL_DEVELOPMENT_NEWS_FETCH_FAILURE,
      payload: error,
    } as SdNewsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestNewsThunk:
    (): AppThunkAction<SdNewsActions> => async (dispatch, getState) => {
      dispatch(actionCreators.request());

      api
        .get<ApiCollection<BlogItem>>(
          'api/v1/blogItems/skill-development?count=4'
        )
        .then(
          (data) => {
            dispatch(actionCreators.success(data.items));
          },
          (error: ApiError) => {
            dispatch(actionCreators.failure(error));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: SkillDevelopmentNewsState = {
  items: [],
  loading: false,
  error: undefined,
};

export const reducer: Reducer<SkillDevelopmentNewsState> = (
  state: SkillDevelopmentNewsState = defaultState,
  incomingAction: Action | undefined = undefined
): SkillDevelopmentNewsState => {
  const action = incomingAction as SdNewsActions;

  switch (action.type) {
    case SKILL_DEVELOPMENT_NEWS_FETCH_REQUEST:
      return { ...state, loading: true };

    case SKILL_DEVELOPMENT_NEWS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: undefined,
      };

    case SKILL_DEVELOPMENT_NEWS_FETCH_FAILURE:
      return { ...state, loading: false, items: [], error: action.payload };

    default:
      return state;
  }
};
