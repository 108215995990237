"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var scrollOptionIntoView = function (_a) {
    var newFocusIndex = _a.newFocusIndex, eventType = _a.eventType, listboxRef = _a.listboxRef;
    var listboxNode = listboxRef.current;
    if (listboxNode) {
        if (newFocusIndex === -1) {
            if (listboxNode) {
                if (newFocusIndex === -1) {
                    listboxNode.scrollTop = 0;
                }
            }
            return;
        }
        // Scroll active descendant into view.
        // Logic copied from https://www.w3.org/TR/wai-aria-practices/examples/listbox/js/listbox.js
        //
        // Consider this API instead once it has a better browser support:
        // .scrollIntoView({ scrollMode: 'if-needed', block: 'nearest' });
        if (listboxNode.scrollHeight > listboxNode.clientHeight && eventType !== 'mouse') {
            var option = listboxNode.querySelector("[data-option-index=\"" + newFocusIndex + "\"]");
            if (!option) {
                return;
            }
            var groupBy = false;
            var element = option;
            var scrollBottom = listboxNode.clientHeight + listboxNode.scrollTop;
            var elementBottom = element.offsetTop + element.offsetHeight;
            if (elementBottom > scrollBottom) {
                listboxNode.scrollTop = elementBottom - listboxNode.clientHeight;
            }
            else if (element.offsetTop - element.offsetHeight * (groupBy ? 1.3 : 0) < listboxNode.scrollTop) {
                listboxNode.scrollTop = element.offsetTop - element.offsetHeight * (groupBy ? 1.3 : 0);
            }
        }
    }
};
exports.default = scrollOptionIntoView;
