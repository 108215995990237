import { api } from 'shared/utils/api';
// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { ApiCollection, ApiError } from 'models/api';
import { Service } from 'models/state/services';

export const SERVICES_FETCH_REQUEST = 'SERVICES_FETCH_REQUEST';
export const SERVICES_FETCH_SUCCESS = 'SERVICES_FETCH_SUCCESS';
export const SERVICES_FETCH_FAILURE = 'SERVICES_FETCH_FAILURE';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface ServicesFetchRequestAction {
  type: typeof SERVICES_FETCH_REQUEST;
}

interface ServicesFetchSuccessAction {
  type: typeof SERVICES_FETCH_SUCCESS;
  payload: Service[];
}

interface ServicesFetchFailureAction {
  type: typeof SERVICES_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ServicesActions =
  | ServicesFetchRequestAction
  | ServicesFetchSuccessAction
  | ServicesFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const actionCreators = {
  request: () =>
    ({ type: SERVICES_FETCH_REQUEST } as ServicesFetchRequestAction),
  success: (data: Service[]) =>
    ({
      type: SERVICES_FETCH_SUCCESS,
      payload: data,
    } as ServicesFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: SERVICES_FETCH_FAILURE,
      payload: error,
    } as ServicesFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestServicesThunk: (): AppThunkAction<ServicesActions> => async (
    dispatch,
    getState
  ) => {
    dispatch(actionCreators.request());
    const url = '/api/v1/services?page=1&pageSize=10';
    api.get<ApiCollection<Service>>(url).then(
      (data) => {
        dispatch(actionCreators.success(data.items));
      },
      (error: ApiError) => {
        dispatch(actionCreators.failure(error));
      }
    );
  },
};
