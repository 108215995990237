import { FunctionComponent } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Footer.module.scss';

const FooterComponent: FunctionComponent = () => (
  <footer className={styles.footer}>
    <Container>
      <Row>
        <Col md={6}>
          <h3 className={styles.footerTitle}>Crayon contact information</h3>
          <ul className="list-unstyled">
            <li>
              <a
                href="https://www.crayon.com/support/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footerLink}
              >
                Crayon Support
              </a>
            </li>
          </ul>
        </Col>
        <Col md={6}>
          <h3 className={styles.footerTitle}>Additional information</h3>
          <ul className="list-unstyled">
            <li>
              <a
                target="_blank"
                href="https://www.crayon.com/en/about-us/"
                rel="noopener noreferrer"
                className={styles.footerLink}
              >
                About Crayon
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default FooterComponent;
