import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './MediaObject.module.scss';

interface MediaObjectProps {
  children?: ReactNode;
  url?: string;
  icon?: ReactNode;
  title: string;
  description?: string;
  hidden?: boolean;
}

const MediaObject: FunctionComponent<MediaObjectProps> = ({
  children,
  url,
  icon,
  title,
  description,
  hidden,
}: MediaObjectProps) =>
  url ? (
    <Link
      to={url}
      className={`${styles.mediaObject} ${styles.mediaObjectLink}`}
    >
      <div className={styles.settingsItemDescription}>
        <div className={styles.descriptionLink}>
          {icon}
          <span className={styles.descriptionLinkText}>{title}</span>
        </div>
        {description && (
          <div className={styles.descriptionText}>{description}</div>
        )}
      </div>
      {children}
    </Link>
  ) : (
    <div className={styles.mediaObject}>
      <div className={styles.settingsItemDescription}>
        <span className={styles.descriptionLink}>
          {icon}
          <span className={styles.descriptionLinkText}>{title}</span>
        </span>
        {description && (
          <div className={styles.descriptionText}>{description}</div>
        )}
      </div>
      {children}
    </div>
  );

export default MediaObject;
