import { Service, ServiceId } from 'models/state/services';
import { Layouts, Layout } from 'react-grid-layout';
import cloudIqLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_Cloud_iQ.svg';
import empowerIqLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_Empower_iQ.svg';
import samIqLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_SAM_iQ.svg';
import serviceIqLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_Service_iQ.svg';
import marketIqLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_Market_iQ.svg';
import coreIqLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_Core_iQ.svg';
import softwareDoLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_Software_Downloads.svg';
import optimaIqLogo from 'shared/images/service-logos/Crayon_iQ_logos_negative_Optima_iQ.svg';

export const getLogo = (id: string): string => {
  switch (id) {
    case ServiceId.CLOUD_IQ:
      return cloudIqLogo;
    case ServiceId.OPTIMA_IQ:
      return optimaIqLogo;
    case ServiceId.EMPOWER_IQ:
      return empowerIqLogo;
    case ServiceId.SAM_IQ:
      return samIqLogo;
    case ServiceId.MARKET_IQ:
      return marketIqLogo;
    case ServiceId.SERVICE_IQ:
      return serviceIqLogo;
    case ServiceId.CORE_IQ:
      return coreIqLogo;
    case ServiceId.SOFTWARE_DO:
      return softwareDoLogo;
    default:
      return '';
  }
};

export const getClassName = (id: string): string => {
  switch (id) {
    case ServiceId.CLOUD_IQ:
      return 'service-cloud-iq';
    case ServiceId.OPTIMA_IQ:
      return 'service-optima-iq';
    case ServiceId.EMPOWER_IQ:
      return 'service-empower-iq';
    case ServiceId.SAM_IQ:
      return 'service-sam-iq';
    case ServiceId.SERVICE_IQ:
      return 'service-service-iq';
    case ServiceId.MARKET_IQ:
      return 'service-market-iq';
    case ServiceId.CORE_IQ:
      return 'service-core-iq';
    case ServiceId.SOFTWARE_DO:
      return 'service-software-downloads';
    default:
      return '';
  }
};

const getSmallLayout = (id: ServiceId, index: number): Layout => ({
  i: id,
  x: 0,
  y: index * 6,
  w: 12,
  h: 6,
  static: true,
});

function getSingleServiceLayout(services: Service[]): Layouts {
  const singleLayouts: Layout[] = [
    { i: services[0].id, x: 0, y: 0, w: 6, h: 12, static: true },
  ];
  return {
    lg: singleLayouts,
    md: singleLayouts,
    sm: [getSmallLayout(services[0].id, 0)],
    xs: [getSmallLayout(services[0].id, 0)],
    xxs: [getSmallLayout(services[0].id, 0)],
  };
}

function getTwoServiceLayouts(services: Service[]): Layouts {
  const smallestTwo = [
    getSmallLayout(services[0].id, 0),
    getSmallLayout(services[1].id, 1),
  ];
  return {
    lg: [
      { i: services[0].id, x: 0, y: 0, w: 6, h: 12, static: true },
      { i: services[1].id, x: 6, y: 0, w: 6, h: 12, static: true },
    ],
    md: [
      { i: services[0].id, x: 0, y: 0, w: 5, h: 10, static: true },
      { i: services[1].id, x: 5, y: 0, w: 5, h: 10, static: true },
    ],
    sm: smallestTwo,
    xs: smallestTwo,
    xxs: smallestTwo,
  };
}

function getThreeServiceLayouts(services: Service[]): Layouts {
  const smallestThree = [
    getSmallLayout(services[0].id, 0),
    getSmallLayout(services[1].id, 1),
    getSmallLayout(services[2].id, 2),
  ];
  return {
    lg: [
      { i: services[0].id, x: 0, y: 0, w: 6, h: 12, static: true },
      { i: services[1].id, x: 6, y: 0, w: 6, h: 6, static: true },
      { i: services[2].id, x: 6, y: 6, w: 6, h: 6, static: true },
    ],
    md: [
      { i: services[0].id, x: 0, y: 0, w: 5, h: 10, static: true },
      { i: services[1].id, x: 5, y: 0, w: 5, h: 10, static: true },
      { i: services[2].id, x: 0, y: 10, w: 10, h: 6, static: true },
    ],
    sm: smallestThree,
    xs: smallestThree,
    xxs: smallestThree,
  };
}

function getFourServiceLayouts(services: Service[]): Layouts {
  const smallestFour = [
    getSmallLayout(services[0].id, 0),
    getSmallLayout(services[1].id, 1),
    getSmallLayout(services[2].id, 2),
    getSmallLayout(services[3].id, 3),
  ];
  return {
    /* 1200 and up | 12 columns */
    lg: [
      { i: services[0].id, x: 0, y: 0, w: 6, h: 6, static: true },
      { i: services[1].id, x: 6, y: 0, w: 6, h: 6, static: true },
      { i: services[2].id, x: 0, y: 6, w: 6, h: 6, static: true },
      { i: services[3].id, x: 6, y: 6, w: 6, h: 6, static: true },
    ],
    /* 992 and up to 1199 | 10 columns */
    md: [
      { i: services[0].id, x: 0, y: 0, w: 5, h: 7, static: true },
      { i: services[1].id, x: 5, y: 0, w: 5, h: 7, static: true },
      { i: services[2].id, x: 0, y: 7, w: 5, h: 7, static: true },
      { i: services[3].id, x: 5, y: 7, w: 5, h: 7, static: true },
    ],
    sm: smallestFour,
    xs: smallestFour,
    xxs: smallestFour,
  };
}

function getFiveServiceLayouts(services: Service[]): Layouts {
  const smallestFive = [
    getSmallLayout(services[0].id, 0),
    getSmallLayout(services[1].id, 1),
    getSmallLayout(services[2].id, 2),
    getSmallLayout(services[3].id, 3),
    getSmallLayout(services[4].id, 4),
  ];
  return {
    /* 1200 and up | 12 columns */
    lg: [
      { i: services[0].id, x: 0, y: 0, w: 6, h: 6, static: true },
      { i: services[1].id, x: 6, y: 0, w: 6, h: 6, static: true },
      { i: services[2].id, x: 0, y: 6, w: 6, h: 6, static: true },
      { i: services[3].id, x: 6, y: 6, w: 6, h: 6, static: true },
      { i: services[4].id, x: 0, y: 12, w: 6, h: 6, static: true },
    ],
    /* 992 and up to 1199 | 10 columns */
    md: [
      { i: services[0].id, x: 0, y: 0, w: 5, h: 7, static: true },
      { i: services[1].id, x: 5, y: 0, w: 5, h: 7, static: true },
      { i: services[2].id, x: 0, y: 7, w: 5, h: 7, static: true },
      { i: services[3].id, x: 5, y: 7, w: 5, h: 7, static: true },
      { i: services[4].id, x: 0, y: 14, w: 5, h: 7, static: true },
    ],
    sm: smallestFive,
    xs: smallestFive,
    xxs: smallestFive,
  };
}

function getSixServiceLayouts(services: Service[]): Layouts {
  const smallestSix = [
    getSmallLayout(services[0].id, 0),
    getSmallLayout(services[1].id, 1),
    getSmallLayout(services[2].id, 2),
    getSmallLayout(services[3].id, 3),
    getSmallLayout(services[4].id, 4),
    getSmallLayout(services[5].id, 5),
  ];
  return {
    /* 1200 and up | 12 columns */
    lg: [
      { i: services[0].id, x: 0, y: 0, w: 6, h: 6, static: true },
      { i: services[1].id, x: 6, y: 0, w: 6, h: 6, static: true },
      { i: services[2].id, x: 0, y: 6, w: 6, h: 6, static: true },
      { i: services[3].id, x: 6, y: 6, w: 6, h: 6, static: true },
      { i: services[4].id, x: 0, y: 12, w: 6, h: 6, static: true },
      { i: services[5].id, x: 6, y: 12, w: 6, h: 6, static: true },
    ],
    /* 992 and up to 1199 | 10 columns */
    md: [
      { i: services[0].id, x: 0, y: 0, w: 5, h: 7, static: true },
      { i: services[1].id, x: 5, y: 0, w: 5, h: 7, static: true },
      { i: services[2].id, x: 0, y: 7, w: 5, h: 7, static: true },
      { i: services[3].id, x: 5, y: 7, w: 5, h: 7, static: true },
      { i: services[4].id, x: 0, y: 14, w: 5, h: 7, static: true },
      { i: services[5].id, x: 5, y: 14, w: 5, h: 7, static: true },
    ],
    sm: smallestSix,
    xs: smallestSix,
    xxs: smallestSix,
  };
}

// https://github.com/STRML/react-grid-layout#grid-item-props
export const getLayouts = (services: Service[]): Layouts => {
  switch (services.length) {
    case 1:
      return getSingleServiceLayout(services);
    case 2:
      return getTwoServiceLayouts(services);
    case 3:
      return getThreeServiceLayouts(services);
    case 4:
      return getFourServiceLayouts(services);
    case 5:
      return getFiveServiceLayouts(services);
    case 6:
      return getSixServiceLayouts(services);
    default:
      return {
        lg: [{ i: '', x: 0, y: 0, w: 0, h: 0 }],
        md: [{ i: '', x: 0, y: 0, w: 0, h: 0 }],
        sm: [{ i: '', x: 0, y: 0, w: 0, h: 0 }],
        xs: [{ i: '', x: 0, y: 0, w: 0, h: 0 }],
        xxs: [{ i: '', x: 0, y: 0, w: 0, h: 0 }],
      };
  }
};
