import {
  ReactNode,
  FunctionComponent,
  MouseEvent,
  cloneElement,
  ReactElement,
} from 'react';
import styles from './StepProgress.module.scss';

interface StepProgressProps {
  children: Array<ReactNode>;
  activeStepKey: string;
}

interface StepProps {
  stepKey: string;
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

interface StepExtendedProps extends StepProps {
  active?: boolean;
}

export const Step: FunctionComponent<StepProps> = ({
  children,
  active,
  onClick,
}: StepExtendedProps) => {
  let css = `${styles.step} ${active ? styles.active : ''}  steps-spacing`;

  if (onClick) {
    css += ` ${styles.clickable}`;

    return (
      <button type="button" className={css} onClick={onClick}>
        {children}
      </button>
    );
  }

  return <div className={css}>{children}</div>;
};

export const StepProgress: FunctionComponent<StepProgressProps> = ({
  children,
  activeStepKey,
}: StepProgressProps) => (
  <div className={`${styles.cSteps}`}>
    {children &&
      children.map((slide, index) => {
        const elementChild = slide as ReactElement<StepProps>;

        return cloneElement<StepExtendedProps>(elementChild, {
          key: `child_${elementChild.props.stepKey}`,
          active: elementChild.props.stepKey === activeStepKey,
        });
      })}
  </div>
);

export default StepProgress;
