import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const useDebounce = <T>(initialValue: T) => {
  const [value, setValue] = useState(initialValue);
  const delay = 250;
  useEffect(() => {
    const handler = setTimeout(() => setValue(initialValue), delay);
    return () => clearTimeout(handler);
  }, [delay, initialValue]);
  return value;
};

type UseDebounceHook<T> = [T, (val: T) => void, Dispatch<SetStateAction<T>>];

const useDebounceState = <T>(initialValue: T): UseDebounceHook<T> => {
  const [value, setValue] = useState(initialValue);
  const debounceValue = useDebounce<T>(value);
  return [value, setValue, 
    debounceValue as unknown as Dispatch<SetStateAction<T>>];
};

export { useDebounce, useDebounceState };