import { Action, Reducer } from 'redux';
import { ServicesState } from 'models/state/services';
// eslint-disable-next-line import/no-cycle
import {
  ServicesActions,
  SERVICES_FETCH_REQUEST,
  SERVICES_FETCH_SUCCESS,
  SERVICES_FETCH_FAILURE,
} from './actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: ServicesState = {
  items: [],
  loading: false,
  error: undefined,
};

export const reducer: Reducer<ServicesState> = (
  state: ServicesState = defaultState,
  incomingAction: Action | undefined = undefined
): ServicesState => {
  const action = incomingAction as ServicesActions;

  switch (action.type) {
    case SERVICES_FETCH_REQUEST:
      return { ...state, loading: true };

    case SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: undefined,
      };

    case SERVICES_FETCH_FAILURE:
      return { ...state, loading: false, items: [], error: action.payload };

    default:
      return state;
  }
};
