import { Action, Reducer } from 'redux';
import { ContactsState } from 'models/state/contacts';
// eslint-disable-next-line import/no-cycle
import {
  ContactsActions,
  CONTACTS_FETCH_REQUEST,
  CONTACTS_FETCH_SUCCESS,
  CONTACTS_FETCH_FAILURE,
} from './actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: ContactsState = {
  items: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<ContactsState> = (
  state: ContactsState = defaultState,
  incomingAction: Action | undefined = undefined
): ContactsState => {
  const action = incomingAction as ContactsActions;

  switch (action.type) {
    case CONTACTS_FETCH_REQUEST:
      return { ...state, loading: true };

    case CONTACTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: undefined,
      };

    case CONTACTS_FETCH_FAILURE:
      return { ...state, loading: false, items: [], error: action.payload };

    default:
      return state;
  }
};
