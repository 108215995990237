/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import './breadcumbs.scss';

interface BreadcrumbsProps {
  children?: ReactNode;
  className?: string;
}

interface BreadcrumbsItemProps {
  href?: string;
  title: string;
  active?: boolean;
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  children,
  className,
}) => {
  if (children === undefined) return null;

  return (
    <ol className={`breadcrumb${className ? ` ${className}` : ''}`}>
      {children}
    </ol>
  );
};

export const BreadcrumbsItem: FunctionComponent<BreadcrumbsItemProps> = ({
  href,
  title,
  active,
}) => {
  if (active === true) {
    return (
      <li className="breadcrumb-item active" aria-current="page">
        {title}
      </li>
    );
  }

  if (href !== undefined && href !== '') {
    return (
      <li className="breadcrumb-item">
        <Link to={href}>{title}</Link>
      </li>
    );
  }

  return null;
};
