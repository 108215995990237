import { FunctionComponent } from 'react';
import { TicketStatus } from 'models/state/tickets';

interface TicketStatusProps {
  ticketStatus: TicketStatus;
  noText?: boolean;
}

const TicketStatusLabel: FunctionComponent<TicketStatusProps> = ({
  ticketStatus,
  noText,
}: TicketStatusProps) => {
  const renderStatus = (
    statusClass:
      | 'new'
      | 'open'
      | 'closed'
      | 'pending'
      | 'solved'
      | 'onHold'
      | 'deleted',
    text: string,
    showText: boolean
  ) => {
    const title = showText ? undefined : text;
    return (
      <>
        <span className={`circle ${statusClass}`} title={title} />
        {showText && <>{text}</>}
      </>
    );
  };

  return (
    <>
      {ticketStatus === TicketStatus.New && renderStatus('new', 'New', !noText)}

      {ticketStatus === TicketStatus.Open &&
        renderStatus('open', 'Open', !noText)}

      {ticketStatus === TicketStatus.Closed &&
        renderStatus('closed', 'Closed', !noText)}

      {/* is pending waiting for user input or crayon input? which color should this one be? */}
      {ticketStatus === TicketStatus.Pending &&
        renderStatus('pending', 'Pending', !noText)}

      {ticketStatus === TicketStatus.Solved &&
        renderStatus('solved', 'Solved', !noText)}

      {/* what color should this one be? */}
      {ticketStatus === TicketStatus.Hold &&
        renderStatus('onHold', 'On hold', !noText)}

      {ticketStatus === TicketStatus.Deleted &&
        renderStatus('deleted', 'Deleted', !noText)}
    </>
  );
};

export default TicketStatusLabel;
