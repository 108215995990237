import { FunctionComponent, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import styles from './SettingsList.module.scss';

interface SettingsListItemProps {
  url: string;
  icon: ReactNode;
  title: string;
  description: string;
  hidden?: boolean;
}

const SettingsListItem: FunctionComponent<SettingsListItemProps> = ({
  url,
  icon,
  title,
  description,
  hidden,
}: SettingsListItemProps) => (
  <li hidden={hidden} className={styles.settingsListItem}>
    <div className={styles.settingsItemDescription}>
      <a href={url} className={styles.descriptionLink}>
        {icon}
        <span className={styles.descriptionLinkText}>{title}</span>
      </a>
      <div className={styles.descriptionText}>{description}</div>
    </div>
    <a href={url} className={styles.editLink}>
      <FontAwesomeIcon icon={faPen} className={styles.editSvg} />
      Edit
    </a>
  </li>
);

export default SettingsListItem;
