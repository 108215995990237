// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { ApiCollection, ApiError } from 'models/api';
import {
  Ticket,
  CompanyTicketsState,
  TicketsFilter,
} from 'models/state/tickets';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const COMPANY_TICKETS_FETCH_REQUEST = 'COMPANY_TICKETS_FETCH_REQUEST';
const COMPANY_TICKETS_FETCH_SUCCESS = 'COMPANY_TICKETS_FETCH_SUCCESS';
const COMPANY_TICKETS_FETCH_FAILURE = 'COMPANY_TICKETS_FETCH_FAILURE';

interface CompanyTicketsFetchRequestAction extends Action<string> {
  type: typeof COMPANY_TICKETS_FETCH_REQUEST;
}

interface CompanyTicketsFetchSuccessAction extends Action<string> {
  type: typeof COMPANY_TICKETS_FETCH_SUCCESS;
  payload: ApiCollection<Ticket>;
}

interface CompanyTicketsFetchFailureAction extends Action<string> {
  type: typeof COMPANY_TICKETS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type CompanyTicketsActions =
  | CompanyTicketsFetchRequestAction
  | CompanyTicketsFetchSuccessAction
  | CompanyTicketsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: () =>
    ({
      type: COMPANY_TICKETS_FETCH_REQUEST,
    } as CompanyTicketsFetchRequestAction),
  success: (data: ApiCollection<Ticket>) =>
    ({
      type: COMPANY_TICKETS_FETCH_SUCCESS,
      payload: data,
    } as CompanyTicketsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: COMPANY_TICKETS_FETCH_FAILURE,
      payload: error,
    } as CompanyTicketsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestTickets:
    (
      filter: TicketsFilter = {
        page: 1,
        pageSize: 20,
        searchText: '',
        status: undefined,
      }
    ): AppThunkAction<CompanyTicketsActions> =>
    async (dispatch) => {
      dispatch(actionCreators.request());

      let qs = `?page=${filter.page}&pageSize=${filter.pageSize}&searchText=${filter.searchText}`;

      if (filter.status) {
        qs += `&status=${filter.status}`;
      }

      api.get<ApiCollection<Ticket>>(`api/v1/tickets/company/${qs}`).then(
        (data) => {
          dispatch(actionCreators.success(data));
        },
        (error: ApiError) => {
          dispatch(actionCreators.failure(error));
        }
      );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: CompanyTicketsState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<CompanyTicketsState> = (
  state: CompanyTicketsState = defaultState,
  incomingAction: Action | undefined = undefined
): CompanyTicketsState => {
  const action = incomingAction as CompanyTicketsActions;

  switch (action.type) {
    case COMPANY_TICKETS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPANY_TICKETS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: undefined,
      };

    case COMPANY_TICKETS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
