import { ReactNode, FunctionComponent, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

import styles from './ExpansionPanel.module.scss';
import ExpansionPanelDivider from './ExpansionPanelDivider';

interface ExpansionPanelProps {
  children?: ReactNode;
  title: string;
  activeKey?: string;
  columns: number;
}

const ExpansionPanel: FunctionComponent<ExpansionPanelProps> = ({
  children,
  title,
  columns,
  ...rest
}: ExpansionPanelProps) => {
  const { cardHeader, toggleButton } = styles;
  const [activeKey, setActiveKey] = useState(rest.activeKey);
  return (
    <Accordion activeKey={activeKey} className={styles.expansionPanelWrapper}>
      <Card>
        <Card.Header className={cardHeader}>
          <Button
            className={toggleButton}
            onClick={() => setActiveKey(!activeKey ? '0' : undefined)}
          >
            <span>{title}</span>
            <FontAwesomeIcon
              icon={activeKey === '0' ? faChevronUp : faChevronDown}
            />
          </Button>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className={styles.expansionPanelCardBody}>
            <ExpansionPanelDivider columns={columns} />
            {children}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ExpansionPanel;
