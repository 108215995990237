// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { AuthConfig } from 'models/state/auth';
import { ApiError } from 'models/api';

export const AUTH_FETCH_REQUEST = 'AUTH_FETCH_REQUEST';
export const AUTH_FETCH_SUCCESS = 'AUTH_FETCH_SUCCESS';
export const AUTH_FETCH_FAILURE = 'AUTH_FETCH_FAILURE';
export const AUTH_IS_SIGNING_OUT = 'AUTH_IS_SIGNING_OUT';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface AuthFetchRequestAction {
  type: typeof AUTH_FETCH_REQUEST;
}

interface AuthFetchSuccessAction {
  type: typeof AUTH_FETCH_SUCCESS;
  payload: AuthConfig;
}

interface AuthFetchFailureAction {
  type: typeof AUTH_FETCH_FAILURE;
  payload: ApiError;
}

interface IsSigningOutAction {
  type: typeof AUTH_IS_SIGNING_OUT;
  payload: boolean;
}

export type AuthActions =
  | AuthFetchRequestAction
  | AuthFetchSuccessAction
  | AuthFetchFailureAction
  | IsSigningOutAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  request: () => ({ type: AUTH_FETCH_REQUEST } as AuthFetchRequestAction),
  success: (data: AuthConfig) =>
    ({
      type: AUTH_FETCH_SUCCESS,
      payload: data,
    } as AuthFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: AUTH_FETCH_FAILURE,
      payload: error,
    } as AuthFetchFailureAction),
  setIsSigningOut: (payload: boolean) =>
    ({
      type: AUTH_IS_SIGNING_OUT,
      payload,
    } as IsSigningOutAction),
};

// ----------------
// THUNKS
export const authThunks = {
  requestAuthConfigThunk: (): AppThunkAction<AuthActions> => async (
    dispatch,
    getState
  ) => {
    dispatch(actionCreators.request());

    const url = '/api/v1/settings/auth';
    api.get<AuthConfig>(url).then(
      (data) => {
        dispatch(actionCreators.success(data));
      },
      (error: ApiError) => {
        dispatch(actionCreators.failure(error));
      }
    );
  },
};
