import { FunctionComponent, useEffect } from 'react';
import { SoftwareFilesFilter } from 'models/state/software-downloads';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { thunks as filesThunks } from 'store/software-downloads/software-download-files';
import { LoadingSpinner } from 'shared/components';
import SoftwareDownloadFile from './SoftwareDownloadFile';

interface FilesProps {
  filter: SoftwareFilesFilter;
}

const SoftwareDownloadFiles: FunctionComponent<FilesProps> = ({ filter }) => {
  const dispatch = useDispatch();

  const index = `${filter.product}${filter.operatingSystem.toString()}${
    filter.type
  }` as unknown as number;

  const { loading, error, data } = useSelector(
    (state: ApplicationState) =>
      state.softwareDownloads.softwareDownloadFiles[index] ?? {
        loading: false,
      }
  );

  useEffect(() => {
    if (!loading && !error && !data) {
      dispatch(filesThunks.requestFiles(filter, index));
    }
  }, [loading, error, data, dispatch, filter, index]);

  return (
    <>
      {loading && (
        <LoadingSpinner className="section-margin blockquote text-center" />
      )}
      {!loading && error && (
        <div className="section-title-spacing">
          <p>Error fetching software download files</p>
        </div>
      )}
      {!loading && !error && data && (
        <>
          {data.items.length === 0 && (
            <div className="u-py-5 u-bg-white u-text-center">
              No files found
            </div>
          )}
          <div className="c-table-row__inner c-dl c-dl--md-indent u-align-middle">
            <table className="c-table c-table--dark">
              <thead>
                <tr>
                  <th className="u-w-1 u-d-none u-d-md-table-cell"> </th>
                  <th>File</th>
                  <th className="u-d-none u-d-md-table-cell">Version</th>
                  <th className="u-d-none u-d-md-table-cell">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((software) => (
                  <SoftwareDownloadFile key={software.id} software={software} />
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default SoftwareDownloadFiles;
