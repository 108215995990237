import notFoundImage from 'shared/images/error-pages/404.svg';

const PageNotFound = () => (
  <div style={{ textAlign: 'center' }}>
    <img alt="Page not found" src={notFoundImage} />
    <h3 className="section-title-spacing">Page not found</h3>
    <p>
      The page you are looking for was moved, removed or might never existed.
    </p>
  </div>
);

export default PageNotFound;
