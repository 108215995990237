import { FunctionComponent, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from '@crayoncupl/styleguide-react/lib/Collapse/Collapse';
import {
  SoftwareDownloadGroup,
  Product,
  Type,
  OperatingSystem,
} from 'models/state/software-downloads';
import SoftwareDownloadFiles from './SoftwareDownloadFiles';

interface SoftwareDownloadsProps {
  softwareGroup: SoftwareDownloadGroup;
}

const SoftwareDownloadRow: FunctionComponent<SoftwareDownloadsProps> = ({
  softwareGroup,
}) => {
  const [tableRowOpen, setTableRowOpen] = useState(false);

  return (
    <>
      <tr
        className={`c-table-row${
          tableRowOpen ? ' c-table-row--open' : ' c-table-row--closed'
        }`}
      >
        <td className="u-td-icon">
          <button
            className="c-icon-btn"
            type="button"
            onClick={() => setTableRowOpen(!tableRowOpen)}
          >
            <span className="c-icon-btn__inner">
              <FontAwesomeIcon
                icon={tableRowOpen ? faChevronUp : faChevronDown}
              />
            </span>
          </button>
        </td>
        <td>
          {Product[softwareGroup.product!]}{' '}
          <span className="u-d-inline u-d-sm-none">
            <br />
            {Type[softwareGroup.type!]},{' '}
            {OperatingSystem[softwareGroup.operatingSystem!]}
          </span>
        </td>
        <td className="u-d-none u-d-sm-table-cell">
          {Type[softwareGroup.type!]}
        </td>
        <td className="u-d-none u-d-sm-table-cell">
          {OperatingSystem[softwareGroup.operatingSystem!]}
        </td>
      </tr>
      <tr
        className={`c-table-row${
          tableRowOpen
            ? ' c-table-row--expanded c-table-row--strong'
            : ' c-table-row--collapsed'
        }`}
      >
        {tableRowOpen && (
          <td colSpan={4}>
            <Collapse closed={!tableRowOpen}>
              <SoftwareDownloadFiles
                key={`softwareFiles-${softwareGroup.product}-${softwareGroup.operatingSystem}-${softwareGroup.type}`}
                filter={{
                  operatingSystem: softwareGroup.operatingSystem,
                  product: softwareGroup.product,
                  type: softwareGroup.type,
                }}
              />
            </Collapse>
          </td>
        )}
      </tr>
    </>
  );
};

export default SoftwareDownloadRow;
