// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Translations } from 'models/state/translations';
import { ApiError } from 'models/api';

export const TRANSLATIONS_FETCH_REQUEST = 'TRANSLATIONS_FETCH_REQUEST';
export const TRANSLATIONS_FETCH_SUCCESS = 'TRANSLATIONS_FETCH_SUCCESS';
export const TRANSLATIONS_FETCH_FAILURE = 'TRANSLATIONS_FETCH_FAILURE';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface TranslationsFetchRequestAction {
  type: typeof TRANSLATIONS_FETCH_REQUEST;
}

interface TranslationsFetchSuccessAction {
  type: typeof TRANSLATIONS_FETCH_SUCCESS;
  payload: Translations;
}

interface TranslationsFetchFailureAction {
  type: typeof TRANSLATIONS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type TranslationsActions =
  | TranslationsFetchRequestAction
  | TranslationsFetchSuccessAction
  | TranslationsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const actionCreators = {
  request: () =>
    ({ type: TRANSLATIONS_FETCH_REQUEST } as TranslationsFetchRequestAction),
  success: (data: Translations) =>
    ({
      type: TRANSLATIONS_FETCH_SUCCESS,
      payload: data,
    } as TranslationsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: TRANSLATIONS_FETCH_FAILURE,
      payload: error,
    } as TranslationsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestTranslationsThunk: (): AppThunkAction<TranslationsActions> => async (
    dispatch,
    getState
  ) => {
    dispatch(actionCreators.request());

    const url = '/api/v1/translations';
    api.get<Translations>(url).then(
      (data) => {
        dispatch(actionCreators.success(data));
      },
      (error: ApiError) => {
        dispatch(actionCreators.failure(error));
      }
    );
  },
};
