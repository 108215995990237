/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import { TicketsState } from 'models/state/tickets';
import { reducer as ccReducer } from './cc-tickets';
import { reducer as companyReducer } from './company-tickets';
import { reducer as myReducer } from './my-tickets';
import { reducer as openReducer } from './open-tickets';
import { reducer as commentReducer } from './ticket-comment';
import { reducer as commentsReducer } from './ticket-comments';
import { reducer as detailsReducer } from './ticket-details';
import { reducer as createReducer } from './tickets-create';
import { reducer as ticketHistoryReducer } from './ticket-history-tags';
import { reducer as ticketFormReducer } from './ticket-form';
import { reducer as supportFormsReducer } from './support-forms';

export const ticketsReducer = combineReducers<TicketsState>({
  ccTickets: ccReducer,
  companyTickets: companyReducer,
  myTickets: myReducer,
  openTickets: openReducer,
  ticketComment: commentReducer,
  ticketComments: commentsReducer,
  ticketDetails: detailsReducer,
  ticketCreate: createReducer,
  ticketHistory: ticketHistoryReducer,
  ticketForm: ticketFormReducer,
  supportForms: supportFormsReducer,
});
