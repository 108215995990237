import axios from 'axios';

// eslint-disable-next-line max-len
export const downloadFile = async (url: string, fileName: string, mime?: string, bom?: BlobPart, callback?: () => void) => {

  axios({
    url,
    method: 'GET',
    responseType: 'blob' // important
  }).then((response) => {
    const blobData = typeof bom !== 'undefined' ? [bom, response.data] : [response.data];
    const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

    // eslint-disable-next-line max-len
    const navigator = window.navigator as { msSaveBlob?: (blob: Blob, fileName: string) => void };

    if (navigator.msSaveBlob) {
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      navigator.msSaveBlob(blob, fileName);
    } else {
      const blobURL =
        window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(blob)
          : window.webkitURL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', fileName);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);
      tempLink.click();

      // Fixes "webkit blob resource error 1"
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }, 200);
    }

    if (callback) {
      callback();
    }
  });
};
