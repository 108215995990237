import { ApiError } from 'models/api';

export interface Service {
  id: ServiceId;
  name: string;
  linkUrl: string;
  marketingLink: string;
  heading: string;
  description: string;
  owned: boolean;
}

export enum ServiceId {
  CLOUD_IQ = 'CLOUDIQ',
  SAM_IQ = 'SAMIQ',
  SERVICE_IQ = 'SERVICEIQ',
  EMPOWER_IQ = 'EMPOWERIQ',
  MARKET_IQ = 'MARKETIQ',
  CORE_IQ = 'COREIQ',
  SOFTWARE_DO = 'SOFTWAREDO',
  OPTIMA_IQ = 'CRAYONIQX',
}

export interface ServicesState {
  items: Service[];
  loading: boolean;
  error?: ApiError;
}
