// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { ApiCollection, ApiError } from 'models/api';
import { Ticket, OpenTicketsState } from 'models/state/tickets';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const OPEN_TICKETS_FETCH_REQUEST = 'OPEN_TICKETS_FETCH_REQUEST';
const OPEN_TICKETS_FETCH_SUCCESS = 'OPEN_TICKETS_FETCH_SUCCESS';
const OPEN_TICKETS_FETCH_FAILURE = 'OPEN_TICKETS_FETCH_FAILURE';

interface OpenTicketsFetchRequestAction extends Action<string> {
  type: typeof OPEN_TICKETS_FETCH_REQUEST;
}

interface OpenTicketsFetchSuccessAction extends Action<string> {
  type: typeof OPEN_TICKETS_FETCH_SUCCESS;
  payload: Ticket[];
}

interface OpenTicketsFetchFailureAction extends Action<string> {
  type: typeof OPEN_TICKETS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type OpenTicketsActions =
  | OpenTicketsFetchRequestAction
  | OpenTicketsFetchSuccessAction
  | OpenTicketsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: () =>
    ({
      type: OPEN_TICKETS_FETCH_REQUEST,
    } as OpenTicketsFetchRequestAction),
  success: (data: Ticket[]) =>
    ({
      type: OPEN_TICKETS_FETCH_SUCCESS,
      payload: data,
    } as OpenTicketsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: OPEN_TICKETS_FETCH_FAILURE,
      payload: error,
    } as OpenTicketsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestTickets:
    (): AppThunkAction<OpenTicketsActions> => async (dispatch) => {
      dispatch(actionCreators.request());
      api
        .get<ApiCollection<Ticket>>(
          'api/v1/tickets?page=1&pageSize=7&status=open&status=new&status=pending&status=hold'
        )
        .then(
          (data) => {
            dispatch(actionCreators.success(data.items));
          },
          (error: ApiError) => {
            dispatch(actionCreators.failure(error));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: OpenTicketsState = {
  items: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<OpenTicketsState> = (
  state: OpenTicketsState = defaultState,
  incomingAction: Action | undefined = undefined
): OpenTicketsState => {
  const action = incomingAction as OpenTicketsActions;

  switch (action.type) {
    case OPEN_TICKETS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OPEN_TICKETS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: undefined,
      };

    case OPEN_TICKETS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        items: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
