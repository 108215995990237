import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { TicketCard } from 'modules/tickets/components';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './TicketNewStepThree.module.scss';

interface TicketNewStepThreeProps {
  ticketId: string;
  goToStepOne: () => void;
}

const TicketNewStepThree: FunctionComponent<TicketNewStepThreeProps> = ({
  ticketId,
  goToStepOne,
}: TicketNewStepThreeProps) => (
  <>
    <div className={`alert alert-success ${styles.thankYouAlert}`}>
      <FontAwesomeIcon icon={faCheckCircle} className={styles.iconStyles} />
      Thank you for your request!
    </div>

    <Card>
      <Card.Body className={styles.thankYouPage}>
        <Row>
          <Col md={{ span: 6 }}>
            <h3>Ticket ID #{ticketId}</h3>
            <span className="body2">
              We have received your inquiry and it’s been assigned the reference
              number presented above.
              <br />
              One of our support agents will contact you shortly to assist. If
              you want to add more information to the ticket, you can do this
              directly in the portal.
            </span>
            <div className="body2" style={{ marginTop: '1.5rem' }}>
              Want to create another ticket?{' '}
              <Link onClick={goToStepOne} to="/tickets/new">
                Click here
              </Link>
              .
            </div>
          </Col>
          <Col md={{ span: 6 }} className={styles.thankYouStatusBox}>
            <TicketCard
              icon={<FontAwesomeIcon icon={faSearch} />}
              url={`/tickets/${ticketId}`}
              title="Track the status of your request"
              description="Track the status and see details of your request"
              styleTileBordered={true}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </>
);

export default TicketNewStepThree;
