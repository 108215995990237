"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
function useAjaxQueue() {
    var _a = react_1.useState(0), counter = _a[0], setCounter = _a[1];
    var _b = react_1.useState(undefined), sessionId = _b[0], setSessionId = _b[1];
    var _c = react_1.useState([]), queueItems = _c[0], setQueueItems = _c[1];
    var _d = react_1.useState([]), completedRequests = _d[0], setCompletedRequests = _d[1];
    var ref = react_1.default.useRef(-1);
    var generateSessionId = function () {
        return (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10);
    };
    var isLatestRequest = function (requestId) {
        if (queueItems.length > 0) {
            var lastRequest = queueItems[queueItems.length - 1];
            return lastRequest.requestId === requestId && sessionId === lastRequest.sessionId;
        }
        return false;
    };
    var addQueueItem = function (requestId) {
        setCounter(counter + 1);
        var newValue = {
            order: counter,
            completed: undefined,
            abortAjax: undefined,
            sessionId: sessionId || '',
            requestId: requestId,
            more: false,
            aborted: false
        };
        for (var i = 0; i < queueItems.length; ++i) {
            var o = queueItems[i];
            if (o.abortAjax) {
                o.abortAjax();
            }
        }
        setQueueItems(__spreadArrays(queueItems, [newValue]));
        return newValue;
    };
    var isRequestActive = function () {
        var loadingItem = queueItems.find(function (ajaxQueueItem) { return !ajaxQueueItem.completed; });
        return loadingItem !== undefined;
    };
    var lastPageCompleted = function () {
        if (completedRequests.length > 0) {
            var lastCompletedRequest = completedRequests[completedRequests.length - 1];
            if (lastCompletedRequest.sessionId === sessionId) {
                return lastCompletedRequest.more && lastCompletedRequest.sessionId === sessionId;
            }
        }
        return false;
    };
    var morePagesExists = function () {
        if (completedRequests.length > 0) {
            var lastCompletedRequest = completedRequests[completedRequests.length - 1];
            if (lastCompletedRequest.sessionId === sessionId) {
                return lastCompletedRequest.more && lastCompletedRequest.sessionId === sessionId;
            }
        }
        return true;
    };
    var startNewSession = function () {
        setSessionId(generateSessionId());
    };
    var firstRequestOnNewSession = function () {
        if (completedRequests.length > 0) {
            var lastCompletedRequest = completedRequests[completedRequests.length - 1];
            if (lastCompletedRequest.sessionId === sessionId) {
                if (lastCompletedRequest.sessionId === sessionId) {
                    return false;
                }
            }
        }
        return true;
    };
    var isLoading = function () {
        var seconds = 500 * 1;
        var threshold = new Date(new Date().getTime() - seconds);
        var loadingItem = queueItems.find(function (ajaxQueueItem) {
            return !ajaxQueueItem.completed || ajaxQueueItem.completed > threshold;
        });
        return loadingItem === undefined;
    };
    var newerRequestCompleted = function (completedItem) {
        if (completedItem.order < ref.current) {
            return true;
        }
        ref.current = completedItem.order;
        return false;
    };
    var requestCompleted = function (completedItem) {
        var active = true;
        if (active) {
            // eslint-disable-next-line no-param-reassign
            completedItem.completed = new Date();
            setCompletedRequests(__spreadArrays(completedRequests, [completedItem]));
        }
        return function () {
            active = false;
        };
    };
    var returnValue = {
        addQueueItem: addQueueItem,
        requestCompleted: requestCompleted,
        isRequestActive: isRequestActive,
        isLoading: isLoading,
        queueItems: queueItems,
        startNewSession: startNewSession,
        sessionId: sessionId,
        isLatestRequest: isLatestRequest,
        lastPageCompleted: lastPageCompleted,
        morePagesExists: morePagesExists,
        firstRequestOnNewSession: firstRequestOnNewSession,
        newerRequestCompleted: newerRequestCompleted,
        numCompletedRequest: completedRequests.length
    };
    return returnValue;
}
exports.default = useAjaxQueue;
