import { PureComponent } from 'react';
import { TicketsFilter, Ticket } from 'models/state/tickets';
import { ApiError, ApiCollection } from 'models/api';
import TicketsTable from '../TicketsTable/TicketsTable';

interface CcTicketsProps {
  loading: boolean;
  error?: ApiError;
  data?: ApiCollection<Ticket>;
  onSearch: (ticketFilter: TicketsFilter) => void;
}

interface CcTicketsState {
  page: number;
  pageSize: number;
}

export default class CcTickets extends PureComponent<
  CcTicketsProps,
  CcTicketsState
> {
  constructor(props: CcTicketsProps) {
    super(props);
    this.state = { page: 1, pageSize: 20 };
  }

  onChangePage = (newPage: number) => {
    this.setState({ page: newPage }, () => {
      const { page, pageSize } = this.state;
      this.props.onSearch({ page, pageSize });
    });
  };

  public render() {
    const { loading, error, data } = this.props;
    const totalPages =
      data !== undefined ? Math.ceil(data.totalHits / this.state.pageSize) : 0;

    return (
      <TicketsTable
        loading={loading}
        error={error}
        data={data}
        paginated
        currentPage={this.state.page}
        totalPages={totalPages}
        onChangePage={this.onChangePage}
      />
    );
  }
}
