import { PureComponent } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from 'store';
import { thunks as commentThunks } from 'store/tickets/ticket-comment';
import {
  thunks as commentsThunks,
  actionCreators as commentsActionCreators,
} from 'store/tickets/ticket-comments';
import { thunks as ticketThunks } from 'store/tickets/ticket-details';
import { actionCreators as ticketHistoryActions } from 'store/tickets/ticket-history-tags';
import {
  LoadingSpinner,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
} from 'shared/components';
import {
  TicketDetailedTicket,
  TicketDetailedComments,
  TicketDetailedNewComment,
  TicketHistoryTags,
} from 'modules/tickets/components';
import { connect } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import { TicketPostComment } from 'models/state/tickets';

import './ticketdetails.scss';

interface TicketDetailsOwnState {
  page: number;
  pageSize: number;
}

interface TicketDetailsRouteProps {
  id: string;
}

const mapStateToProps = (state: ApplicationState) => ({
  ticketDetailsState: state.tickets.ticketDetails,
  commentState: state.tickets.ticketComment,
  commentsState: state.tickets.ticketComments,
});

const mapDispatchToProps = (
  dispatch: Dispatch // used to be generic <TicketActions & TicketCommentsActions & TicketCommentActions & TicketHistoryActions>
) =>
  bindActionCreators(
    {
      fetchTicketDetailed: ticketThunks.requestTicket,
      fetchComments: commentsThunks.requestComments,
      createComment: commentThunks.addComment,
      clearComments: commentsActionCreators.clearComments,
      addToHistory: ticketHistoryActions.addTicket,
    },
    dispatch
  );

type TicketDetailsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<TicketDetailsRouteProps>;

class TicketDetails extends PureComponent<
  TicketDetailsProps,
  TicketDetailsOwnState
> {
  constructor(props: TicketDetailsProps) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    this.props.fetchTicketDetailed(this.props.match.params.id);
    this.props.fetchComments(this.props.match.params.id);
    this.props.addToHistory(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: TicketDetailsProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.clearComments();
      this.props.fetchTicketDetailed(this.props.match.params.id);
      this.props.fetchComments(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearComments();
  }

  getCommentPage = (newPage: number) => {
    this.setState({ page: newPage }, () => {
      const {
        match: {
          params: { id },
        },
      } = this.props;
      const { page, pageSize } = this.state;
      this.props.fetchComments(id, page, pageSize);
    });
  };

  createComment = (comment: TicketPostComment) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.props.createComment(id, comment, () => {
      this.props.clearComments();
      this.getCommentPage(1);
    });
  };

  render() {
    const {
      loading: ticketLoading,
      error: ticketError,
      ticket,
    } = this.props.ticketDetailsState;
    const {
      loading: commentsLoading,
      error: commentsError,
      comments,
    } = this.props.commentsState;
    const { saving: commentSaving, error: commentError } =
      this.props.commentState;

    const totalPages =
      comments !== undefined
        ? Math.ceil(comments.totalHits / this.state.pageSize)
        : 0;

    return (
      <>
        <Breadcrumbs>
          <BreadcrumbsItem href="/" title="Home" />
          <BreadcrumbsItem href="/tickets" title="Requests" />
          <BreadcrumbsItem
            title={`Ticket #${this.props.match.params.id}`}
            active
          />
        </Breadcrumbs>
        <Row className="mb-4">
          <Col md={12}>
            <Button smallBtn color="lightBlue" href="/tickets">
              Back to requests
            </Button>
          </Col>
        </Row>
        <Card className="ticket-details-container form">
          <Card.Body>
            <Row>
              <Col md={12}>
                <TicketHistoryTags activeId={ticket && `${ticket.id}`} />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                {/* SUB TITLE - REQUEST DETAILS */}
                <Card.Title as="h3">Request details</Card.Title>

                {/* TICKET DETAILS - STATUS AND ID */}
                {ticketLoading && (
                  <LoadingSpinner className="section-margin blockquote text-center" />
                )}
                {!ticketLoading && ticketError && (
                  <div className="section-title-spacing">
                    <p>
                      {ticketError.errorCode === 'UNAUTHORIZED'
                        ? 'You are not authorized to see this ticket'
                        : 'Error fetching ticket'}
                    </p>
                  </div>
                )}
                {!ticketLoading && !ticketError && ticket !== undefined && (
                  <>
                    <h4>{ticket.subject && ticket.subject}</h4>
                    <Card.Text>
                      <TicketDetailedTicket ticket={ticket} />
                    </Card.Text>
                  </>
                )}
              </Col>
              <Col md="6">
                {/* SUB TITLE - TICKET HISTORY */}
                <Card.Title as="h3">Ticket history (conversation)</Card.Title>

                {/* NEW COMMENT */}
                {!commentsError && comments && (
                  <TicketDetailedNewComment
                    saving={commentSaving}
                    error={commentError}
                    onSubmitComment={this.createComment}
                  />
                )}
                {/* COMMENT LIST */}
                <Row className="mt-4">
                  <Col>
                    <TicketDetailedComments
                      loading={commentsLoading}
                      error={commentsError}
                      data={comments}
                      currentPage={this.state.page}
                      totalPages={totalPages}
                      onChangePage={this.getCommentPage}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketDetails);
