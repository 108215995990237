import { FunctionComponent, useState } from 'react';
import { ApplicationState } from 'store';
import { useSelector } from 'react-redux';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  LoadingSpinner,
  Pagination,
} from 'shared/components';
import { Card } from 'react-bootstrap';
import { Defaults } from 'shared/constants/index';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Redirect } from 'react-router';
import { ROUTES } from 'shared/utils/routes';
import SoftwareDownloadsFilter from '../components/SoftwareDownloadsFilter';
import SoftwareDownloadRow from '../components/SoftwareDownloadRow';

const SoftwareDownloads: FunctionComponent = () => {
  const translationsState = useSelector(
    (state: ApplicationState) => state.i18n
  );
  const { loading, error, data } = useSelector(
    (state: ApplicationState) => state.softwareDownloads.softwareDownloads
  );
  const {
    loading: loadingSettings,
    error: errorSettings,
    settings,
  } = useSelector((state: ApplicationState) => state.settings);

  const [currentPage, setCurrentPage] = useState(1);
  const showLoading =
    translationsState.loading ||
    translationsState.translations === undefined ||
    loadingSettings ||
    settings === undefined;

  const showError =
    (!translationsState.loading && translationsState.error !== undefined) ||
    (!loadingSettings && errorSettings !== undefined);

  const showSoftwareDownloads =
    !showLoading &&
    !showError &&
    settings !== undefined &&
    settings.canAccessSoftwareDownloads;

  const hideSoftwareDownloads =
    !showLoading &&
    !showError &&
    settings !== undefined &&
    !settings.canAccessSoftwareDownloads;

  if (!showLoading && showError) {
    return (
      <div>An error occured while fetching translations and settings.</div>
    );
  }

  if (showSoftwareDownloads) {
    return (
      <>
        {!loading && error && (
          <div className="section-title-spacing">
            <p>Unable to fetch software downloads.</p>
          </div>
        )}
        <div>
          <Breadcrumbs>
            <BreadcrumbsItem
              href="/"
              title={translationsState.translations!.CommonHome}
            />
            <BreadcrumbsItem
              title={translationsState.translations!.SoftwareDownloadsHeading}
              active
            />
          </Breadcrumbs>
          <h1 className="u-mb-3">
            {translationsState.translations!.SoftwareDownloadsHeading}
          </h1>
          <SoftwareDownloadsFilter
            onChange={() => setCurrentPage(1)}
            defaultFilter={{
              page: currentPage,
              pageSize: Defaults.pageSize,
              operatingSystem: undefined,
              product: undefined,
              type: undefined,
              searchText: '',
            }}
            translations={translationsState.translations!}
          />
        </div>
        {loading && (
          <LoadingSpinner className="section-margin blockquote text-center" />
        )}

        {!loading && !error && data && (
          <>
            {data.items.length === 0 && (
              <Card>
                <Card.Body>
                  <Card.Text className="text-center">
                    No software downloads found
                  </Card.Text>
                </Card.Body>
              </Card>
            )}
            {data.items.length > 0 && (
              <table className="c-table c-table--general">
                <thead>
                  <tr>
                    <th className="u-w-1"> </th>
                    <th>Software</th>
                    <th className="u-d-none u-d-sm-table-cell">Type</th>
                    <th className="u-d-none u-d-sm-table-cell">
                      Operating system
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.items.map((softwareGroup) => (
                    <SoftwareDownloadRow
                      softwareGroup={softwareGroup}
                      key={`software-${softwareGroup.product}-${softwareGroup.operatingSystem}-${softwareGroup.type}`}
                    />
                  ))}
                </tbody>
              </table>
            )}
            {!!data.totalHits && (
              <Pagination
                className="u-mt-4"
                currentPage={currentPage}
                totalPages={Math.ceil(
                  (data?.totalHits || 0) / Defaults.pageSize
                )}
                onChangePage={(newPage) => setCurrentPage(newPage)}
              />
            )}
          </>
        )}
      </>
    );
  }

  if (hideSoftwareDownloads) {
    return <Redirect to={ROUTES.root} />;
  }

  return <LoadingSpinner className="section-margin blockquote text-center" />;
};

export default SoftwareDownloads;
