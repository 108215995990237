import { PureComponent } from 'react';
import { ApplicationState } from 'store';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { thunks, OpenTicketsActions } from 'store/tickets/open-tickets';
import { LoadingSpinner, ExpansionPanel } from 'shared/components';
import OpenTickets from '../OpenTickets/OpenTickets';

const mapStateToProps = (state: ApplicationState) => ({
  ticketState: state.tickets,
  settingsState: state.settings,
  translationsState: state.i18n,
});

const mapDispatchToProps = (dispatch: Dispatch<OpenTicketsActions>) =>
  bindActionCreators(
    {
      fetchTickets: thunks.requestTickets,
    },
    dispatch
  );

type MyActionsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class MyActionsPanel extends PureComponent<MyActionsProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {
    this.getSupportTickets();
  }

  public componentDidUpdate() {
    this.getSupportTickets();
  }

  private getSupportTickets() {
    const { settingsState, ticketState } = this.props;

    if (
      !settingsState.loading &&
      settingsState.error === undefined &&
      settingsState.settings !== undefined
    ) {
      const { featureFlags } = settingsState.settings;
      if (featureFlags.showSupportTickets) {
        if (
          !ticketState.openTickets.loading &&
          ticketState.openTickets.error === undefined &&
          ticketState.openTickets.items === undefined
        ) {
          this.props.fetchTickets();
        }
      }
    }
  }

  render() {
    const {
      translationsState: {
        loading: loadingTranslations,
        error: translationsError,
        translations,
      },
      settingsState: {
        loading: loadingSettings,
        error: settingsError,
        settings,
      },
    } = this.props;

    const hasTranslations =
      !loadingTranslations &&
      translationsError === undefined &&
      translations !== undefined;
    const hasSettings =
      !loadingSettings && settingsError === undefined && settings !== undefined;
    const showLoading = !hasTranslations || !hasSettings;

    if (showLoading) {
      return (
        <LoadingSpinner className="section-margin blockquote text-center" />
      );
    }
    const { featureFlags } = settings!;
    if (!featureFlags.showSupportTickets) return null;
    return (
      <ExpansionPanel
        title={translations!.MyActionsHeading}
        activeKey="0"
        columns={2}
      >
        <Row>
          <OpenTickets />
        </Row>
      </ExpansionPanel>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyActionsPanel);
