// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { TicketCommentState, TicketPostComment } from 'models/state/tickets';
import { ApiError } from 'models/api';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const TICKET_COMMENTS_ADD_REQUEST = 'TICKET_COMMENTS_ADD_REQUEST';
const TICKET_COMMENTS_ADD_SUCCESS = 'TICKET_COMMENTS_ADD_SUCCESS';
const TICKET_COMMENTS_ADD_FAILURE = 'TICKET_COMMENTS_ADD_FAILURE';

interface TicketCommentsAddRequestAction extends Action<string> {
  type: typeof TICKET_COMMENTS_ADD_REQUEST;
}

interface TicketCommentsAddSuccessAction extends Action<string> {
  type: typeof TICKET_COMMENTS_ADD_SUCCESS;
}

interface TicketCommentsAddFailureAction extends Action<string> {
  type: typeof TICKET_COMMENTS_ADD_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type TicketCommentActions =
  | TicketCommentsAddRequestAction
  | TicketCommentsAddSuccessAction
  | TicketCommentsAddFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  addRequest: () =>
    ({
      type: TICKET_COMMENTS_ADD_REQUEST,
    } as TicketCommentsAddRequestAction),
  addSuccess: () =>
    ({
      type: TICKET_COMMENTS_ADD_SUCCESS,
    } as TicketCommentsAddSuccessAction),
  addFailure: (error: ApiError) =>
    ({
      type: TICKET_COMMENTS_ADD_FAILURE,
      payload: error,
    } as TicketCommentsAddFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  addComment:
    (
      ticketId: string,
      comment: TicketPostComment,
      onSuccess: () => void
    ): AppThunkAction<TicketCommentActions> =>
    async (dispatch) => {
      dispatch(actionCreators.addRequest());
      api
        .post<TicketPostComment, boolean>(
          `api/v1/tickets/${ticketId}/comments`,
          comment
        )
        .then(
          (data) => {
            dispatch(actionCreators.addSuccess());
            onSuccess();
          },
          (error: ApiError) => {
            dispatch(actionCreators.addFailure(error));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: TicketCommentState = {
  saving: false,
  error: undefined,
};

export const reducer: Reducer<TicketCommentState> = (
  state: TicketCommentState = defaultState,
  incomingAction: Action | undefined = undefined
): TicketCommentState => {
  const action = incomingAction as TicketCommentActions;

  switch (action.type) {
    case TICKET_COMMENTS_ADD_REQUEST:
      return {
        ...state,
        saving: true,
        error: undefined,
      };

    case TICKET_COMMENTS_ADD_SUCCESS:
      return {
        ...state,
        saving: false,
        error: undefined,
      };

    case TICKET_COMMENTS_ADD_FAILURE:
      return {
        ...state,
        saving: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
