// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { TicketComment, TicketCommentsState } from 'models/state/tickets';
import { ApiCollection, ApiError } from 'models/api';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const TICKET_COMMENTS_FETCH_REQUEST = 'TICKET_COMMENTS_FETCH_REQUEST';
const TICKET_COMMENTS_FETCH_SUCCESS = 'TICKET_COMMENTS_FETCH_SUCCESS';
const TICKET_COMMENTS_FETCH_FAILURE = 'TICKET_COMMENTS_FETCH_FAILURE';
const TICKET_COMMENTS_CLEAR = 'TICKET_COMMENTS_CLEAR';

interface TicketCommentsFetchRequestAction extends Action<string> {
  type: typeof TICKET_COMMENTS_FETCH_REQUEST;
}

interface TicketCommentsFetchSuccessAction extends Action<string> {
  type: typeof TICKET_COMMENTS_FETCH_SUCCESS;
  payload: ApiCollection<TicketComment>;
}

interface TicketCommentsFetchFailureAction extends Action<string> {
  type: typeof TICKET_COMMENTS_FETCH_FAILURE;
  payload: ApiError;
}

interface TicketCommentsClearAction extends Action<string> {
  type: typeof TICKET_COMMENTS_CLEAR;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type TicketCommentsActions =
  | TicketCommentsFetchRequestAction
  | TicketCommentsFetchSuccessAction
  | TicketCommentsFetchFailureAction
  | TicketCommentsClearAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  fetchRequest: () =>
    ({
      type: TICKET_COMMENTS_FETCH_REQUEST,
    } as TicketCommentsFetchRequestAction),
  fetchSuccess: (data: ApiCollection<TicketComment>) =>
    ({
      type: TICKET_COMMENTS_FETCH_SUCCESS,
      payload: data,
    } as TicketCommentsFetchSuccessAction),
  fetchFailure: (error: ApiError) =>
    ({
      type: TICKET_COMMENTS_FETCH_FAILURE,
      payload: error,
    } as TicketCommentsFetchFailureAction),
  clearComments: () =>
    ({
      type: TICKET_COMMENTS_CLEAR,
    } as TicketCommentsClearAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestComments:
    (
      ticketId: string,
      page: number = 1,
      pageSize: number = 10
    ): AppThunkAction<TicketCommentsActions> =>
    async (dispatch) => {
      dispatch(actionCreators.fetchRequest());
      api
        .get<ApiCollection<TicketComment>>(
          `api/v1/tickets/${ticketId}/comments?page=${page}&pageSize=${pageSize}`
        )
        .then(
          (data) => {
            dispatch(actionCreators.fetchSuccess(data));
          },
          (error: ApiError) => {
            dispatch(actionCreators.fetchFailure(error));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: TicketCommentsState = {
  comments: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<TicketCommentsState> = (
  state: TicketCommentsState = defaultState,
  incomingAction: Action | undefined = undefined
): TicketCommentsState => {
  const action = incomingAction as TicketCommentsActions;

  switch (action.type) {
    case TICKET_COMMENTS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TICKET_COMMENTS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        comments: {
          ...state.comments,
          items: state.comments
            ? state.comments.items.concat(action.payload.items)
            : action.payload.items,
          totalHits: action.payload.totalHits,
        },
        error: undefined,
      };

    case TICKET_COMMENTS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        comments: undefined,
        error: action.payload,
      };
    case TICKET_COMMENTS_CLEAR:
      return defaultState;

    default:
      return state;
  }
};
