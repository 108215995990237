import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingSpinner } from 'shared/components';
import { truncateText } from 'shared/utils';
import { BlogItem } from 'models/state/news';

import styles from '../NewsList.module.scss';

const SkillDevelopmentComponent: FunctionComponent = () => {
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );
  const { loading, error, items } = useSelector(
    (state: ApplicationState) => state.news.skillDevelopment
  );

  const renderItems = (blogItems: BlogItem[]) => (
    <div className="section-margin">
      <h2>{translations?.SkillDevelopmentHeading}</h2>
      <p style={{ marginTop: 10 }}>
        {translations!.SkillDevelopmentSubDescriptionText}
      </p>
      <Row className="section-title-spacing">
        {blogItems.map((item, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col md={3} key={index}>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="tile-brick"
            >
              <div className={styles.newsTile}>
                <div
                  className={`${styles.imageContainer}`}
                  style={{ backgroundImage: `url(${item.image}?w=600)` }}
                />
                <div className={styles.contentContainer}>
                  <h4 title={item.title}>{truncateText(item.title, 60)}</h4>
                  <p
                    className={`body2 ${styles.description}`}
                    title={item.description}
                  >
                    {truncateText(item.description, 75)}
                  </p>
                </div>
              </div>
            </a>
          </Col>
        ))}
      </Row>
      <div className="row">
        <div className={`col-md-12 text-right ${styles.allNewsLink}`}>
          <a
            href=" https://www.crayon.com/cp/resources/partner-skill-development-program/"
            target="_blank"
            rel="noreferrer"
          >
            {translations?.SkillDevelopmentViewAllLinkHeading}
          </a>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {loading && <LoadingSpinner className="section-margin" />}
      {!loading && !error && !!items?.length && renderItems(items)}
    </>
  );
};

export default SkillDevelopmentComponent;
