/* eslint-disable @typescript-eslint/no-throw-literal */
import axios, { AxiosError } from 'axios';
import { ApiError } from 'models/api';

const getApiError = (error: AxiosError<ApiError>): ApiError => {
  const { response } = error;
  if (response !== undefined)
    return {
      message: response.data.message,
      errorCode: response.data.errorCode,
    };

  return {
    message: 'An unknown error occured',
    errorCode: 'UnknownError',
  };
};

export const api = {
  get: async <T>(url: string) =>
    axios.get<T>(url).then(
      (res) => res.data,
      (error: AxiosError<ApiError>) => {
        throw getApiError(error);
      }
    ),
  put: async <TIn, TOut>(url: string, data: TIn) =>
    axios.put<TOut>(url, data).then(
      (res) => res.data,
      (error: AxiosError<ApiError>) => {
        throw getApiError(error);
      }
    ),
  post: async <TIn, TOut>(url: string, data: TIn) =>
    axios.post<TOut>(url, data).then(
      (res) => res.data,
      (error: AxiosError<ApiError>) => {
        throw getApiError(error);
      }
    ),
  delete: async <T>(url: string) =>
    axios.delete<T>(url).then(
      (res) => res.data,
      (error: AxiosError<ApiError>) => {
        throw getApiError(error);
      }
    ),
};
