import { PureComponent } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from 'store';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';
import {
  thunks as createTicketThunks,
  TicketCreateActions,
} from 'store/tickets/tickets-create';
import { TicketPost, TicketCategory } from 'models/state/tickets';
import {
  TicketNewSteps,
  TicketNewStepOne,
  TicketNewStepTwo,
  TicketNewStepThree,
} from 'modules/tickets/components';
import { Breadcrumbs, BreadcrumbsItem } from 'shared/components';

const mapStateToProps = (state: ApplicationState) => ({
  translationsState: state.i18n,
  ticketCreateState: state.tickets.ticketCreate,
});

const mapDispatchToProps = (dispatch: Dispatch<TicketCreateActions>) =>
  bindActionCreators(
    {
      createTicket: createTicketThunks.createTicket,
    },
    dispatch
  );

interface TicketDetailsRouteProps {
  id: string;
}

type TicketNewProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<TicketDetailsRouteProps>;

interface TicketNewState {
  category?: TicketCategory;
  step: string;
}

class TicketNew extends PureComponent<TicketNewProps, TicketNewState> {
  constructor(props: TicketNewProps) {
    super(props);

    let step = 'Step1';
    if (this.props.match.params.id) {
      step = 'Step3';
    }
    this.state = { category: undefined, step };
  }

  onSelectCategory = (category: TicketCategory, step: string = 'Step2') => {
    this.setState({ category, step });
  };

  onSubmitTicket = (ticket: TicketPost) => {
    this.props.createTicket(ticket, (t) => {
      this.props.history.push(`/tickets/${t.id}/thank-you/`);
      this.setState({ step: 'Step3' });
    });
  };

  goToStep2 = (category: TicketCategory) => {
    this.onSelectCategory(category, 'Step2');
  };

  goToStep1 = () => {
    this.setState({ category: undefined, step: 'Step1' });
  };

  render() {
    const { translations } = this.props.translationsState;

    if (!translations) {
      return null;
    }

    return (
      <>
        <Breadcrumbs>
          <BreadcrumbsItem href="/" title={translations.CommonHome} />
          <BreadcrumbsItem
            href="/tickets"
            title={translations.TicketsRequests}
          />
          <BreadcrumbsItem title="New request" active />
        </Breadcrumbs>

        <h1 className="section-title-spacing component-spacing">
          Create a ticket
        </h1>
        <TicketNewSteps
          onSelect={(step) => this.setState({ step })}
          activeStep={this.state.step}
        />
        {this.state.step === 'Step1' && (
          <TicketNewStepOne onSelectCategory={this.goToStep2} />
        )}
        {this.state.step === 'Step2' && this.state.category && (
          <TicketNewStepTwo
            ticketCreateState={this.props.ticketCreateState}
            category={this.state.category}
            onSubmitTicket={this.onSubmitTicket}
            saving={this.props.ticketCreateState.loading}
            goToStepOne={this.goToStep1}
          />
        )}
        {this.state.step === 'Step3' && this.props.match.params.id && (
          <TicketNewStepThree
            ticketId={this.props.match.params.id}
            goToStepOne={this.goToStep1}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketNew);
