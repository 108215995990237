"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultRenderCheckboxOption = exports.defaultRenderOption = void 0;
var React = __importStar(require("react"));
function defaultRenderOption(option, index, focusedIndex, handleMouseOverOption, attr, renderOptionContent, getOptionText) {
    var comboBoxObject = option;
    return (React.createElement("button", __assign({ key: "buttonIndex_" + (comboBoxObject.internalId || (comboBoxObject === null || comboBoxObject === void 0 ? void 0 : comboBoxObject.id)), onFocus: function (event) {
            handleMouseOverOption(event);
        }, onMouseOver: function (event) {
            handleMouseOverOption(event);
        }, className: "c-dropdown-menu__item", type: "button" }, attr, { "data-option-index": index, "data-focus": index === focusedIndex ? 'true' : null }), option.initialObj && renderOptionContent
        ? renderOptionContent(option.initialObj, getOptionText(option.initialObj))
        : comboBoxObject.label || comboBoxObject.name));
}
exports.defaultRenderOption = defaultRenderOption;
function defaultRenderCheckboxOption(option, index, focusedIndex, handleMouseOverOption, attr, renderOptionContent, getOptionText) {
    var comboBoxObject = option;
    return (React.createElement("div", __assign({ className: " c-dropdown-menu__checkbox", key: "buttonIndex_" + index, onFocus: function (event) {
            handleMouseOverOption(event);
        }, onMouseOver: function (event) {
            handleMouseOverOption(event);
        }, onClick: function (e) {
            e.preventDefault(); // Prevent popup from closing
        }, role: "none", onKeyDown: function () { } }, attr, { "data-option-index": index, "data-focus": index === focusedIndex ? 'true' : null }),
        React.createElement("div", { className: "c-checkbox" },
            React.createElement("input", { type: "checkbox", value: String(comboBoxObject.id), id: "checkbox-1", readOnly: true, "aria-checked": comboBoxObject.selected, checked: comboBoxObject.selected }),
            React.createElement("label", { htmlFor: "checkbox-1" }, renderOptionContent
                ? renderOptionContent(option.initialObj, getOptionText(option.initialObj))
                : comboBoxObject.label || comboBoxObject.name))));
}
exports.defaultRenderCheckboxOption = defaultRenderCheckboxOption;
exports.default = defaultRenderOption;
