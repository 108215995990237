import { FunctionComponent, CSSProperties, ChangeEvent } from 'react';
import { FieldError } from 'react-hook-form/dist/index.ie11';

interface FormGroupTextInputProps {
  id: string;
  name: string;
  required: boolean;
  labelText: string;
  className?: string;
  placeholder: string;
  error?: FieldError;
  defaultValue?: string | number | string[] | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
}

const FormGroupTextInput: FunctionComponent<FormGroupTextInputProps> = ({
  id,
  name,
  required,
  labelText,
  className,
  placeholder,
  error,
  defaultValue,
  onChange,
  style,
}) => (
  <div
    className={`c-form-group${error ? ' is-invalid' : ' is-valid'}`}
    style={style}
  >
    <label htmlFor={id} className="c-form-group__label">
      {labelText} {required && <span className="is-required">*</span>}
    </label>

    <span className="c-form-group__invalid u-ml-1">{error?.message}</span>

    <input
      type="text"
      name={name}
      id={id}
      className={`c-form-control${className || ''}`}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      required={required}
    />
  </div>
);

export default FormGroupTextInput;
