"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
// Give up on IE 11 support for this feature
function stripDiacritics(string) {
    return typeof string.normalize !== 'undefined'
        ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        : string;
}
exports.default = stripDiacritics;
