import { FunctionComponent, MouseEvent } from 'react';
import { Pagination } from 'react-bootstrap';

import './pagination.scss';

interface PaginationComponentProps {
  currentPage: number;
  totalPages: number;
  showFirstLast?: boolean;
  className?: string;
  onChangePage: (selectedPage: number) => void;
}

/* PAGINATION...
 * So according to this issue 'the pagination components are "presentational" meaning they only wrap
 * DOM nodes and add some styles, it's up to you in your app to add pagination logic to them.'
 * https://github.com/react-bootstrap/react-bootstrap/issues/3281
 *
 * At the bottom of this issue there is at least some code explaning how you can attach onClick handler
 * to the pagination component.
 *
 * So if this isn't good enough, maybe look at some of the following
 * https://www.digitalocean.com/community/tutorials/how-to-build-custom-pagination-with-react
 * https://medium.com/@marcos.deaguiar/custom-react-hook-for-pagination-81d55d5b1e75
 * https://dev.to/potouridisio/the-only-pagination-you-ll-ever-need-1-2-3-4-5-4il5
 * https://www.npmjs.com/package/react-paginate
 * https://www.youtube.com/watch?v=IYCa1F-OWmk | Traversy Media - Simple Frontend Pagination | React
 * https://www.youtube.com/watch?v=6EF_8LbNy0c | Coding Shiksha - React Frontend Pagination Using Bootstrap Axios and React ...
 */

const PaginationComponent: FunctionComponent<PaginationComponentProps> = ({
  currentPage = 1,
  totalPages = 1,
  showFirstLast = true,
  onChangePage,
  className,
}: PaginationComponentProps) => {
  const getMinPageIndex = (
    currentPageIndex: number,
    numPageItems: number,
    numPages: number
  ) =>
    Math.min(
      Math.max(
        1,
        parseInt((currentPageIndex - numPageItems / 2).toString(), 10)
      ),
      Math.max(numPages - numPageItems + 1, 1)
    );

  const getMaxPageIndex = (
    currentPageIndex: number,
    numPageItems: number,
    numPages: number,
    minValue: number
  ) =>
    Math.max(
      Math.min(
        numPages,
        parseInt((currentPageIndex + numPageItems / 2).toString(), 10)
      ),
      Math.min(numPageItems + 1 - minValue, numPages)
    );

  const pageChanged = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const element = event.currentTarget as HTMLElement;
    const clicked = element.getAttribute('page');
    const current = element.getAttribute('current');
    if (clicked !== null && current !== null) {
      if (clicked === current) return;

      // trigger som onChangePage passed in as props with page number
      onChangePage(+clicked);
    }
  };

  const renderPagination = (page: number, lastPage: number) => {
    const minPage = Math.round(getMinPageIndex(page, 13, lastPage));
    const maxPage = Math.round(getMaxPageIndex(page, 13, lastPage, minPage));
    const disableBack = page === minPage;
    const disableForward = page === maxPage;
    const items = [];

    for (let number = minPage; number <= maxPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === page}
          page={number}
          current={page}
          onClick={pageChanged}
        >
          {number}
        </Pagination.Item>
      );
    }

    return (
      <div className={className}>
        <Pagination>
          {showFirstLast && (
            <>
              <Pagination.First
                page={minPage}
                current={page}
                onClick={pageChanged}
                disabled={disableBack}
              >
                First
              </Pagination.First>
              <Pagination.Prev
                page={page - 1}
                current={page}
                onClick={pageChanged}
                disabled={disableBack}
              >
                Previous
              </Pagination.Prev>
            </>
          )}
          {items}
          {showFirstLast && (
            <>
              <Pagination.Next
                page={page + 1}
                current={page}
                onClick={pageChanged}
                disabled={disableForward}
              >
                Next
              </Pagination.Next>
              <Pagination.Last
                page={maxPage}
                current={page}
                onClick={pageChanged}
                disabled={disableForward}
              >
                Last
              </Pagination.Last>
            </>
          )}
        </Pagination>
      </div>
    );
  };

  return renderPagination(currentPage, totalPages);
};

export default PaginationComponent;
