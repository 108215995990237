import { connect } from 'react-redux';
import { User, UserManager } from 'oidc-client';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { CallbackComponent } from 'redux-oidc';
import { Location } from 'history';

import { ROUTES } from 'shared/utils/routes';

interface CallbackPageProps {
  dispatch: Dispatch;
  location: Location;
  userManager: UserManager;
}

const Callback = (props: CallbackPageProps) => {
  const successCallback = (user: User) => {
    // get the user's previous location, passed during signinRedirect()
    if (user.state) {
      const redirectPath = user.state.path as string;
      props.dispatch(push(redirectPath));
    } else {
      props.dispatch(push(ROUTES.root));
    }
  };

  const errorCallback = (error: Error) => {
    // NOTE: Maybe pass along the error? I don't know what kind of information it contains though...
    props.dispatch(push(ROUTES.callbackError));
  };

  // https://github.com/maxmantz/redux-oidc/blob/master/docs/API.md#callbackcomponent--signoutcallbackcomponent
  return (
    <CallbackComponent
      successCallback={successCallback}
      errorCallback={errorCallback}
      userManager={props.userManager}
    >
      <div>Redirecting...</div>
    </CallbackComponent>
  );
};

export default connect()(Callback);
