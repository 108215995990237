import { Action, Reducer } from 'redux';
import { TranslationsState } from 'models/state/translations';
// eslint-disable-next-line import/no-cycle
import {
  TranslationsActions,
  TRANSLATIONS_FETCH_REQUEST,
  TRANSLATIONS_FETCH_SUCCESS,
  TRANSLATIONS_FETCH_FAILURE,
} from './actions';

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: TranslationsState = {
  translations: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<TranslationsState> = (
  state: TranslationsState = defaultState,
  incomingAction: Action | undefined = undefined
): TranslationsState => {
  const action = incomingAction as TranslationsActions;

  switch (action.type) {
    case TRANSLATIONS_FETCH_REQUEST:
      return { ...state, loading: true };

    case TRANSLATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        translations: action.payload,
        error: undefined,
      };

    case TRANSLATIONS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        translations: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
