import { FunctionComponent, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons/faFileInvoiceDollar';
import { faUserCog } from '@fortawesome/free-solid-svg-icons/faUserCog';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons/faTabletAlt';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { TicketCard } from 'modules/tickets/components';
import { TicketCategory } from 'models/state/tickets';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from 'store';
import { thunks as supportFormsThunks } from 'store/tickets/support-forms';
import { LoadingSpinner } from 'shared/components';
import { Card } from 'react-bootstrap';
import ticketCardStyles from '../TicketCard/TicketCard.module.scss';

interface TicketNewStepOneProps {
  onSelectCategory: (category: TicketCategory) => void;
}

const TicketNewStepone: FunctionComponent<TicketNewStepOneProps> = ({
  onSelectCategory,
}: TicketNewStepOneProps) => {
  const dispatch = useDispatch();
  const { loading, error, forms } = useSelector(
    (state: ApplicationState) => state.tickets.supportForms
  );

  useEffect(() => {
    if (!loading && !error && !forms) {
      dispatch(supportFormsThunks.requestSupportForms());
    }
  }, [loading, error, forms, dispatch]);

  const getIcon = (code: string): IconDefinition => {
    switch (code) {
      case 'license-and-billing':
        return faFileInvoiceDollar;

      case 'technical-administration':
        return faUserCog;

      case 'cloud-iq':
        return faCloud;

      case 'service-iq':
        return faChartBar;

      case 'core-iq':
        return faTabletAlt;

      case 'cscs':
        return faChartPie;

      default:
        return faComments;
    }
  };

  return (
    <>
      {loading && (
        <LoadingSpinner className="section-margin blockquote text-center" />
      )}
      {!loading && error && (
        <div className="section-title-spacing">
          <p>Error fetching support forms</p>
        </div>
      )}
      {!loading && !error && forms && (
        <>
          {forms.length === 0 && (
            <Card>
              <Card.Body>
                <Card.Text className="text-center">
                  Support forms currently not available. Please contact your
                  local Crayon contact for assistance.
                </Card.Text>
              </Card.Body>
            </Card>
          )}

          {forms.length > 0 && (
            <div className={ticketCardStyles.ticketCardList}>
              {forms.map((form) => (
                <TicketCard
                  key={form.id}
                  onClick={() =>
                    onSelectCategory({
                      code: form.code,
                      name: form.name,
                      emailCategory: form.emailCategory,
                      ticketFormId: form.zendeskFormId,
                    })
                  }
                  icon={<FontAwesomeIcon icon={getIcon(form.code)} />}
                  title={form.name}
                  description={form.description}
                  styleTileBrick={true}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TicketNewStepone;
