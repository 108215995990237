import { User } from 'oidc-client';

export const isControlPanelUser = (user: User) => !!user.profile.consumerId;

export const isUnauthorizedUser = (user: User) => {
  const { cloudIq, tenantId } = user.profile;
  return (tenantId ?? 0) <= 0 || (cloudIq ?? false) === false;
};

export const isSupportUser = (user: User) => {
  const { role } = user.profile;
  return role === 'SupportAgent' || role === 'SupportUser';
}
