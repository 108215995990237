import { FunctionComponent, useEffect, useState } from 'react';
import { LoadingSpinner } from 'shared/components';
import { api } from 'shared/utils/api';

const NotAuthorizedControlPanel: FunctionComponent = () => {
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getControlPanelUrl = async () => {
      try {
        const result = await api.get<string>(`/api/v1/settings/application-connections/control-panel`);
  
        setUrl(result);
      } catch (error) {
        setUrl('');
      }
    };

    getControlPanelUrl();
  }, []);

  if (url === undefined) {
    return (
      <LoadingSpinner className="section-margin blockquote text-center" />
    );
  }
  
  return (
    <div className="text-center">
      <p>You don't have permissions to access this page.</p>
      {url !== '' && (
        <p>Maybe you meant to go to <a href={url}>Control Panel</a>?</p>
      )}
    </div>
  );
};

export default NotAuthorizedControlPanel;