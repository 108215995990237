import { PureComponent } from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import { ApplicationState } from 'store';
import {
  actionCreators as ticketActions,
  TicketHistoryActions,
} from 'store/tickets/ticket-history-tags';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import styles from './TicketHistoryTags.module.scss';

interface TicketDetailsOwnProps {
  activeId?: string;
}

const mapStateToProps = (state: ApplicationState) => ({
  ticketHistory: state.tickets.ticketHistory,
});

const mapDispatchToProps = (dispatch: Dispatch<TicketHistoryActions>) =>
  bindActionCreators(
    {
      removeTicket: ticketActions.removeTicket,
    },
    dispatch
  );

type TicketHistoryTagsProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  TicketDetailsOwnProps &
  RouteComponentProps;

class TicketHistoryTags extends PureComponent<TicketHistoryTagsProps> {
  removeFromHistory = (ticketId: string) => {
    this.props.removeTicket(ticketId);
    if (ticketId === this.props.activeId) {
      this.props.history.push('/tickets/');
    }
  };

  render() {
    const { ticketHistory, activeId } = this.props;
    return (
      <div className={styles['tags-wrapper']}>
        {ticketHistory.tickets.map((ticketId) => (
          <div
            className={`${styles.tag} ${
              ticketId === activeId ? styles.active : ''
            }`}
            key={ticketId}
          >
            {ticketId === activeId ? (
              <span>{`Ticket #${ticketId}`}</span>
            ) : (
              <Link className={styles['tag-link']} to={`/tickets/${ticketId}/`}>
                {`Ticket #${ticketId}`}
              </Link>
            )}
            <FontAwesomeIcon
              icon={faTimes}
              fixedWidth
              className={styles['tags-remove-icon']}
              onClick={() => this.removeFromHistory(ticketId)}
            />
          </div>
        ))}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TicketHistoryTags));
