"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMultiple = exports.generateOptionId = exports.getSelectedValueFromInput = void 0;
function getSelectedValueFromInput(options, getOptionValue, value) {
    if (typeof value === 'string') {
        if (value === '') {
            return '';
        }
        return [getValueFromString(options, getOptionValue, value, undefined)];
    }
    if (value && typeof value === 'number') {
        return [getValueFromString(options, getOptionValue, value, value)];
    }
    var argValue = value;
    if (argValue === null || argValue === void 0 ? void 0 : argValue.id) {
        return [getValueFromArgValue(argValue)];
    }
    var list = value;
    if (list && list.length) {
        return list.map(function (v) {
            return getValueFromString(options, getOptionValue, v, undefined);
        });
    }
    if (value && typeof value === 'object' && list.length === undefined) {
        return [
            getValueFromString(options, getOptionValue, getOptionValue(value), value)
        ];
    }
    return value;
}
exports.getSelectedValueFromInput = getSelectedValueFromInput;
function getValueFromArgValue(value) {
    var value2 = __assign(__assign({}, value), { internalId: exports.generateOptionId(), selected: true, newValue: null });
    return value2;
}
function getValueFromString(options, getOptionValue, value, valueUntyped) {
    if (options && options.length && value) {
        for (var i = 0; i < options.length; ++i) {
            if (getOptionValue(options[i]) === value) {
                return options[i];
            }
        }
    }
    if (valueUntyped) {
        return valueUntyped;
    }
    var value2 = {
        id: value,
        internalId: exports.generateOptionId(),
        name: value,
        label: value,
        selected: true,
        newValue: null,
        initialObj: valueUntyped
    };
    return value2;
}
exports.generateOptionId = function () {
    return (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10);
};
exports.isMultiple = function (comboBoxType) {
    return (comboBoxType === 'multiple' ||
        comboBoxType === 'multiple-freetext' ||
        comboBoxType === 'multiple-searchable');
};
