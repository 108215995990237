import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';
import { Button, LoadingSpinner, MediaObject, Plus } from 'shared/components';
import { ApplicationState } from 'store';
import { Ticket } from 'models/state/tickets';
import { TicketStatusLabel } from 'modules/tickets/components';
import styles from './OpenTickets.module.scss';

const OpenTickets: FunctionComponent = () => {
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );
  const {
    loading,
    error,
    items: tickets,
  } = useSelector((state: ApplicationState) => state.tickets.openTickets);
  const firstColumn = tickets ? tickets.slice(0, 4) : [];
  const secondColumn = tickets ? tickets.slice(4) : [];
  return (
    <>
      <Col md={6}>
        <div className={`${styles.flex}`}>
          <h3>{translations!.MyActionsOpenTicketsHeading}</h3>
        </div>
        {loading && <LoadingSpinner className="section-title-spacing" />}
        {!loading && error && (
          <div className="section-title-spacing">
            <p>Error fetching tickets</p>
          </div>
        )}
        {!loading && !error && tickets !== undefined && (
          <>
            {tickets.length === 0 ? (
              <div className="section-title-spacing">
                <p>No tickets found.</p>
              </div>
            ) : (
              <ul
                className={`${styles.settingsList} ${styles.settingsListLeft}`}
              >
                {firstColumn.map((ticket: Ticket) => (
                  <li
                    key={ticket.id}
                    hidden={false}
                    className={styles.settingsListItem}
                  >
                    <MediaObject
                      url={`/tickets/${ticket.id}`}
                      title={ticket.subject}
                    >
                      <div>
                        <TicketStatusLabel
                          ticketStatus={ticket.status}
                          noText
                        />
                      </div>
                    </MediaObject>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
      </Col>
      <Col md={6}>
        <div className={`${styles.rightColumnWrapper}`}>
          <div>
            <div
              className={`${styles.flex} ${styles.settingsListRight} ${styles.flexEnd}`}
            >
              <Button href="/tickets/" smallBtn>
                View all
              </Button>
            </div>
            {!loading && !error && tickets !== undefined && (
              <>
                {tickets.length === 0 ? (
                  <div className="section-title-spacing">
                    <p>No tickets found.</p>
                  </div>
                ) : (
                  <ul
                    className={`${styles.settingsList} ${styles.settingsListRight}`}
                  >
                    {secondColumn.map((ticket: Ticket) => (
                      <li
                        key={ticket.id}
                        hidden={false}
                        className={styles.settingsListItem}
                      >
                        <MediaObject
                          url={`/tickets/${ticket.id}`}
                          title={ticket.subject}
                        >
                          <div>
                            <TicketStatusLabel
                              ticketStatus={ticket.status}
                              noText
                            />
                          </div>
                        </MediaObject>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
          <div className={styles['buttons-wrapper']}>
            <Button href="/tickets/new" smallBtn>
              <Plus /> {translations!.MyActionsOpenTicketsAddNewButton}
            </Button>
          </div>
        </div>
      </Col>
    </>
  );
};

export default OpenTickets;
