import { ApiCollection, ApiError } from 'models/api';

export enum TicketStatus {
  New,
  Open,
  Closed,
  Pending,
  Solved,
  Hold,
  Deleted,
}

export interface Ticket {
  id: number;
  subject: string;
  createdAt: string; // DateTimeOffset
  updatedAt: string; // DateTimeOffset
  status: TicketStatus;
  requester: string;

  customFields: CustomField[];
}
  
export interface CustomField {
  ticketFieldId: number;
  position: number;
  label: string;
  text: string;
  value?: string | string[];
}

export interface TicketPost {
  categoryCode: string;
  emailCategory: string;
  ticketFormId: number;
  customerAccountId: number;
  haveAlreadyTried?: string;
  primaryContact?: TicketCreateContact;
  secondaryContact?: TicketCreateContact;
  subject: string;
  comment: TicketPostComment;
  cc?: string;
  customFields?: CustomFieldPost[];
}

export interface CustomFieldPost {
  id: number;
  value?: string;
  values?: string[];
}

export interface TicketForm {
  id: number;
  name: string;
  position: string;
  ticketFields: TicketField[];
}

export interface TicketField {
  id: number;
  position: number;
  type: TicketFieldType;
  title: string;
  description: string;
  editable: boolean;
  required: boolean;
  regexpForValidation?: string;
  tag?: string;
  customFieldOptions?: CustomFieldOption[];
}

type TicketFieldType =
  | 'description'
  | 'subject'
  | 'text'
  | 'textarea'
  | 'tagger'
  | 'regexp'
  | 'multiselect'
  | 'checkbox'
  | 'date'
  | 'integer'
  | 'decimal';

export interface CustomFieldOption {
  id: number;
  name: string;
  value: string;
  default: boolean;
}

export interface TicketPostComment {
  body: string;
  uploads?: string[];
}

export interface TicketCategory {
  code: string;
  name: string;
  emailCategory: string;
  ticketFormId: number;
}

export interface TicketCreateContact {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

export interface TicketCommentUser {
  id: number;
  name: string;
  email: string;
}

export interface Thumbnail {
  id: number;
  fileName: string;
  contentUrl: string;
  contentType: string;
  size: number;
}

export interface Attachment extends Thumbnail {
  thumbnails: Thumbnail[];
}

export interface TicketComment {
  id: number;
  type: string;
  body: string;
  htmlBody: string;
  plainBody: string;
  user?: TicketCommentUser;
  attachments: Attachment[];
  createdAt: string;
}

export interface TicketsFilter {
  page?: number;
  pageSize?: number;
  searchText?: string;
  status?: TicketStatus[];
}

export interface TicketsState {
  openTickets: OpenTicketsState;
  myTickets: MyTicketsState;
  ccTickets: CcTicketsState;
  companyTickets: CompanyTicketsState;
  ticketDetails: TicketDetailsState;
  ticketComment: TicketCommentState;
  ticketComments: TicketCommentsState;
  ticketCreate: TicketCreateState;
  ticketHistory: TicketHistoryState;
  ticketForm: TicketFormState;
  supportForms: SupportFormsState;
}

export interface OpenTicketsState {
  items?: Ticket[];
  loading: boolean;
  error?: ApiError;
}

export interface MyTicketsState {
  data?: ApiCollection<Ticket>;
  loading: boolean;
  error?: ApiError;
}

export interface CcTicketsState {
  data?: ApiCollection<Ticket>;
  loading: boolean;
  error?: ApiError;
}

export interface CompanyTicketsState {
  data?: ApiCollection<Ticket>;
  loading: boolean;
  error?: ApiError;
}

export interface TicketDetailsState {
  ticket?: Ticket;
  loading: boolean;
  error?: ApiError;
}
export interface TicketCommentState {
  saving: boolean;
  error?: ApiError;
}

export interface TicketCommentsState {
  comments?: ApiCollection<TicketComment>;
  loading: boolean;
  error?: ApiError;
}

export interface TicketCreateState {
  ticket?: Ticket;
  loading: boolean;
  error?: ApiError;
}

export interface TicketHistoryState {
  tickets: string[];
}

export interface TicketFormState {
  ticketForm?: TicketForm;
  loading: boolean;
  error?: ApiError;
}

export interface SupportForm {
  id: number;
  code: string;
  name: string;
  description: string;
  emailCategory: string;
  zendeskFormId: number;
}

export interface SupportFormsState {
  forms?: SupportForm[];
  loading: boolean;
  error?: ApiError;
}
