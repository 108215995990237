const regExpTestEmail = (value: string): boolean =>
  !!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);

export const validateEmail = (value: string): boolean =>
  regExpTestEmail(value || '');

export const validateEmailIf = (value: string, condition: boolean): boolean =>
  condition || validateEmail(value);

export const validateEmailOptional = (value: string): boolean =>
  value === '' || regExpTestEmail(value);

export function validateEmailMultiple(
  value: string,
  isOptional: boolean = false
): boolean {
  if (value !== '') {
    const result = value.split(';');
    for (let i = 0; i < result.length; i++) {
      if (result[i] !== '') {
        if (!regExpTestEmail(result[i])) {
          return false;
        }
      } else if (!isOptional && i === result.length - 1) {
        // If not optional and it's not the last item after separator
        return false;
      }
    }
  } else if (!isOptional) {
    return false;
  }

  return true;
}

export const validateEmailMultipleOptional = (value: string): boolean =>
  validateEmailMultiple(value, true);
