import { FunctionComponent } from 'react';
import {
  Controller,
  Control,
  DeepMap,
  FieldError,
} from 'react-hook-form/dist/index.ie11';
import { TicketField as TicketFieldType } from 'models/state/tickets';
import {
  FormGroupTextInput,
  FormGroupTextarea,
  FormGroupSelect,
} from 'shared/components';
import { keyOf } from 'shared/utils';
import { ComboBoxObject } from '@crayoncupl/styleguide-react/lib/ComboBox/models/ComboBoxModels';
import { multiSelectSeparator } from './utils';
import { TicketFormData } from './TicketForm';

interface CustomFieldProps {
  fieldData: TicketFieldType;
  control: Control<TicketFormData>;
  getValues: (key: keyof TicketFormData) => string;
  errors: DeepMap<TicketFormData, FieldError>;
  index: number;
}

const TicketField: FunctionComponent<CustomFieldProps> = ({
  fieldData,
  control,
  getValues,
  errors,
  index,
}) => {
  const {
    type,
    id,
    title,
    description,
    required,
    customFieldOptions,
    regexpForValidation,
  } = fieldData;

  let keyName = keyOf(errors, 'customFieldKey0');
  if (index === 0) keyName = keyOf(errors, 'customFieldKey0');
  if (index === 1) keyName = keyOf(errors, 'customFieldKey1');
  if (index === 2) keyName = keyOf(errors, 'customFieldKey2');
  if (index === 3) keyName = keyOf(errors, 'customFieldKey3');
  if (index === 4) keyName = keyOf(errors, 'customFieldKey4');
  if (index === 5) keyName = keyOf(errors, 'customFieldKey5');
  if (index === 6) keyName = keyOf(errors, 'customFieldKey6');
  if (index === 7) keyName = keyOf(errors, 'customFieldKey7');
  if (index === 8) keyName = keyOf(errors, 'customFieldKey8');
  if (index === 9) keyName = keyOf(errors, 'customFieldKey9');
  if (index === 10) keyName = keyOf(errors, 'customFieldKey10');
  if (index === 12) keyName = keyOf(errors, 'customFieldKey12');
  if (index === 13) keyName = keyOf(errors, 'customFieldKey13');
  if (index === 14) keyName = keyOf(errors, 'customFieldKey14');
  if (index === 15) keyName = keyOf(errors, 'customFieldKey15');
  if (index === 16) keyName = keyOf(errors, 'customFieldKey16');
  if (index === 17) keyName = keyOf(errors, 'customFieldKey17');
  if (index === 18) keyName = keyOf(errors, 'customFieldKey18');
  if (index === 19) keyName = keyOf(errors, 'customFieldKey19');

  switch (type) {
    case 'text':
      return (
        <Controller
          name={keyName}
          control={control}
          defaultValue=""
          rules={{ required: required ? 'Required' : false }}
          render={({ onChange, name, value }) => (
            <FormGroupTextInput
              id={`${id}`}
              name={name}
              required={required}
              labelText={title}
              className={`${value.length ? ' hasValue' : ''}`}
              placeholder={description || `Enter ${title.toLowerCase()}`}
              error={errors[keyName] as FieldError | undefined}
              onChange={onChange}
            />
          )}
        />
      );
    case 'regexp':
    case 'integer':
    case 'decimal':
      return (
        <Controller
          name={keyName}
          control={control}
          defaultValue=""
          rules={{
            required: required ? 'Required' : false,
            validate: () => {
              const value = getValues(keyName);

              if (!required && !value.length) return true;

              const regExp = (regexpForValidation || '')
                .replace('\\A', '^')
                .replace('\\z', '$');

              const match = !!value.match(regExp);

              return match || 'Invalid';
            },
          }}
          render={({ onChange, name, value }) => (
            <FormGroupTextInput
              id={`${id}`}
              name={name}
              required={required}
              labelText={title}
              className={`${value.length ? ' hasValue' : ''}`}
              placeholder={description || `Enter ${title.toLowerCase()}`}
              error={errors[keyName] as FieldError | undefined}
              onChange={onChange}
            />
          )}
        />
      );
    case 'textarea':
      return (
        <Controller
          name={keyName}
          control={control}
          defaultValue=""
          rules={{ required: required ? 'Required' : false }}
          render={({ onChange, name, value }) => (
            <FormGroupTextarea
              id={`${id}`}
              name={name}
              rows={5}
              required={required}
              labelText={title}
              className={`${value.length ? ' hasValue' : ''}`}
              placeholder={description || 'Description of issue'}
              error={errors.details}
              onChange={onChange}
            />
          )}
        />
      );
    case 'tagger':
      return (
        <Controller
          name={keyName}
          control={control}
          defaultValue=""
          rules={{ required: required ? 'Required' : false }}
          render={({ onChange, value, name }) => (
            <FormGroupSelect
              id={`${id}`}
              name={name}
              clearable={true}
              required={required}
              hasValue={!!value}
              type="single-searchable"
              labelText={title}
              placeholder={description || `Select ${title.toLowerCase()}`}
              options={(customFieldOptions || []).map((opt) => ({
                id: `${opt.value}`,
                name: opt.name,
              }))}
              error={errors[keyName] as FieldError | undefined}
              onChange={(values: ComboBoxObject[]) => onChange(values[0]?.id)}
            />
          )}
        />
      );
    case 'multiselect':
      return (
        <Controller
          name={keyName}
          control={control}
          defaultValue=""
          rules={{ required: required ? 'Required' : false }}
          render={({ onChange, value, name }) => (
            <FormGroupSelect
              id={`${id}`}
              name={name}
              clearable={true}
              required={required}
              hasValue={!!value}
              type="multiple-searchable"
              labelText={title}
              placeholder={description || `Select ${title.toLowerCase()}`}
              options={(customFieldOptions || []).map((opt) => ({
                id: `${opt.value}`,
                name: opt.name,
              }))}
              error={errors[keyName] as FieldError | undefined}
              onChange={(values: ComboBoxObject[]) =>
                onChange(
                  values.length
                    ? values.map((val) => val.id).join(multiSelectSeparator)
                    : undefined
                )
              }
            />
          )}
        />
      );
    default:
      return null;
  }
};
export default TicketField;
