import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { ApiCollection, ApiError } from 'models/api';
import {
  SoftwareDownloadGroup,
  SoftwareFilter,
  SoftwareGroupState,
} from 'models/state/software-downloads';
import { Action, Reducer } from 'redux';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const SOFTWARE_DOWNLOADS_FETCH_REQUEST = 'SOFTWARE_DOWNLOADS_FETCH_REQUEST';
const SOFTWARE_DOWNLOADS_FETCH_SUCCESS = 'SOFTWARE_DOWNLOADS_FETCH_SUCCESS';
const SOFTWARE_DOWNLOADS_FETCH_FAILURE = 'SOFTWARE_DOWNLOADS_FETCH_FAILURE';

interface SoftwareDownloadsFetchRequestAction extends Action<string> {
  type: typeof SOFTWARE_DOWNLOADS_FETCH_REQUEST;
}

interface SoftwareDownloadsFetchSuccessAction extends Action<string> {
  type: typeof SOFTWARE_DOWNLOADS_FETCH_SUCCESS;
  payload: ApiCollection<SoftwareDownloadGroup>;
}

interface SoftwareDownloadsFetchFailureAction extends Action<string> {
  type: typeof SOFTWARE_DOWNLOADS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type SoftwareDownloadsActions =
  | SoftwareDownloadsFetchRequestAction
  | SoftwareDownloadsFetchSuccessAction
  | SoftwareDownloadsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: () =>
    ({
      type: SOFTWARE_DOWNLOADS_FETCH_REQUEST,
    } as SoftwareDownloadsFetchRequestAction),
  success: (data: ApiCollection<SoftwareDownloadGroup>) =>
    ({
      type: SOFTWARE_DOWNLOADS_FETCH_SUCCESS,
      payload: data,
    } as SoftwareDownloadsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: SOFTWARE_DOWNLOADS_FETCH_FAILURE,
      payload: error,
    } as SoftwareDownloadsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestSoftwareDownloads:
    (
      softwareDownloadsFilter?: SoftwareFilter
    ): AppThunkAction<SoftwareDownloadsActions> =>
    async (dispatch) => {
      dispatch(actionCreators.request());

      const softwareDownloadFilter = {
        page: 1,
        pageSize: 20,
        searchText: '',
        operatingSystem: null,
        type: null,
        product: null,
        ...softwareDownloadsFilter,
      };

      const filter = `?page=${softwareDownloadFilter.page}&pageSize=${
        softwareDownloadFilter.pageSize
      }
          ${
            softwareDownloadFilter.operatingSystem
              ? `&operatingSystem=${softwareDownloadFilter.operatingSystem}`
              : ''
          }
            ${
              softwareDownloadFilter.type
                ? `&type=${softwareDownloadFilter.type}`
                : ''
            }
            ${
              softwareDownloadFilter.product
                ? `&product=${softwareDownloadFilter.product}`
                : ''
            }&search=${encodeURIComponent(softwareDownloadFilter.searchText)}`;

      api
        .get<ApiCollection<SoftwareDownloadGroup>>(
          `api/v1/softwareDownloads/asgroup/${filter}`
        )
        .then(
          (data) => {
            dispatch(actionCreators.success(data));
          },
          (error: ApiError) => {
            dispatch(actionCreators.failure(error));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: SoftwareGroupState = {
  data: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<SoftwareGroupState> = (
  state: SoftwareGroupState = defaultState,
  incomingAction: Action | undefined = undefined
): SoftwareGroupState => {
  const action = incomingAction as SoftwareDownloadsActions;

  switch (action.type) {
    case SOFTWARE_DOWNLOADS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SOFTWARE_DOWNLOADS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: undefined,
      };

    case SOFTWARE_DOWNLOADS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        data: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
