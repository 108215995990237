// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { Ticket, TicketDetailsState } from 'models/state/tickets';
import { ApiError } from 'models/api';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const TICKET_DETAILS_FETCH_REQUEST = 'TICKET_DETAILS_FETCH_REQUEST';
const TICKET_DETAILS_FETCH_SUCCESS = 'TICKET_DETAILS_FETCH_SUCCESS';
const TICKET_DETAILS_FETCH_FAILURE = 'TICKET_DETAILS_FETCH_FAILURE';

interface TicketFetchRequestAction extends Action<string> {
  type: typeof TICKET_DETAILS_FETCH_REQUEST;
}

interface TicketFetchSuccessAction extends Action<string> {
  type: typeof TICKET_DETAILS_FETCH_SUCCESS;
  payload: Ticket;
}

interface TicketFetchFailureAction extends Action<string> {
  type: typeof TICKET_DETAILS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type TicketActions =
  | TicketFetchRequestAction
  | TicketFetchSuccessAction
  | TicketFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: () =>
    ({
      type: TICKET_DETAILS_FETCH_REQUEST,
    } as TicketFetchRequestAction),
  success: (data: Ticket) =>
    ({
      type: TICKET_DETAILS_FETCH_SUCCESS,
      payload: data,
    } as TicketFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: TICKET_DETAILS_FETCH_FAILURE,
      payload: error,
    } as TicketFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestTicket:
    (ticketId: string): AppThunkAction<TicketActions> =>
    async (dispatch) => {
      dispatch(actionCreators.request());
      api.get<Ticket>(`api/v1/tickets/${ticketId}`).then(
        (data) => {
          dispatch(actionCreators.success(data));
        },
        (error: ApiError) => {
          dispatch(actionCreators.failure(error));
        }
      );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: TicketDetailsState = {
  ticket: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<TicketDetailsState> = (
  state: TicketDetailsState = defaultState,
  incomingAction: Action | undefined = undefined
): TicketDetailsState => {
  const action = incomingAction as TicketActions;

  switch (action.type) {
    case TICKET_DETAILS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TICKET_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        ticket: action.payload,
        error: undefined,
      };

    case TICKET_DETAILS_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        ticket: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
