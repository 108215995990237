export const ROUTES = {
  root: '/',
  callback: '/callback',
  callbackError: '/callback-error',
  ticketList: '/tickets',
  ticketDetails: '/tickets/:id',
  ticketNew: '/tickets/new/',
  ticketThankYou: '/tickets/:id/thank-you/',
  softwareDownloads: '/software-downloads'
};
