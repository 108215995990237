import { FunctionComponent } from 'react';

import styles from './ExpansionPanel.module.scss';

interface ExpansionPanelDividerProps {
  columns: number;
}
const ExpansionPanelDivider: FunctionComponent<ExpansionPanelDividerProps> = ({
  columns,
}: ExpansionPanelDividerProps) => {
  const amountOfDividers = columns === 1 ? 1 : columns - 1;
  return (
    <>
      {Array.from(new Array(amountOfDividers)).map((_, i) => (
        <span
          key={`column${_ + i}`}
          className={styles['expansion-panel-divider']}
          style={{ left: `${(100 / columns) * (i + 1)}%` }}
        />
      ))}
    </>
  );
};

export default ExpansionPanelDivider;
