import { FunctionComponent, ReactNode, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import styles from './TicketCard.module.scss';

interface TicketCardProps {
  url?: string;
  icon?: ReactNode;
  title: string;
  description?: string;
  styleTileBordered?: boolean;
  styleTileBrick?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const TicketCard: FunctionComponent<TicketCardProps> = ({
  url,
  icon,
  title,
  description,
  styleTileBordered,
  styleTileBrick,
  onClick,
}: TicketCardProps) => {
  let css = styles.ticketCard;

  if (styleTileBordered) {
    css += ` ${styles.tileBordered}`;
  }

  if (styleTileBrick) {
    css += ` tile-brick ${styles.ticketCardTileBrick}`;
  }

  const ticketCardContent = (
    <div className={styles.ticketContent}>
      <div className={styles.ticketImage}>{icon}</div>
      <div className={styles.ticketText}>
        <h3 className={styles.ticketHeading}>{title}</h3>
        {description}
      </div>
    </div>
  );

  if (url) {
    return (
      <Link to={url} className={css}>
        {ticketCardContent}
      </Link>
    );
  }

  return (
    <button type="button" onClick={onClick} className={css}>
      {ticketCardContent}
    </button>
  );
};

export default TicketCard;
