// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Settings } from 'models/state/settings';
import { ApiError } from 'models/api';

export const SETTINGS_FETCH_REQUEST = 'SETTINGS_FETCH_REQUEST';
export const SETTINGS_FETCH_SUCCESS = 'SETTINGS_FETCH_SUCCESS';
export const SETTINGS_FETCH_FAILURE = 'SETTINGS_FETCH_FAILURE';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface SettingsFetchRequestAction {
  type: typeof SETTINGS_FETCH_REQUEST;
}

interface SettingsFetchSuccessAction {
  type: typeof SETTINGS_FETCH_SUCCESS;
  payload: Settings;
}

interface SettingsFetchFailureAction {
  type: typeof SETTINGS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type SettingsActions =
  | SettingsFetchRequestAction
  | SettingsFetchSuccessAction
  | SettingsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const settingsActionCreators = {
  request: () =>
    ({ type: SETTINGS_FETCH_REQUEST } as SettingsFetchRequestAction),
  success: (data: Settings) =>
    ({
      type: SETTINGS_FETCH_SUCCESS,
      payload: data,
    } as SettingsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: SETTINGS_FETCH_FAILURE,
      payload: error,
    } as SettingsFetchFailureAction),
};

// ----------------
// THUNKS
export const settingThunks = {
  requestSettingsThunk: (): AppThunkAction<SettingsActions> => async (
    dispatch,
    getState
  ) => {
    dispatch(settingsActionCreators.request());

    const url = '/api/v1/settings';
    api.get<Settings>(url).then(
      (data) => {
        dispatch(settingsActionCreators.success(data));
      },
      (error: ApiError) => {
        dispatch(settingsActionCreators.failure(error));
      }
    );
  },
};
