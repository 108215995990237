import { ReactNode, FunctionComponent, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import styles from './Button.module.scss';

interface ButtonProps {
  children?: ReactNode;
  href?: string;
  component?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: ReactNode;
  color?: 'green' | 'blue' | 'red' | 'lightBlue';
  smallBtn?: boolean;
  linkBtn?: boolean;
  type?: 'submit' | 'button';
}

const Button: FunctionComponent<ButtonProps> = ({
  children,
  href,
  onClick,
  disabled,
  loading,
  loadingText,
  color,
  smallBtn,
  linkBtn,
  type = 'button',
}: ButtonProps) => {
  const colorStyle = color || 'blue';
  const combinedStyles = linkBtn
    ? `${styles.linkBtn}`
    : `${styles.cBtn} ${smallBtn ? styles.btnSmall : ''} ${styles.btnRound} ${
        styles[colorStyle]
      }`;
  let isDisabled = disabled;

  if (loading) {
    isDisabled = true;
  }

  if (href && !isDisabled) {
    return (
      <Link to={href} className={combinedStyles}>
        {children}
      </Link>
    );
  }

  let loadingElements = null;
  if (loading) {
    loadingElements = (
      <>
        {loadingText || 'Loading'}
        <span className={styles.dots} />
      </>
    );
  }

  return (
    <button
      type={type === 'button' ? 'button' : 'submit'}
      onClick={onClick}
      disabled={isDisabled}
      className={combinedStyles}
    >
      {loading ? loadingElements : children}
    </button>
  );
};

export default Button;
