// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { ApiCollection, ApiError } from 'models/api';
import { Contact } from 'models/state/contacts';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export const CONTACTS_FETCH_REQUEST = 'CONTACTS_FETCH_REQUEST';
export const CONTACTS_FETCH_SUCCESS = 'CONTACTS_FETCH_SUCCESS';
export const CONTACTS_FETCH_FAILURE = 'CONTACTS_FETCH_FAILURE';

interface ContactsFetchRequestAction {
  type: typeof CONTACTS_FETCH_REQUEST;
}

interface ContactsFetchSuccessAction {
  type: typeof CONTACTS_FETCH_SUCCESS;
  payload: Contact[];
}

interface ContactsFetchFailureAction {
  type: typeof CONTACTS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ContactsActions =
  | ContactsFetchRequestAction
  | ContactsFetchSuccessAction
  | ContactsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  request: () =>
    ({ type: CONTACTS_FETCH_REQUEST } as ContactsFetchRequestAction),
  success: (data: Contact[]) =>
    ({
      type: CONTACTS_FETCH_SUCCESS,
      payload: data,
    } as ContactsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: CONTACTS_FETCH_FAILURE,
      payload: error,
    } as ContactsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestContactsThunk: (): AppThunkAction<ContactsActions> => async (
    dispatch
  ) => {
    dispatch(actionCreators.request());
    api.get<ApiCollection<Contact>>('api/v1/contacts').then(
      (data) => {
        dispatch(actionCreators.success(data.items));
      },
      (error: ApiError) => {
        dispatch(actionCreators.failure(error));
      }
    );
  },
};
