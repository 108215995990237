import { FunctionComponent } from 'react';
import { StepProgress, Step } from 'shared/components';

interface TicketNewStepsProps {
  activeStep: string;
  onSelect: (step: string) => void;
}

const TicketNewSteps: FunctionComponent<TicketNewStepsProps> = ({
  onSelect,
  activeStep,
}: TicketNewStepsProps) => {
  const onClickStep1 =
    activeStep === 'Step2' || activeStep === 'Step3'
      ? () => {
          onSelect('Step1');
        }
      : undefined;

  return (
    <StepProgress activeStepKey={activeStep}>
      <Step stepKey="Step1" onClick={onClickStep1}>
        1. How can we help you?
      </Step>
      <Step stepKey="Step2">2. Describe your request</Step>
      <Step stepKey="Step3">3. Confirmation</Step>
    </StepProgress>
  );
};

export default TicketNewSteps;
