import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { ApiCollection, ApiError } from 'models/api';
import {
  SoftwareDownload,
  SoftwareFilesDictionary,
  SoftwareFilesFilter,
} from 'models/state/software-downloads';
import { Action, Reducer } from 'redux';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const SOFTWARE_DOWNLOAD_FILES_FETCH_REQUEST =
  'SOFTWARE_DOWNLOAD_FILES_FETCH_REQUEST';
const SOFTWARE_DOWNLOAD_FILES_FETCH_SUCCESS =
  'SOFTWARE_DOWNLOAD_FILES_FETCH_SUCCESS';
const SOFTWARE_DOWNLOAD_FILES_FETCH_FAILURE =
  'SOFTWARE_DOWNLOAD_FILES_FETCH_FAILURE';

interface SoftwareDownloadFilesFetchRequestAction extends Action<string> {
  type: typeof SOFTWARE_DOWNLOAD_FILES_FETCH_REQUEST;
  index: number;
}

interface SoftwareDownloadFilesFetchSuccessAction extends Action<string> {
  type: typeof SOFTWARE_DOWNLOAD_FILES_FETCH_SUCCESS;
  payload: ApiCollection<SoftwareDownload>;
  index: number;
}

interface SoftwareDownloadFilesFetchFailureAction extends Action<string> {
  type: typeof SOFTWARE_DOWNLOAD_FILES_FETCH_FAILURE;
  payload: ApiError;
  index: number;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type FilesActions =
  | SoftwareDownloadFilesFetchRequestAction
  | SoftwareDownloadFilesFetchSuccessAction
  | SoftwareDownloadFilesFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: (filter: SoftwareFilesFilter, index: number) =>
    ({
      type: SOFTWARE_DOWNLOAD_FILES_FETCH_REQUEST,
      index,
    } as SoftwareDownloadFilesFetchRequestAction),
  success: (data: ApiCollection<SoftwareDownload>, index: number) =>
    ({
      type: SOFTWARE_DOWNLOAD_FILES_FETCH_SUCCESS,
      index,
      payload: data,
    } as SoftwareDownloadFilesFetchSuccessAction),
  failure: (error: ApiError, index: number) =>
    ({
      type: SOFTWARE_DOWNLOAD_FILES_FETCH_FAILURE,
      index,
      payload: error,
    } as SoftwareDownloadFilesFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestFiles:
    (
      filesFilter: SoftwareFilesFilter,
      index: number
    ): AppThunkAction<FilesActions> =>
    async (dispatch) => {
      dispatch(actionCreators.request(filesFilter, index));

      const filter = `?operatingSystem=${filesFilter.operatingSystem}&type=${filesFilter.type}&product=${filesFilter.product}`;

      api
        .get<ApiCollection<SoftwareDownload>>(
          `api/v1/softwareDownloads/asfiles/${filter}`
        )
        .then(
          (data) => {
            dispatch(actionCreators.success(data, index));
          },
          (error: ApiError) => {
            dispatch(actionCreators.failure(error, index));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: SoftwareFilesDictionary = {};

export const reducer: Reducer<SoftwareFilesDictionary> = (
  state: SoftwareFilesDictionary = defaultState,
  incomingAction: Action | undefined = undefined
): SoftwareFilesDictionary => {
  const action = incomingAction as FilesActions;

  switch (action.type) {
    case SOFTWARE_DOWNLOAD_FILES_FETCH_REQUEST:
      return {
        ...state,
        [(action as SoftwareDownloadFilesFetchRequestAction).index]: {
          loading: true,
        },
      };

    case SOFTWARE_DOWNLOAD_FILES_FETCH_SUCCESS:
      return {
        ...state,
        [(action as SoftwareDownloadFilesFetchSuccessAction).index]: {
          loading: false,
          data: action.payload,
          error: undefined,
        },
      };

    case SOFTWARE_DOWNLOAD_FILES_FETCH_FAILURE:
      return {
        ...state,
        [(action as SoftwareDownloadFilesFetchFailureAction).index]: {
          loading: true,
          data: undefined,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
