import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'shared/utils';
import { Ticket } from 'models/state/tickets';
import { TicketStatusLabel } from 'modules/tickets/components';
import { Card } from 'react-bootstrap';
import { ApiError, ApiCollection } from 'models/api';
import { LoadingSpinner, Pagination } from 'shared/components';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

import styles from './TicketsTable.module.scss';

interface TicketsTableProps {
  loading: boolean;
  error?: ApiError;
  data?: ApiCollection<Ticket>;
  paginated?: boolean;
  currentPage?: number;
  totalPages?: number;
  showRequester?: boolean;
  onChangePage?: (page: number) => void;
}

const TicketsTable: FunctionComponent<TicketsTableProps> = ({
  loading,
  error,
  data,
  paginated,
  currentPage,
  totalPages,
  showRequester,
  onChangePage,
}) => {
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );
  const showPagination =
    paginated === true &&
    onChangePage !== undefined &&
    currentPage !== undefined &&
    totalPages !== undefined &&
    totalPages > 1;

  return (
    <>
      {loading && (
        <LoadingSpinner className="section-margin blockquote text-center" />
      )}

      {!loading && error && (
        <div className="section-title-spacing">
          <p>Unable to fetch tickets.</p>
        </div>
      )}

      {!loading && !error && data && (
        <>
          {data.items.length === 0 && (
            <Card>
              <Card.Body>
                <Card.Text className="text-center">No tickets found</Card.Text>
              </Card.Body>
            </Card>
          )}

          {data.items.length > 0 && (
            <>
              <table className="table cTable">
                <thead>
                  <tr>
                    <th>{translations!.TicketsSubject}</th>
                    <th className="desktop-only">{translations!.CommonID}</th>
                    {showRequester && (
                      <th
                        className={`${styles['requester-column']} desktop-only`}
                      >
                        {translations!.TicketsRequester}
                      </th>
                    )}
                    <th className="desktop-only">
                      {translations!.CommonCreated}
                    </th>
                    <th className="desktop-only">
                      {translations!.TicketsLastActivity}
                    </th>
                    <th>{translations!.CommonStatus}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.items.map((ticket) => (
                    <tr key={`ticket-${ticket.id}`}>
                      <td>
                        <Link to={`/tickets/${ticket.id}/`}>
                          {ticket.subject}
                        </Link>
                      </td>
                      <td className="desktop-only">
                        <Link to={`/tickets/${ticket.id}/`}>#{ticket.id}</Link>
                      </td>
                      {showRequester && (
                        <td
                          className={`${styles['requester-column']} desktop-only`}
                        >
                          {ticket.requester}
                        </td>
                      )}
                      <td className="nowrap desktop-only">
                        {formatDate(ticket.createdAt, 'dd MMM yyyy')}
                      </td>
                      <td className="nowrap desktop-only">
                        {formatDate(ticket.updatedAt, 'dd MMM yyyy')}
                      </td>
                      <td className="nowrap">
                        <TicketStatusLabel ticketStatus={ticket.status} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {showPagination && (
                <Pagination
                  className="mt-4"
                  currentPage={currentPage!}
                  totalPages={totalPages!}
                  onChangePage={onChangePage!}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TicketsTable;
