import { PureComponent, createRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { TicketComment } from 'models/state/tickets';
import { LoadingSpinner } from 'shared/components';
import { ApiError, ApiCollection } from 'models/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import SimpleBar from 'simplebar-react';
import TicketDetailedComment from './TicketDetailedComment';

import 'simplebar/dist/simplebar.min.css';
import styles from './TicketDetailedComments.module.scss';

const mapStateToProps = (state: ApplicationState) => ({
  translationsState: state.i18n,
});
interface TicketCommentsOwnProps {
  loading: boolean;
  error?: ApiError;
  data?: ApiCollection<TicketComment>;
  currentPage: number;
  totalPages: number;
  onChangePage: (page: number) => void;
}

type TicketCommentsProps = ReturnType<typeof mapStateToProps> &
  TicketCommentsOwnProps;

class TicketDetailedComments extends PureComponent<TicketCommentsProps> {
  simpleBarRef: RefObject<SimpleBar>;

  constructor(props: TicketCommentsProps) {
    super(props);
    this.simpleBarRef = createRef();
  }

  render() {
    const {
      error,
      data,
      currentPage,
      totalPages,
      onChangePage,
      translationsState: {
        loading: loadingTranslations,
        error: translationsError,
        translations,
      },
    } = this.props;

    const scrollableTarget = !this.simpleBarRef
      ? null
      : this.simpleBarRef.current;

    const hasTranslations =
      !loadingTranslations &&
      translationsError === undefined &&
      translations !== undefined;

    if (!hasTranslations) {
      return (
        <LoadingSpinner className="section-margin blockquote text-center" />
      );
    }

    return (
      <div className={styles['ticket-comment-wrapper']}>
        <SimpleBar
          forceVisible="y"
          // autoHide={false}
          style={{ height: 500 }}
          scrollableNodeProps={{ ref: this.simpleBarRef }}
        >
          {error && (
            <div className={styles['ticket-comment']}>
              <p>
                {error.errorCode === 'UNAUTHORIZED'
                  ? 'You are not authorized to see the comments for this ticket'
                  : translations!.TicketsErrorFetchingComments}
              </p>
            </div>
          )}
          {data && data.totalHits > 0 && (
            <InfiniteScroll
              dataLength={data.items.length}
              next={() => onChangePage(currentPage + 1)}
              hasMore={currentPage < totalPages}
              scrollableTarget={scrollableTarget}
              loader={
                <LoadingSpinner className="section-margin blockquote text-center" />
              }
            >
              {data.items.map((comment) => (
                <TicketDetailedComment key={comment.id} comment={comment} />
              ))}
            </InfiniteScroll>
          )}
          {data && data.totalHits === 0 && (
            <p className="body-2 p-4">{translations!.TicketsNoComments}</p>
          )}
          {!data && !error && (
            <LoadingSpinner className="section-margin blockquote text-center" />
          )}
        </SimpleBar>
      </div>
    );
  }
}
export default connect(mapStateToProps)(TicketDetailedComments);
