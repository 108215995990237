import { FunctionComponent } from 'react';
import { getLogo, getClassName } from 'modules/home/utils/services';
import { Service } from 'models/state/services';
import '../../styles/services.scss';
import styles from './OtherServices.module.scss';

interface OtherServiceProps {
  service: Service;
}

const OtherService: FunctionComponent<OtherServiceProps> = ({ service }) => {
  const logoSrc = getLogo(service.id);

  return (
    <div className={styles.serviceTile}>
      <div className={`${styles.logoContainer} ${getClassName(service.id)}`}>
        {logoSrc ? (
          <img
            className={styles['service-logo']}
            alt={service.name}
            src={logoSrc}
          />
        ) : (
          <span className={styles.serviceLogoText}>
            {service.id.toLowerCase()}
          </span>
        )}
      </div>
      <div className={styles.contentContainer}>
        <h4>{service.heading}</h4>
        <p className="body2">{service.description}</p>
      </div>
    </div>
  );
};

export default OtherService;
