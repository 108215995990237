// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { TicketFormState, TicketForm } from 'models/state/tickets';
import { ApiError } from 'models/api';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const TICKET_FORM_FETCH_REQUEST = 'TICKET_FORM_FETCH_REQUEST';
const TICKET_FORM_FETCH_SUCCESS = 'TICKET_FORM_FETCH_SUCCESS';
const TICKET_FORM_FETCH_FAILURE = 'TICKET_FORM_FETCH_FAILURE';

interface TicketFormFetchRequestAction extends Action<string> {
  type: typeof TICKET_FORM_FETCH_REQUEST;
}

interface TicketFormFetchSuccessAction extends Action<string> {
  type: typeof TICKET_FORM_FETCH_SUCCESS;
  payload: TicketForm;
}

interface TicketFormFetchFailureAction extends Action<string> {
  type: typeof TICKET_FORM_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type TicketFormActions =
  | TicketFormFetchRequestAction
  | TicketFormFetchSuccessAction
  | TicketFormFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
const actionCreators = {
  request: () =>
    ({
      type: TICKET_FORM_FETCH_REQUEST,
    } as TicketFormFetchRequestAction),
  success: (data: TicketForm) =>
    ({
      type: TICKET_FORM_FETCH_SUCCESS,
      payload: data,
    } as TicketFormFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: TICKET_FORM_FETCH_FAILURE,
      payload: error,
    } as TicketFormFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestTicketForm:
    (ticketFormId: number): AppThunkAction<TicketFormActions> =>
    async (dispatch) => {
      dispatch(actionCreators.request());
      api.get<TicketForm>(`api/v1/tickets/ticket-form/${ticketFormId}`).then(
        (data) => {
          dispatch(actionCreators.success(data));
        },
        (error: ApiError) => {
          dispatch(actionCreators.failure(error));
        }
      );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: TicketFormState = {
  ticketForm: undefined,
  loading: false,
  error: undefined,
};

export const reducer: Reducer<TicketFormState> = (
  state: TicketFormState = defaultState,
  incomingAction: Action | undefined = undefined
): TicketFormState => {
  const action = incomingAction as TicketFormActions;

  switch (action.type) {
    case TICKET_FORM_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TICKET_FORM_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketForm: action.payload,
        error: undefined,
      };

    case TICKET_FORM_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        ticketForm: undefined,
        error: action.payload,
      };

    default:
      return state;
  }
};
