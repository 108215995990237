// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { Action, Reducer } from 'redux';
import { Ticket, TicketPost, TicketCreateState } from 'models/state/tickets';
import { ApiError } from 'models/api';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const TICKET_CREATE_REQUEST = 'TICKET_CREATE_REQUEST';
const TICKET_CREATE_SUCCESS = 'TICKET_CREATE_SUCCESS';
const TICKET_CREATE_FAILURE = 'TICKET_CREATE_FAILURE';
const TICKET_CREATE_RESET = 'TICKET_CREATE_RESET';

interface TicketCreateRequestAction extends Action<string> {
  type: typeof TICKET_CREATE_REQUEST;
}

interface TicketCreateSuccessAction extends Action<string> {
  type: typeof TICKET_CREATE_SUCCESS;
  payload: Ticket;
}

interface TicketCreateFailureAction extends Action<string> {
  type: typeof TICKET_CREATE_FAILURE;
  payload: ApiError;
}
interface TicketCreateResetAction extends Action<string> {
  type: typeof TICKET_CREATE_RESET;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type TicketCreateActions =
  | TicketCreateRequestAction
  | TicketCreateSuccessAction
  | TicketCreateFailureAction
  | TicketCreateResetAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  request: () =>
    ({
      type: TICKET_CREATE_REQUEST,
    } as TicketCreateActions),
  success: (data: Ticket) =>
    ({
      type: TICKET_CREATE_SUCCESS,
      payload: data,
    } as TicketCreateSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: TICKET_CREATE_FAILURE,
      payload: error,
    } as TicketCreateFailureAction),
  reset: () =>
    ({
      type: TICKET_CREATE_RESET,
    } as TicketCreateResetAction),
};

// ----------------
// THUNKS
export const thunks = {
  createTicket:
    (
      ticket: TicketPost,
      onSuccess: (ticket: Ticket) => void
    ): AppThunkAction<TicketCreateActions> =>
    async (dispatch) => {
      dispatch(actionCreators.request());
      api.post<TicketPost, Ticket>('api/v1/tickets/', ticket).then(
        (data) => {
          dispatch(actionCreators.success(data));
          onSuccess(data);
        },
        (error: ApiError) => {
          dispatch(actionCreators.failure(error));
        }
      );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: TicketCreateState = {
  loading: false,
  error: undefined,
};

export const reducer: Reducer<TicketCreateState> = (
  state: TicketCreateState = defaultState,
  incomingAction: Action | undefined = undefined
): TicketCreateState => {
  const action = incomingAction as TicketCreateActions;

  switch (action.type) {
    case TICKET_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        ticket: undefined,
      };

    case TICKET_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ticket: action.payload,
        error: undefined,
      };

    case TICKET_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        ticket: undefined,
        error: action.payload,
      };

    case TICKET_CREATE_RESET:
      return defaultState;
    default:
      return state;
  }
};
