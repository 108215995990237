import { FunctionComponent } from 'react';
import styles from './Plus.module.scss';

interface PlusProps {
  color?: string;
  size?: string;
}

const plus: FunctionComponent<PlusProps> = ({
  color = '#FFF',
  size = '9px',
}: PlusProps) => (
  <span className={styles.plus}>
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
    >
      <path
        fillRule="evenodd"
        d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
      />
    </svg>
  </span>
);

export default plus;
