import { FunctionComponent } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { Service } from 'models/state/services';
import { getLogo, getClassName } from 'modules/home/utils/services';
import 'react-grid-layout/css/styles.css';
import '../../styles/services.scss';
import styles from './MyServices.module.scss';

interface MyServiceProps {
  service: Service;
  layouts: ReactGridLayout.Layouts;
  index: number;
  classNameLogo?: string;
}

const MyService: FunctionComponent<MyServiceProps> = ({
  service,
  layouts,
  index,
  classNameLogo,
}) => {
  const logoSrc = getLogo(service.id);

  const className = [getClassName(service.id), classNameLogo]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={styles.serviceTile}>
      <div className={`${styles.logoContainer} ${className}`}>
        {logoSrc ? (
          <img
            className={styles['service-logo']}
            alt={service.name}
            src={logoSrc}
            style={{
              maxHeight: layouts.lg[index]?.h === 12 ? 120 : 70,
            }}
          />
        ) : (
          <span className={styles.serviceLogoText}>
            {service.id.toLowerCase()}
          </span>
        )}
      </div>
      <div className={styles.contentContainer}>
        <h4>{service.heading}</h4>
        <p className="body2">{service.description}</p>
      </div>
    </div>
  );
};

export default MyService;
