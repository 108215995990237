import { useState, FunctionComponent, MouseEvent } from 'react';
import { useForm, Controller } from 'react-hook-form/dist/index.ie11';
import {
  TicketCreateState,
  TicketCategory,
  TicketField as TicketFieldType,
  TicketPost,
  CustomFieldPost,
} from 'models/state/tickets';
import { TicketAttachmentUpload, SelectInputOption } from 'models/shared';
import {
  Button,
  FileUploader,
  FormGroupTextInput,
  FormGroupSelect,
  FormGroupTextarea,
} from 'shared/components';
import { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import { Card, Row, Col } from 'react-bootstrap';
import { keyOf } from 'shared/utils';
import { ApiCollection } from 'models/api';
import { api } from 'shared/utils/api';
import {
  validateEmail,
  validateEmailOptional,
  validateEmailMultipleOptional,
} from 'shared/utils/validation';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
  AjaxInput,
  ComboBoxObject,
} from '@crayoncupl/styleguide-react/lib/ComboBox/models/ComboBoxModels';
import { multiSelectSeparator } from './utils';
import TicketField from './TicketField';

interface TicketFormProps {
  ticketCreateState: TicketCreateState;
  category: TicketCategory;
  onSubmitTicket: (ticket: TicketPost) => void;
  saving: boolean;
  goToStepOne: () => void;
  ticketFields: TicketFieldType[];
}
export interface TicketFormData {
  customerAccountId: number;
  subject: string;
  details: string;
  haveAlreadyTried?: string;
  cc?: string;

  primaryContactFirstName?: string;
  primaryContactLastName?: string;
  primaryContactEmail?: string;
  primaryContactPhoneNumber?: string;

  secondaryContactFirstName?: string;
  secondaryContactLastName?: string;
  secondaryContactEmail?: string;
  secondaryContactPhoneNumber?: string;

  customFieldKey0?: string;
  customFieldKey1?: string;
  customFieldKey2?: string;
  customFieldKey3?: string;
  customFieldKey4?: string;
  customFieldKey5?: string;
  customFieldKey6?: string;
  customFieldKey7?: string;
  customFieldKey8?: string;
  customFieldKey9?: string;
  customFieldKey10?: string;
  customFieldKey11?: string;
  customFieldKey12?: string;
  customFieldKey13?: string;
  customFieldKey14?: string;
  customFieldKey15?: string;
  customFieldKey16?: string;
  customFieldKey17?: string;
  customFieldKey18?: string;
  customFieldKey19?: string;
}
interface TicketFormState {
  files: TicketAttachmentUpload[];
}

const TicketForm: FunctionComponent<TicketFormProps> = ({
  ticketCreateState,
  category,
  onSubmitTicket,
  saving,
  goToStepOne,
  ticketFields,
}) => {
  const { profile } = useSelector((state: ApplicationState) => state.oidc.user);
  const { settings } = useSelector((state: ApplicationState) => state.settings);
  const [state, setState] = useState<TicketFormState>({
    files: [],
  });
  const {
    handleSubmit,
    control,
    errors,
    trigger,
    getValues,
    formState,
    watch,
  } = useForm<TicketFormData>({
    mode: 'onChange',
  });

  const productCSCSFieldId =
    settings?.zendeskFormOverrides.crayonStatisticCollaborationSolution
      .productCSCSFieldId;

  if (productCSCSFieldId !== undefined) {
    const productCSCSFieldName = document
      .getElementById(productCSCSFieldId)
      ?.getAttribute('name');

    if (productCSCSFieldName) {
      watch(productCSCSFieldName);
    }
  }

  const reduceFieldsList = (
    ticketFormData: TicketFormData
  ): CustomFieldPost[] =>
    ticketFields.reduce(
      (accumulatedFields: CustomFieldPost[], currentField, index) => {
        const descriptionOrSubject =
          currentField.type === 'description' ||
          currentField.type === 'subject';
        if (descriptionOrSubject) return accumulatedFields;

        let value = '';
        if (index === 0) value = ticketFormData.customFieldKey0 || '';
        if (index === 1) value = ticketFormData.customFieldKey1 || '';
        if (index === 2) value = ticketFormData.customFieldKey2 || '';
        if (index === 3) value = ticketFormData.customFieldKey3 || '';
        if (index === 4) value = ticketFormData.customFieldKey4 || '';
        if (index === 5) value = ticketFormData.customFieldKey5 || '';
        if (index === 6) value = ticketFormData.customFieldKey6 || '';
        if (index === 7) value = ticketFormData.customFieldKey7 || '';
        if (index === 8) value = ticketFormData.customFieldKey8 || '';
        if (index === 9) value = ticketFormData.customFieldKey9 || '';
        if (index === 10) value = ticketFormData.customFieldKey10 || '';
        if (index === 11) value = ticketFormData.customFieldKey11 || '';
        if (index === 12) value = ticketFormData.customFieldKey12 || '';
        if (index === 13) value = ticketFormData.customFieldKey13 || '';
        if (index === 14) value = ticketFormData.customFieldKey14 || '';
        if (index === 15) value = ticketFormData.customFieldKey15 || '';
        if (index === 16) value = ticketFormData.customFieldKey16 || '';
        if (index === 17) value = ticketFormData.customFieldKey17 || '';
        if (index === 18) value = ticketFormData.customFieldKey18 || '';
        if (index === 19) value = ticketFormData.customFieldKey19 || '';

        const valueIsArray = value.indexOf(multiSelectSeparator) !== -1;

        const customField = {
          id: currentField.id,
          value: valueIsArray ? undefined : value,
          values: valueIsArray ? value.split(multiSelectSeparator) : undefined,
        };

        return accumulatedFields.concat(customField);
      },
      []
    );

  const hideStatisticCollaborationSolutionFields = (field: TicketFieldType) => {
    const hiddenFields =
      settings?.zendeskFormOverrides.crayonStatisticCollaborationSolution
        .dependentFields;

    if (!hiddenFields?.some((hf) => hf === field.id.toString())) {
      return false;
    }

    const formValues = getValues();
    const values = reduceFieldsList(formValues);

    if (
      productCSCSFieldId !== undefined &&
      values.some(
        (x) =>
          String(x.id) === productCSCSFieldId &&
          x.value === 'ibm_spss_statistics'
      )
    ) {
      return false;
    }
    return true;
  };

  // called every time a file's `status` changes
  const handleChangeStatus = (
    file: IFileWithMeta,
    status: StatusValue,
    allFiles: IFileWithMeta[]
  ) => {
    if (file.xhr && (status === 'removed' || status === 'done')) {
      if (status === 'removed') {
        const files = state.files.filter((item) => item.id !== file.meta.id);
        setState({ ...state, ...{ files } });
      } else if (status === 'done') {
        const { token } = JSON.parse(file.xhr.response);

        const attachment: TicketAttachmentUpload = {
          token,
          name: file.file.name,
          id: file.meta.id,
        };

        setState({ ...state, ...{ files: [...state.files, attachment] } });
      }
    }
  };

  const handleClickCancel = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    goToStepOne();
  };

  const onSubmitForm = (data: TicketFormData) => {
    const uploads: string[] = state.files.map((a) => a.token);

    const ticket: TicketPost = {
      categoryCode: category.code,
      emailCategory: category.emailCategory,
      ticketFormId: category.ticketFormId,
      customerAccountId: data.customerAccountId,
      subject: data.subject,
      comment: {
        body: data.details,
        uploads,
      },
      cc: data.cc,
      haveAlreadyTried: data.haveAlreadyTried,
      primaryContact: data.primaryContactFirstName
        ? {
            firstName: data.primaryContactFirstName || '',
            lastName: data.primaryContactLastName || '',
            email: data.primaryContactEmail || '',
            phoneNumber: data.primaryContactPhoneNumber,
          }
        : undefined,
      secondaryContact: data.secondaryContactFirstName
        ? {
            firstName: data.secondaryContactFirstName || '',
            lastName: data.secondaryContactLastName || '',
            email: data.secondaryContactEmail || '',
            phoneNumber: data.secondaryContactPhoneNumber,
          }
        : undefined,
      customFields: reduceFieldsList(data),
    };

    onSubmitTicket(ticket);
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col lg={8}>
            {ticketCreateState && ticketCreateState.error && (
              <div className="alert alert-danger">
                {ticketCreateState.error.message}
              </div>
            )}

            <form
              noValidate={true}
              className={`form${formState.isSubmitted ? ' was-validated' : ''}`}
              autoComplete="off"
            >
              <Controller
                name={keyOf(errors, 'customerAccountId')}
                control={control}
                defaultValue={null}
                rules={{ required: 'Required' }}
                render={({ onChange, value, name }) => (
                  <FormGroupSelect
                    id="requestCustomerAccount"
                    name={name}
                    clearable={true}
                    required={true}
                    hasValue={!!value}
                    type="single-searchable"
                    labelText="Company"
                    placeholder="Company that the service request is related to"
                    error={errors.customerAccountId}
                    ajaxClientSearch
                    options={async (input: AjaxInput) => {
                      const pageSize = 20;
                      const options = await api.get<
                        ApiCollection<SelectInputOption>
                      >(
                        `/api/v1/data/customer-accounts/?&search=${input.search}&pageSize=${pageSize}&page=${input.page}&ticketFormId=${category.ticketFormId}`
                      );

                      return {
                        items: options.items.map((option) => ({
                          id: option.id,
                          name: option.name,
                        })),
                        more:
                          options.totalHits >
                          input.loadedItems + options.items.length,
                      };
                    }}
                    onChange={(values: ComboBoxObject[]) =>
                      onChange(values[0]?.id)
                    }
                  />
                )}
              />

              <Controller
                name={keyOf(errors, 'subject')}
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ onChange, name, value }) => (
                  <FormGroupTextInput
                    id="requestSubject"
                    name={name}
                    required={true}
                    labelText="Subject"
                    className={`${value.length ? ' hasValue' : ''}`}
                    placeholder="Enter support request title"
                    error={errors.subject}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name={keyOf(errors, 'details')}
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ onChange, name, value }) => (
                  <FormGroupTextarea
                    id="requestDetails"
                    name={name}
                    rows={5}
                    required={true}
                    labelText="Details"
                    className={`${value.length ? ' hasValue' : ''}`}
                    placeholder="Description of issue"
                    error={errors.details}
                    onChange={onChange}
                  />
                )}
              />
              {category.code !== 'service-iq' && (
                <Controller
                  name={keyOf(errors, 'haveAlreadyTried')}
                  control={control}
                  defaultValue=""
                  rules={{ required: false }}
                  render={({ onChange, name, value }) => (
                    <FormGroupTextarea
                      id="requestHaveAlreadyTried"
                      name={name}
                      rows={5}
                      required={false}
                      labelText="Problem solving I have already tried"
                      className={`${value.length ? ' hasValue' : ''}`}
                      placeholder="Problem solving you have tried before sending this request"
                      error={errors.haveAlreadyTried}
                      onChange={onChange}
                    />
                  )}
                />
              )}

              {ticketFields
                .sort((a, b) => a.position - b.position)
                .map((field, i) =>
                  hideStatisticCollaborationSolutionFields(field) ? null : (
                    <TicketField
                      errors={errors}
                      key={field.id}
                      control={control}
                      getValues={getValues}
                      fieldData={field}
                      index={i}
                    />
                  )
                )}

              {category.code === 'service-iq' && (
                <Controller
                  name={keyOf(errors, 'cc')}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: false,
                    validate: {
                      validateSecondaryContact: (value) =>
                        validateEmailMultipleOptional(value) ||
                        'Invalid email(s)',
                    },
                  }}
                  render={({ onChange, name, value }) => (
                    <FormGroupTextInput
                      id="requestCc"
                      name={name}
                      required={false}
                      labelText="CC"
                      className={`${value.length ? ' hasValue' : ''}`}
                      placeholder="CC"
                      error={errors.cc}
                      onChange={onChange}
                    />
                  )}
                />
              )}

              <h4>Attachments</h4>
              <FileUploader handleChangeStatus={handleChangeStatus} />
              {category.code !== 'service-iq' && (
                <>
                  <h4 style={{ marginTop: '24px' }}>Primary contact</h4>
                  <Row>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'primaryContactFirstName')}
                        control={control}
                        defaultValue={profile.given_name}
                        rules={{ required: true }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestPrimaryContactFirstName"
                            name={name}
                            required={true}
                            labelText="First name"
                            defaultValue={profile.given_name}
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="First name"
                            error={errors.primaryContactFirstName}
                            onChange={onChange}
                            style={{ marginBottom: '6px' }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'primaryContactLastName')}
                        control={control}
                        defaultValue={profile.family_name}
                        rules={{ required: true }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestPrimaryContactLastName"
                            name={name}
                            required={true}
                            labelText="Last name"
                            defaultValue={profile.family_name}
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="Last name"
                            error={errors.primaryContactLastName}
                            onChange={onChange}
                            style={{ marginBottom: '6px' }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'primaryContactEmail')}
                        control={control}
                        defaultValue={profile.name}
                        rules={{
                          required: 'Required',
                          validate: {
                            validateSecondaryContact: (value) =>
                              validateEmailOptional(value) || 'Invalid email',
                          },
                        }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestPrimaryContactEmail"
                            name={name}
                            required={true}
                            labelText="Email"
                            defaultValue={profile.name}
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="Email"
                            error={errors.primaryContactEmail}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'primaryContactPhoneNumber')}
                        control={control}
                        defaultValue=""
                        rules={{
                          required:
                            category.code === 'technical-administration',
                        }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestPrimaryContactPhoneNumber"
                            name={name}
                            required={
                              category.code === 'technical-administration'
                            }
                            labelText="Phone number"
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="Phone number"
                            error={errors.primaryContactPhoneNumber}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {category.code === 'technical-administration' && (
                <>
                  <h4>Secondary contact</h4>
                  <Row>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'secondaryContactFirstName')}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: false,
                          validate: {
                            validateSecondaryContact: async (value) => {
                              const {
                                secondaryContactLastName,
                                secondaryContactEmail,
                                secondaryContactPhoneNumber,
                              } = getValues();

                              const isInvalid =
                                (secondaryContactLastName?.length ||
                                  secondaryContactEmail?.length ||
                                  secondaryContactPhoneNumber?.length) &&
                                value === '';

                              return isInvalid ? 'Required' : true;
                            },
                          },
                        }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestSecondaryContactFirstName"
                            name={name}
                            required={false}
                            labelText="First name"
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="First name"
                            error={errors.secondaryContactFirstName}
                            onChange={(event) => {
                              onChange(event.target.value);
                              if (formState.isSubmitted)
                                trigger([
                                  keyOf(errors, 'secondaryContactLastName'),
                                  keyOf(errors, 'secondaryContactEmail'),
                                ]);
                            }}
                            style={{ marginBottom: '6px' }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'secondaryContactLastName')}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: false,
                          validate: {
                            validateSecondaryContact: async (value) => {
                              const {
                                secondaryContactFirstName,
                                secondaryContactEmail,
                                secondaryContactPhoneNumber,
                              } = getValues();

                              const isInvalid =
                                (secondaryContactFirstName?.length ||
                                  secondaryContactEmail?.length ||
                                  secondaryContactPhoneNumber?.length) &&
                                value === '';

                              return isInvalid ? 'Required' : true;
                            },
                          },
                        }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestSecondaryContactLastName"
                            name={name}
                            required={false}
                            labelText="Last name"
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="Last name"
                            error={errors.secondaryContactLastName}
                            onChange={(event) => {
                              onChange(event.target.value);
                              if (formState.isSubmitted)
                                trigger([
                                  keyOf(errors, 'secondaryContactFirstName'),
                                  keyOf(errors, 'secondaryContactEmail'),
                                ]);
                            }}
                            style={{ marginBottom: '6px' }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'secondaryContactEmail')}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: false,
                          validate: {
                            validateSecondaryContact: async (value) => {
                              const {
                                secondaryContactFirstName,
                                secondaryContactLastName,
                                secondaryContactPhoneNumber,
                              } = getValues();

                              const hasOtherValues =
                                secondaryContactFirstName?.length ||
                                secondaryContactLastName?.length ||
                                secondaryContactPhoneNumber?.length;

                              const isValid = hasOtherValues
                                ? validateEmail(value)
                                : validateEmailOptional(value);

                              return isValid || 'Invalid email';
                            },
                          },
                        }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestSecondaryContactEmail"
                            name={name}
                            required={false}
                            labelText="Email"
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="Email"
                            error={errors.secondaryContactEmail}
                            onChange={(event) => {
                              onChange(event.target.value);
                              if (formState.isSubmitted)
                                trigger([
                                  keyOf(errors, 'secondaryContactFirstName'),
                                  keyOf(errors, 'secondaryContactLastName'),
                                ]);
                            }}
                          />
                        )}
                      />
                    </Col>
                    <Col lg={6}>
                      <Controller
                        name={keyOf(errors, 'secondaryContactPhoneNumber')}
                        control={control}
                        defaultValue=""
                        rules={{ required: false }}
                        render={({ onChange, name, value }) => (
                          <FormGroupTextInput
                            id="requestSecondaryContactPhoneNumber"
                            name={name}
                            required={false}
                            labelText="Phone number"
                            className={`${value.length ? ' hasValue' : ''}`}
                            placeholder="Phone number"
                            error={errors.secondaryContactPhoneNumber}
                            onChange={(event) => {
                              onChange(event.target.value);
                              if (formState.isSubmitted)
                                trigger([
                                  keyOf(errors, 'secondaryContactFirstName'),
                                  keyOf(errors, 'secondaryContactLastName'),
                                  keyOf(errors, 'secondaryContactEmail'),
                                ]);
                            }}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <div className="form-button-spacing">
                <Button
                  type="button"
                  onClick={handleSubmit(onSubmitForm)}
                  loading={saving}
                  loadingText="Saving"
                  color="green"
                >
                  Submit request
                </Button>
                <span className="ml-3">
                  <Button linkBtn onClick={(e) => handleClickCancel(e)}>
                    Cancel
                  </Button>
                </span>
              </div>
            </form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TicketForm;
