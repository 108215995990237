import { format } from 'date-fns';

export const formatDate = (
  text: string,
  dateFormat: string = 'dd MMM yyyy'
): string => format(new Date(text), dateFormat);

export const truncateText = (text: string, maxCharacters: number): string => {
  if (text.length < maxCharacters) {
    return text;
  }
  let addWord = true;
  return text.split(' ').reduce((desc: string, word: string) => {
    if (!addWord) {
      return desc;
    }
    if (desc.length + word.length >= maxCharacters) {
      addWord = false;
      return `${desc}...`;
    }
    return `${desc} ${word}`;
  }, '');
};

export const formatFileSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Checks whether property exists in type
 * @param obj Type to be checked
 * @param key Name of property to validate
 */
export const typeHasProperty = <T extends object>(
  obj: T,
  key: string | number | symbol
): key is keyof T => key in obj;

// name={keyOf(errors, 'lastname')}
export function keyOf<T>(o: T, k: keyof T) {
  return k;
}
