import { FunctionComponent, Fragment } from 'react';
import { Ticket } from 'models/state/tickets';
import { Form } from 'react-bootstrap';
import { TicketStatusLabel } from '..';

interface TicketDetailedProps {
  ticket: Ticket;
}

const TicketDetailedTicket: FunctionComponent<TicketDetailedProps> = ({
  ticket,
}) => (
  <>
    <Form.Label className="mt-2 mb-0">Status</Form.Label>
    <br />
    <TicketStatusLabel ticketStatus={ticket.status} />
    <br />
    <Form.Label className="mt-2 mb-0">Ticket ID</Form.Label>
    <br />
    {ticket.id}
    {ticket.customFields.map((cf) => (
      <Fragment key={cf.ticketFieldId}>
        <br />
        <Form.Label className="mt-2 mb-0">{cf.label}</Form.Label>
        <br />
        {cf.text || '-'}
      </Fragment>
    ))}
  </>
);
export default TicketDetailedTicket;
