import { FunctionComponent } from 'react';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faCode } from '@fortawesome/free-solid-svg-icons/faCode';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';

import styles from './SettingsList.module.scss';
import SettingsListItem from './SettingsListItem';

const SettingsList: FunctionComponent = () => {
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );
  const settings = useSelector(
    (state: ApplicationState) => state.settings.settings
  );
  const { cloudIq: cloudIqUrl } = settings!.applicationConnections;

  return (
    <Col md={6}>
      <h3>Settings</h3>
      <ul className={styles.settingsList}>
        <SettingsListItem
          url={`${cloudIqUrl}/organizations/`}
          icon={<FontAwesomeIcon icon={faBuilding} />}
          title={translations!.AdminSettingsCompaniesHeading}
          description={translations!.AdminSettingsCompaniesSubHeading}
        />
        <SettingsListItem
          url={`${cloudIqUrl}/users/`}
          icon={<FontAwesomeIcon icon={faUser} />}
          title={translations!.AdminSettingsUserManagementHeading}
          description={translations!.AdminSettingsUserManagementSubHeading}
        />
        <SettingsListItem
          url={`${cloudIqUrl}/clients/`}
          icon={<FontAwesomeIcon icon={faCode} />}
          title={translations!.AdminSettingsApiManagementHeading}
          description={translations!.AdminSettingsApiManagementSubHeading}
        />
        <SettingsListItem
          hidden
          url={`${cloudIqUrl}/`}
          icon={<FontAwesomeIcon icon={faCode} />}
          title={translations!.AdminSettingsResourcesHeading}
          description={translations!.AdminSettingsResourcesSubHeading}
        />
      </ul>
    </Col>
  );
};

export default SettingsList;
