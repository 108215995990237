// eslint-disable-next-line import/no-cycle
import { Action, Reducer } from 'redux';
import { TicketHistoryState } from 'models/state/tickets';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
const ADD_TO_TICKET_HISTORY = 'ADD_TO_TICKET_HISTORY';
const REMOVE_FROM_TICKET_HISTORY = 'REMOVE_FROM_TICKET_HISTORY';
const CLEAR_TICKET_HISTORY = 'CLEAR_TICKET_HISTORY';

interface AddToTicketHistory extends Action<string> {
  type: typeof ADD_TO_TICKET_HISTORY;
  payload: string;
}
interface RemoveFromTicketHistory extends Action<string> {
  type: typeof REMOVE_FROM_TICKET_HISTORY;
  payload: string;
}
interface ClearTicketHistory extends Action<string> {
  type: typeof CLEAR_TICKET_HISTORY;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type TicketHistoryActions =
  | AddToTicketHistory
  | RemoveFromTicketHistory
  | ClearTicketHistory;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  addTicket: (id: string) =>
    ({
      type: ADD_TO_TICKET_HISTORY,
      payload: id,
    } as AddToTicketHistory),
  removeTicket: (id: string) =>
    ({
      type: REMOVE_FROM_TICKET_HISTORY,
      payload: id,
    } as RemoveFromTicketHistory),
  clearTickets: () =>
    ({
      type: CLEAR_TICKET_HISTORY,
    } as ClearTicketHistory),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: TicketHistoryState = {
  tickets: [],
};

export const reducer: Reducer<TicketHistoryState> = (
  state: TicketHistoryState = defaultState,
  incomingAction: Action | undefined = undefined
): TicketHistoryState => {
  const action = incomingAction as TicketHistoryActions;

  switch (action.type) {
    case ADD_TO_TICKET_HISTORY:
      return {
        tickets: state.tickets.includes(action.payload)
          ? state.tickets
          : [...state.tickets, action.payload],
      };
    case REMOVE_FROM_TICKET_HISTORY:
      return {
        tickets: state.tickets.filter(
          (ticketId) => ticketId !== action.payload
        ),
      };
    case CLEAR_TICKET_HISTORY:
      return defaultState;

    default:
      return state;
  }
};
