// eslint-disable-next-line import/no-cycle
import { AppThunkAction } from 'store';
import { api } from 'shared/utils/api';
import { ApiCollection, ApiError } from 'models/api';
import { BlogItem, ProductsServicesNewsState } from 'models/state/news';
import { Action, Reducer } from 'redux';

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export const PRODUCTS_SERVICES_NEWS_FETCH_REQUEST =
  'PRODUCTS_SERVICES_NEWS_FETCH_REQUEST';
export const PRODUCTS_SERVICES_NEWS_FETCH_SUCCESS =
  'PRODUCTS_SERVICES_NEWS_FETCH_SUCCESS';
export const PRODUCTS_SERVICES_NEWS_FETCH_FAILURE =
  'PRODUCTS_SERVICES_NEWS_FETCH_FAILURE';

interface PsNewsFetchRequestAction {
  type: typeof PRODUCTS_SERVICES_NEWS_FETCH_REQUEST;
}

interface PsNewsFetchSuccessAction {
  type: typeof PRODUCTS_SERVICES_NEWS_FETCH_SUCCESS;
  payload: BlogItem[];
}

interface PsNewsFetchFailureAction {
  type: typeof PRODUCTS_SERVICES_NEWS_FETCH_FAILURE;
  payload: ApiError;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type PsNewsActions =
  | PsNewsFetchRequestAction
  | PsNewsFetchSuccessAction
  | PsNewsFetchFailureAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  request:
    // eslint-disable-next-line max-len
    () =>
      ({
        type: PRODUCTS_SERVICES_NEWS_FETCH_REQUEST,
      } as PsNewsFetchRequestAction),
  success: (data: BlogItem[]) =>
    ({
      type: PRODUCTS_SERVICES_NEWS_FETCH_SUCCESS,
      payload: data,
    } as PsNewsFetchSuccessAction),
  failure: (error: ApiError) =>
    ({
      type: PRODUCTS_SERVICES_NEWS_FETCH_FAILURE,
      payload: error,
    } as PsNewsFetchFailureAction),
};

// ----------------
// THUNKS
export const thunks = {
  requestNewsThunk:
    (): AppThunkAction<PsNewsActions> => async (dispatch, getState) => {
      dispatch(actionCreators.request());

      api
        .get<ApiCollection<BlogItem>>(
          'api/v1/blogitems/products-services?count=4'
        )
        .then(
          (data) => {
            dispatch(actionCreators.success(data.items));
          },
          (error: ApiError) => {
            dispatch(actionCreators.failure(error));
          }
        );
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const defaultState: ProductsServicesNewsState = {
  items: [],
  loading: false,
  error: undefined,
};

export const reducer: Reducer<ProductsServicesNewsState> = (
  state: ProductsServicesNewsState = defaultState,
  incomingAction: Action | undefined = undefined
): ProductsServicesNewsState => {
  const action = incomingAction as PsNewsActions;

  switch (action.type) {
    case PRODUCTS_SERVICES_NEWS_FETCH_REQUEST:
      return { ...state, loading: true };

    case PRODUCTS_SERVICES_NEWS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: undefined,
      };

    case PRODUCTS_SERVICES_NEWS_FETCH_FAILURE:
      return { ...state, loading: false, items: [], error: action.payload };

    default:
      return state;
  }
};
