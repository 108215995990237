import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingSpinner } from 'shared/components';
import { truncateText } from 'shared/utils';

import styles from '../NewsList.module.scss';

const ProductsServicesComponent: FunctionComponent = () => {
  const translations = useSelector(
    (state: ApplicationState) => state.i18n.translations
  );
  const { loading, error, items } = useSelector(
    (state: ApplicationState) => state.news.productsServices
  );

  return (
    <div className="section-margin">
      <h2>{translations!.ProductsServicesHeading}</h2>
      {loading && <LoadingSpinner />}
      {!loading && !error && !!items.length && (
        <Row className="section-title-spacing">
          {items.map((item, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col md={3} key={index}>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="tile-brick"
              >
                <div className={styles.newsTile}>
                  <div
                    className={`${styles.imageContainer}`}
                    style={{ backgroundImage: `url(${item.image}?w=600)` }}
                  />
                  <div className={styles.contentContainer}>
                    <h4 title={item.title}>{truncateText(item.title, 60)}</h4>
                    <p
                      className={`body2 ${styles.description}`}
                      title={item.description}
                    >
                      {truncateText(item.description, 75)}
                    </p>
                  </div>
                </div>
              </a>
            </Col>
          ))}
        </Row>
      )}
      <div className="row">
        <div className={`col-md-12 text-right ${styles.allNewsLink}`}>
          <a
            href="https://www.crayon.com/resources/insights/"
            target="_blank"
            rel="noreferrer"
          >
            {translations!.ProductsServicesViewAllLinkHeading}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductsServicesComponent;
