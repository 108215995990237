import { FunctionComponent } from 'react';
import { TicketComment } from 'models/state/tickets';
import { Row, Col, Card, Form } from 'react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate, formatFileSize } from 'shared/utils';
import styles from './TicketDetailedComments.module.scss';

interface TicketCommentProps {
  comment: TicketComment;
}

const TicketDetailedComment: FunctionComponent<TicketCommentProps> = ({
  comment,
}) => (
  <div className={styles['ticket-comment']}>
    <Card.Subtitle>
      <Row>
        <Col className="text-left">
          <Form.Label className="mb-0">
            {comment.user!.name || 'No Name'}
          </Form.Label>
        </Col>
        <Col className="text-right">
          <span className={styles['ticket-comment-timestamp']}>
            {formatDate(comment.createdAt, 'yyyy-MM-dd HH:mm:ss')}
          </span>
        </Col>
      </Row>
    </Card.Subtitle>
    <Card.Text className="mt-2 mb-2 comment-body body2">
      {comment.body}
    </Card.Text>
    {comment.attachments.map((attachment, index) => (
      <Card.Link
        href={attachment.contentUrl}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        target="_blank"
        className={styles['attachment-link']}
      >
        <FontAwesomeIcon
          icon={faPaperclip}
          className={styles['attachment-icon']}
        />
        {attachment.fileName} ({formatFileSize(attachment.size)})
      </Card.Link>
    ))}
  </div>
);
export default TicketDetailedComment;
