import { ApiCollection, ApiError } from 'models/api';

export enum Product {
    SPSS = 1,
    Amos = 2,
    SentinelLicenseManager = 3,
    ODBCDrivers = 4,
    SamplePower = 5,
    Modeler = 6,
    DataCollectionDataEntry = 7,
    TextAnalyticsForSurveys = 8,
    TIBCOStatistics = 9,
    SAS = 10
}

export enum OperatingSystem {
    Mac = 1,
    Linux = 2,
    Windows32 = 3,
    Windows64 = 4
}

export enum Type {
    Software = 1,
    Patch = 2,
    Documentation = 3
}

export interface SoftwareDownload {
    id: number;
    product: Product;
    version: string;
    operatingSystem: OperatingSystem;
    type: Type;
    filePath: string;
    displayName: string;
}

export interface SoftwareDownloadGroup {
    product: Product;
    operatingSystem: OperatingSystem;
    type: Type;
}

export interface SoftwareFilter {
    page?: number;
    pageSize?: number;
    searchText?: string;
    operatingSystem?: OperatingSystem;
    type?: Type;
    product?: Product;
}

export interface SoftwareFilesFilter {
    operatingSystem: OperatingSystem;
    type: Type;
    product: Product;
}

export interface SoftwareState {
    data?: ApiCollection<SoftwareDownload>;
    loading: boolean;
    error?: ApiError;
}

export interface SoftwareGroupState {
    data?: ApiCollection<SoftwareDownloadGroup>;
    loading: boolean;
    error?: ApiError;
}

export interface SoftwareFilesDictionary {
    [key: number]: SoftwareState
}

export interface SoftwareDownloadsState {
    softwareDownloads: SoftwareGroupState,
    softwareDownloadFiles: SoftwareFilesDictionary,
}
